import React, { useEffect } from 'react';

const Privacy = () => {
    let redirectUrl = 'https://www.privacypolicies.com/live/a976ad31-e8c1-4c54-8e65-ab30492b21c9';

    useEffect(() => {
        // Redirect to the specified URL when the component mounts
        // test issue w
        window.location.href = redirectUrl;
    }, []); // Dependency array with redirectUrl to trigger effect when URL changes

    // Render your component (this will be quickly replaced by the redirect)
    return (
        <div>Redirecting to Privacy Policy...</div>
    );
}

export default Privacy;
