import Icon from '../Icon';

const ModalContent = ({
  closeModal,
  children,
  src,
  title,
  className,
  openModal,
}) => {
  return openModal ? (
    <div className="modal-backdrop">
      <div
        className={`relative flex flex-col p-6 bg-white shadow-top-prominent rounded-lg modal-content ${className}`}
      >
        <div className="flex flex-col gap-4">
          {src ? (
            <Icon
              src={src}
              alt={title}
              className="border rounded-lg p-3 w-12 h-12"
              size={12}
            />
          ) : null}
          <h2 className="font-bold text-lg">{title}</h2>
        </div>
        <button
          className="absolute top-0 right-0 mt-4 mr-4"
          onClick={closeModal}
        >
          <svg
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="">{children}</div>
      </div>
    </div>
  ) : null;
};

export default ModalContent;
