import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import fullLogo from '../../icons/Full.png';
import smallIcon from '../../images/small-icon.png'
import workspaceIcon from '../../icons/workspaceIcon.png';
import notificationIcon from '../../icons/notification-icon.png';
import integrationIcon from '../../icons/integration-icon.png';
import userIcon from '../../icons/manage-user-icon.png';
import settingsIcon from '../../icons/settings-icon.png';
import helpIcon from '../../icons/help-icon.png';
import searchIcon from '../../icons/search-icon.png';
import notificationBellIcon from '../../icons/notification-bell-icon.png';
import NavLink from './NavLink';
import {
  activeWorkspaceAtom,
  sideNavDimension,
} from '../../recoil/workspaceAtom';
import { userAtom, sidebarState, upgrageState } from '../../recoil/userAtoms';
import Divider from '../SVGs/Divider';
import { useState, useEffect } from 'react';

const PageWrapper = ({ children, title, overlay, actionButton, hideSidebar }) => {
  const [user] = useRecoilState(userAtom);
  const navigate = useNavigate();
  const [activeWorkspace] = useRecoilState(activeWorkspaceAtom);
  const [dimensions, setDimensions] = useRecoilState(sideNavDimension);
  const [isResizing, setIsResizing] = useState(false);
  const [isOpen, setIsOpen] = useRecoilState(sidebarState);
  const [upgradeModalVisible, setUpgradeModalVisible] = useRecoilState(upgrageState);

  // Set initial and toggled widths
  const OPEN_WIDTH = 288; // 72px (w-72)
  const CLOSED_WIDTH = 80;

  // Update width when isOpen changes
  useEffect(() => {
    setDimensions({
      width: isOpen ? OPEN_WIDTH : CLOSED_WIDTH,
    });
  }, [isOpen, setDimensions]);

  const startResize = (e) => {
    if (isOpen) {
      setIsResizing(true);
    }
  };

  const stopResize = () => {
    setIsResizing(false);
  };

  const handleResize = (e) => {
    if (isResizing && isOpen && e.clientX < 300 && e.clientX > 80) {
      setDimensions({
        width: e.clientX,
      });
    }
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  // get page path
  const urlPath = window.location.pathname.split('/')[1];

  const paths = [
    {
      title: 'Workspaces',
      link: '/workspaces',
      src: workspaceIcon,
      paths: ['workspaces', 'approval-requests', 'workspace', 'work', 'edits'],
    },
    {
      title: 'Notifications',
      link: '/notifications',
      src: notificationIcon,
      paths: ['notifications'],
    },
    {
      title: 'Integrations',
      link: '/settings?open=integrations',
      src: integrationIcon,
      paths: ['integrations'],
    },
    {
      title: 'Manage Users',
      link: '/settings?open=manage-users',
      src: userIcon,
      paths: ['manage-users'],
    },
    {
      title: 'Settings',
      link: '/settings',
      src: settingsIcon,
      paths: ['settings'],
    },
    {
      title: 'Help',
      link: '/settings?open=contact-us',
      src: helpIcon,
      paths: ['help'],
    },
  ];

  return (
    <div
      className="relative flex h-screen w-full"
      onMouseMove={handleResize}
      onMouseUp={stopResize}
      onMouseLeave={stopResize}
    >
      {hideSidebar !== true &&
      <div
        id="resizeable"
        className="flex flex-col border h-screen justify-between relative px-4 py-8 z-50 bg-white transition-all duration-300"
        style={{ width: `${dimensions.width}px` }}
      >
        <div
          className="absolute rounded-full w-8 h-8 flex items-center justify-center bg-gray-300 cursor-pointer -right-4 top-10"
          onMouseDown={startResize}
          onClick={handleToggle}
        >
          <Divider />
        </div>
        <div className="h-full z-50 overflow-hidden">
          <div className={`w-[200px] flex ${isOpen ? 'px-2' : '' } mb-10`}>
            <div className="justify-center text-center w-[300px]">
              {isOpen ? 
              <img src={fullLogo} alt="SwiftApprove" className="w-full" />
              :
              <img src={smallIcon} alt="SwiftApprove" className="w-1/4" />
              }
            </div>
          </div>
          <div className="">
            <ul className="flex flex-col gap-4">
              {paths.map((path, index) => (
                <NavLink
                  key={index}
                  src={path.src}
                  title={path.title}
                  active={path.paths.includes(urlPath)}
                  link={path.link}
                  titleVisible={isOpen}
                />
              ))}
            </ul>
          </div>
        </div>

        {isOpen && upgradeModalVisible && <div className="flex flex-col justify-start p-4 bg-gray-100 gap-4 text-sm overflow-hidden">
          <p className="text-gray-500 text-nowrap">0.01 GB of 500 GB used</p>
          <div className="w-full h-2 bg-gray-300 rounded-full">
            <div className="bg-primary h-full w-[10%] rounded"></div>
          </div>
          <div className="flex flex-row justify-start gap-3">
            <p className='cursor-pointer'
            onClick={() => setUpgradeModalVisible(false)}
            >Dismiss</p>
            <p className="text-primary font-bold cursor-pointer"
              onClick={() => navigate('/settings?upgrade=true')}
            >Upgrade plan</p>
          </div>
        </div>}
      </div>}
      <div className="w-full h-screen overflow-hidden flex flex-col">
        <div className="w-full flex justify-end px-10 py-4 items-center">
        {hideSidebar !== true &&
          <div className="flex gap-2 items-center">
            <div className="h-10 w-10 p-2.5">
              <img src={notificationBellIcon} className='cursor-pointer'
              onClick={() => {
                navigate('/notifications');
              }}/>
            </div>
            <div
              className="h-10 w-10 border rounded-full overflow-hidden cursor-pointer"
              onClick={() => {
                navigate('/profile');
              }}
            >
              <img src={user.profilePicURL ?? userIcon} />
            </div>
          </div>
        }
        </div>
        <div className="sm:pl-8 sm:pr-12 sm:pt-5 p-4 flex flex-col overflow-hidden grow">
          {['approval-requests', 'workspace', 'edit'].includes(urlPath) ? (
            <div className="flex justify-between items-center mb-3">
              <div className="flex gap-2 items-stretch">
                <span className="self-center">Workspaces</span>
                <span className="self-center">{'>'}</span>
                <span className="font-bold py-0.5 px-1 rounded-lg bg-[#F9FAFB] self-center">
                  {activeWorkspace}
                </span>
              </div>
              {actionButton && actionButton}
            </div>
          ) : null}
          {children}
        </div>
      </div>
      {overlay}
    </div>
  );
};

export default PageWrapper;