import React, { useState } from 'react';

const SettingsItem = ({ children, profileIcon, title, Icon, setCustomShowModal, customShowModal, id }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        id={id}
        className="flex justify-between p-4 hover-bg hover-shadow cursor-pointer rounded-xl m-2 w-full bg-dashGray relative"
        onClick={() => (setCustomShowModal || setShowModal)(true)}
      >
        <div className="text-left flex items-center">
          <div className="relative h-6 w-6 mr-2">
            <img src={profileIcon} alt="Profile Icon" />
          </div>
          <p className="text-lg">{title}</p>
        </div>
        <div className="text-right items-center flex">
          <Icon size={12} />
        </div>
      </div>
      {(customShowModal || showModal) && React.cloneElement(children, { setShowModal: setCustomShowModal || setShowModal })}
    </>
  );
};

export default SettingsItem;
