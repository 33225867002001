import StepperCheckPoint from './StepperCheckPoint';
import StepperProgress from './StepperProgress';

const StepperGroup = ({ isLast, active, completed, selectStep }) => {
  if (isLast) {
    return (
      <StepperCheckPoint active={active || completed} selectStep={selectStep} />
    );
  }

  return (
    <>
      <StepperCheckPoint active={active || completed} selectStep={selectStep} />
      <StepperProgress active={active} completed={completed} />
    </>
  );
};

export default StepperGroup;
