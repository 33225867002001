/* global Dropbox */
import React, { useState, useRef } from 'react';
import whiteXIcon from '../icons/whiteXIcon.png';
import { useToast } from '@chakra-ui/react';
import { ImInfo } from 'react-icons/im';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Text,
} from '@chakra-ui/react';
import 'react-datepicker/dist/react-datepicker.css';
import { FiArrowRightCircle } from 'react-icons/fi';
import { Logger } from '../utils/logger';

const ApproversManager = ({
  selectedApprovals,
  scrollContainerRef,
  removeSecondLevelApprover,
  existingEmails = [],
  setSelectedApprovals,
  showEmailModal,
  validateEmail,
  setShowEmailModal,
  showPopover2,
  setShowPopover2,
  maxWidth,
}) => {
  const toast = useToast();
  const [emails, setEmails] = useState(existingEmails);
  const [email, setEmail] = useState('');

  const emailRef2 = useRef(null);
  const approvalFirstNameRef = useRef(null);
  const approvalLastNameRef = useRef(null);

  const handleKeyDown2 = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if you're using a form

      if (
        validateEmail(emailRef2.current.value) &&
        approvalFirstNameRef.current.value &&
        approvalLastNameRef.current.value
      ) {
        // If it's a valid email, add it to your emails array and clear the input
        if (emails.find((e) => e.email === emailRef2.current.value)) {
          toast({
            title: 'An error occurred.',
            description: 'Email already added',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          return;
        }

        let oldEmailsLength = emails.length;
        Logger.log(emails);
        let updatedEmail = [
          ...emails,
          {
            email: emailRef2.current.value,
            level: null,
            firstName: approvalFirstNameRef.current.value,
            lastName: approvalLastNameRef.current.value,
          },
        ];
        // setMembers(updatedMembers);
        setEmails(updatedEmail);
        setEmail('');
        const newEmails = [...updatedEmail];
        newEmails[oldEmailsLength].level = 1;
        setEmails(newEmails);
        setSelectedApprovals(updatedEmail.filter((e) => e.level !== null));
        if (oldEmailsLength != 0) {
          setShowEmailModal(true);
        }
        emailRef2.current.value = '';
        approvalFirstNameRef.current.value = '';
        approvalLastNameRef.current.value = '';
      } else if (
        !approvalFirstNameRef.current.value ||
        !approvalLastNameRef.current.value
      ) {
        toast({
          title: 'An error occurred.',
          description: 'Please enter a first and last name',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } else {
        // If it's not a valid email, handle the error (e.g., show an error message)
        toast({
          title: 'An error occurred.',
          description: 'Invalid email',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        Logger.error('Invalid email'); // Replace with your error handling logic
      }
    }
  };

  const arrowClick2 = () => {
    if (
      validateEmail(emailRef2.current.value) &&
      approvalFirstNameRef.current.value &&
      approvalLastNameRef.current.value
    ) {
      // If it's a valid email, add it to your emails array and clear the input
      if (emails.find((e) => e.email === emailRef2.current.value)) {
        toast({
          title: 'An error occurred.',
          description: 'Email already added',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      let oldEmailsLength = emails.length;
      Logger.log(emails);
      let updatedEmail = [
        ...emails,
        {
          email: emailRef2.current.value,
          level: null,
          firstName: approvalFirstNameRef.current.value,
          lastName: approvalLastNameRef.current.value,
        },
      ];
      // setMembers(updatedMembers);
      setEmails(updatedEmail);
      setEmail('');
      const newEmails = [...updatedEmail];
      newEmails[oldEmailsLength].level = 1;
      setEmails(newEmails);
      setSelectedApprovals(updatedEmail.filter((e) => e.level !== null));
      if (oldEmailsLength != 0) {
        setShowEmailModal(true);
      }
      emailRef2.current.value = '';
      approvalFirstNameRef.current.value = '';
      approvalLastNameRef.current.value = '';
    } else {
      // If it's not a valid email, handle the error (e.g., show an error message)
      toast({
        title: 'An error occurred.',
        description: 'Invalid email',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      Logger.error('Invalid email'); // Replace with your error handling logic
    }
  };

  const SecondLevelNameCard = ({ name, email, onRemove, approver }) => {
    const [showPopover3, setShowPopover3] = useState(false);

    return (
      <Popover isOpen={showPopover3} className="">
        <PopoverTrigger>
          {/* This Box component is now the hover target */}
          <div
            style={{ backgroundColor: '#085198', color: 'white' }}
            className="rounded-lg px-2 py-2 mr-2 flex items-center whitespace-nowrap select-none cursor-pointer"
            title={`Level ${approver.level}`}
            onMouseEnter={() => setShowPopover3(true)} // Show popover when mouse enters
            onMouseLeave={() => setShowPopover3(false)}
          >
            <span
              className="mr-2 text-sm font-semibold"
              onClick={() => setShowEmailModal(true)}
            >
              {name} ({email})
            </span>

            <div className="w-4 h-4 flex items-center justify-center">
              <img
                className="object-cover cursor-pointer"
                src={whiteXIcon}
                alt="Remove"
                onClick={() => onRemove(email)}
              />
            </div>
          </div>
        </PopoverTrigger>
        <PopoverContent className="">
          <Box
            className="bg-white text-black rounded-lg px-2 py-4 text-center"
            style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
          >
            <Text>{`Level ${approver.level}`}</Text>
          </Box>
        </PopoverContent>
      </Popover>
    );
  };

  return (
    <>
      <div className="mb-6">
        {/* <label className='block text-black font-bold'>Add Approvers</label> */}
        {/* <button className='bg-primary text-white px-3 py-1 rounded-lg hover:bg-blue-700 hover-shadow' onClick={() => setShowEmailModal(true)}>
              Add Approvers
            </button> */}

        {showEmailModal && (
          <div className="modal-backdrop">
            <div
              className="flex flex-col w-full max-w-xl p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content"
              style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
            >
              <div className="flex flex-col flex-grow py-4">
                <div className="justify-center mb-4 text-center">
                  <h2 className="font-bold text-2xl">Assign approval level</h2>
                  <p
                    className="text-center text-md text-gray-400"
                    style={{ lineHeight: '110%' }}
                  >
                    Select the approver you'd like to assign and designate an
                    approval level.
                  </p>
                </div>

                <div
                  style={{ maxHeight: '300px', overflowY: 'scroll' }}
                  className="text-lg my-4"
                >
                  {emails.map((item, index) => (
                    <div
                      key={index}
                      className="mt-2 flex justify-between items-center"
                    >
                      <span className="mr-3">
                        {item.firstName} ({item.email})
                      </span>
                      <select
                        className="text-lg cursor-pointer outline-none px-2 bg-dashGray text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                        value={item.level}
                        defaultValue="Click to select level"
                        onChange={(e) => {
                          const newEmails = [...emails];
                          newEmails[index].level = Number(e.target.value);
                          setEmails(newEmails);
                        }}
                      >
                        {[...Array(emails.length)].map((_, idx) => (
                          <option
                            className="cursor pointer bg-dashGray px-2"
                            key={idx}
                            value={idx + 1}
                          >
                            Level {idx + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                  ))}
                </div>

                <div className="flex flex-row mt-4 justify-between">
                  <button
                    onClick={() => setShowEmailModal(false)}
                    className="px-4 py-2 font-bold text-primary text-md bg-dashGray hover-bg hover-shadow rounded-lg focus:outline-none focus:shadow-outline"
                  >
                    Cancel
                  </button>

                  <button
                    onClick={() => {
                      setSelectedApprovals(
                        emails.filter((e) => e.level !== null)
                      );
                      setShowEmailModal(false);
                    }}
                    className="text-md px-4 py-2 font-bold text-white bg-primary rounded-lg hover-shadow hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedApprovals.length == 0 && (
        <label className="text-black font-bold mb-2 flex flex-row items-center">
          Approvers
          <Popover isOpen={showPopover2} className="w-fit">
            <PopoverTrigger>
              {/* This Box component is now the hover target */}
              <Box as="span" cursor="pointer">
                <ImInfo
                  className="ml-2 text-primary"
                  onMouseEnter={() => setShowPopover2(true)} // Show popover when mouse enters
                  onMouseLeave={() => setShowPopover2(false)}
                />
              </Box>
            </PopoverTrigger>
            <PopoverContent className="w-fit">
              <Box
                className="bg-white text-black rounded-lg px-2 py-4 text-center"
                style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
              >
                <Text>
                  Approvers are users that will be providing feedback. Approver
                  levels allow you to create a workflow so the content can be
                  reviewed in stages.
                </Text>
              </Box>
            </PopoverContent>
          </Popover>
        </label>
      )}
      {selectedApprovals.length > 0 && (
        <div className="mb-6">
          <label className="text-black font-bold mb-2 flex flex-row items-center">
            Approvers
            <Popover isOpen={showPopover2} className="w-fit">
              <PopoverTrigger>
                {/* This Box component is now the hover target */}
                <Box as="span" cursor="pointer">
                  <ImInfo
                    className="ml-2 text-primary"
                    onMouseEnter={() => setShowPopover2(true)} // Show popover when mouse enters
                    onMouseLeave={() => setShowPopover2(false)}
                  />
                </Box>
              </PopoverTrigger>
              <PopoverContent className="w-fit">
                <Box
                  className="bg-white text-black rounded-lg px-2 py-4 text-center"
                  style={{
                    boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <Text>
                    Approvers are users that will be providing feedback.
                    Approver levels allow you to create a workflow so the
                    content can be reviewed in stages.
                  </Text>
                </Box>
              </PopoverContent>
            </Popover>
          </label>
          <div className="flex">
            <div
              className="overflow-x-auto"
              ref={scrollContainerRef}
              style={{ maxWidth }}
            >
              <div className="flex mb-2">
                {selectedApprovals.map((approver, index) => (
                  <SecondLevelNameCard
                    key={index}
                    name={approver.firstName}
                    email={approver.email}
                    approver={approver}
                    onRemove={removeSecondLevelApprover}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex mt-2 gap-2 items-center">
        <input
          className="w-1/2 p-2 rounded-lg focus:outline-none bg-white"
          placeholder="First Name"
          ref={approvalFirstNameRef}
        />
        <input
          className="w-1/2 p-2 rounded-lg focus:outline-none bg-white"
          placeholder="Last Name"
          ref={approvalLastNameRef}
        />
      </div>
      <div className="flex items-stretch mt-1 rounded-lg">
        <input
          className="flex-grow rounded-l-lg p-2 focus:outline-none"
          id="newApprover"
          type="email"
          placeholder="Enter an email to add a new approver"
          ref={emailRef2}
          onKeyDown={handleKeyDown2}
        />
        <div
          className="flex items-center justify-center p-2 rounded-r-lg bg-white cursor-pointer"
          onClick={() => arrowClick2()}
        >
          {/* Replace this with your actual right arrow icon component */}
          <FiArrowRightCircle size={20} className="text-primary" />
        </div>
      </div>
    </>
  );
};

export default ApproversManager;
