import ModalContent from './ModalContent';

const ApprovalCreatedModal = ({ closeModal, isOpen = true }) => {
  const closeSuccessModal = () => {
    closeModal();
  };

  return (
    <ModalContent
      title={''}
      className={'max-w-[480px]'}
      closeModal={closeSuccessModal}
      openModal={isOpen}
    >
      <div className="flex flex-col items-center gap-4 text-sm">
        <div className="flex flex-col justify-center items-center mt-4">
          <h3 className="text-lg font-semibold">New Approval sent</h3>
          <p>Your approval request has been sent successfully</p>
        </div>
        <div className="flex justify-between w-full gap-4 mt-8 mb-4">
          <button
            className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
            onClick={closeSuccessModal}
          >
            Continue
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default ApprovalCreatedModal;
