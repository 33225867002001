const SendMessage = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="14"
      // height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <g clip-path="url(#clip0_998_6599)">
        <path
          d="M9.33341 6.99984L7.00008 4.6665M7.00008 4.6665L4.66675 6.99984M7.00008 4.6665V9.33317M12.8334 6.99984C12.8334 10.2215 10.2217 12.8332 7.00008 12.8332C3.77842 12.8332 1.16675 10.2215 1.16675 6.99984C1.16675 3.77818 3.77842 1.1665 7.00008 1.1665C10.2217 1.1665 12.8334 3.77818 12.8334 6.99984Z"
          // stroke="#1570EF"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_998_6599">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendMessage;
