import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import workspacePlaceholderIcon from '../icons/workspacePlaceholderIcon.png';
import { BsGear } from 'react-icons/bs';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import { useNavigate } from 'react-router-dom';
import { FaCheck, FaPencilAlt } from 'react-icons/fa';
import uploadToS3 from '../utils/uploadToS3';
import { IoClose } from 'react-icons/io5';
import { Logger } from '../utils/logger';
import Spinner from './Spinner/Spinner';

const WorkspaceListCard = ({
  workspace,
  isSelectMode,
  isSelected,
  onSelect,
}) => {
  const [modalIndex, setModalIndex] = useState(0);
  const [emailInputs, setEmailInputs] = useState(workspace.workspaceMembers);
  const [user] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState(workspace.name);
  const [validationError, setValidationError] = useState('');
  const [selectedWorkspaceColor, setSelectedWorkspaceColor] = useState(
    workspace.color
  );
  const [imageData, setImageData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imageChanged, setImageChanged] = useState(false);
  const toast = useToast();

  const availableColors = [
    { label: 'primary', code: '#0D76DB' },
    { label: 'red', code: '#FF4F00' },
    { label: 'magenta', code: '#E55DFF' },
    { label: 'violet', code: '#8247F5' },
    { label: 'cyan', code: '#09E8F0' },
    { label: 'electric lime', code: '#CCF000' },
    { label: 'bright yellow', code: '#F8E436' },
    { label: 'orange', code: '#FFA600' },
  ];

  const addImageData = (image) => {
    setImageData([image]);
  };

  const handleImageChange = (e) => {
    setImageChanged(true);
    const files = Array.from(e.target.files); // Convert files to an array

    // Process each file in the files array
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Use a function that sets an array of image data, instead of a single image data state
        addImageData({ name: file.name, data: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };

  const uploadImageToS3 = async () => {
    if (!imageData[0]) {
      return '';
    }
    // Assuming uploadToS3 is a function that uploads a file to S3 and returns the URL
    const url = await uploadToS3(imageData, user);
    return url[0].url;
  };

  const selectColor = (color) => {
    setSelectedWorkspaceColor(color);
  };

  const validateEmails = () => {
    for (let i = 0; i < emailInputs.length; i++) {
      const email = emailInputs[i].email;
      // Using a simple regex pattern for email validation
      const emailValid = /\S+@\S+\.\S+/.test(email);
      if (!emailValid) {
        return false;
      }
    }
    for (let i = 0; i < emailInputs.length; i++) {
      const firstName = emailInputs[i].firstName;
      // Using a simple regex pattern for email validation
      if (firstName.length < 1) {
        return false;
      }
    }
    return true;
  };

  const handleEditWorkspace = async () => {
    setIsLoading(true);
    if (!validateEmails()) {
      setValidationError('Please enter valid email addresses and name.');
      setIsLoading(false);
      return;
    }

    try {
      const workspaceIconURL = imageChanged
        ? await uploadImageToS3()
        : workspace.icon;
      const response = await axios.put(
        `/api/workspace/editWorkspace/${workspace._id}`,
        {
          workspaceName,
          color: selectedWorkspaceColor,
          workspaceIcon: workspaceIconURL, // Handle this based on whether the icon was changed
          workspaceMemberEmails: emailInputs.map((input) => input.email),
          workspaceMemberFirstNames: emailInputs.map(
            (input) => input.firstName
          ),
        }
      );

      if (response.status === 200) {
        Logger.log('Successfully updated workspace', response.data);
        toast({
          title: 'Workspace updated.',
          duration: 5000,
          isClosable: true,
        });
        setShowModal(false);
        window.location = window.location;
        // Handle successful update, e.g., refetch data, close modal, etc.
      } else {
        Logger.error('Failed to update workspace: ', response.data);
      }
    } catch (error) {
      Logger.error('An error occurred while updating the workspace:', error);
      setValidationError('Internal Server Error');
      toast({
        title: 'Unable to update workspace.',
        duration: 5000,
        status: 'error',
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const handleWorkspaceNameChange = (e) => {
    setWorkspaceName(e.target.value);
  };

  const handleEmailChange = (e, index, field) => {
    const newEmails = [...emailInputs];
    newEmails[index][field] = e.target.value;
    setEmailInputs(newEmails);
  };

  const addNewEmailInput = () => {
    setEmailInputs([...emailInputs, { email: '' }]);
  };

  const handleGearClick = (e) => {
    e.stopPropagation(); // Prevents triggering the parent's onClick
    e.preventDefault();
    setModalIndex(0);
    setShowModal(true);
  };

  return (
    <div>
      <>
        {isSelectMode ? (
          <div
            className={`bg-dashGray cursor-pointer px-4 rounded-xl m-2 py-3 flex justify-between items-center ${
              !isSelected && 'hover-bg'
            } hover-shadow ${
              isSelected ? 'bg-red-200 border-red wiggle-light' : ''
            }`}
            style={{
              border: `3px solid ${isSelected ? 'red' : workspace.color}`,
            }}
            onClick={() => onSelect(workspace._id)}
          >
            <div className="flex items-center flex-1">
              {workspace.icon === '' ? (
                <img
                  src={workspacePlaceholderIcon}
                  alt="Workspace Icon"
                  className="w-10 h-10 mr-4 rounded-full"
                />
              ) : (
                <img
                  src={workspace.icon}
                  alt="Workspace Icon"
                  className="w-10 h-10 mr-4 rounded-full"
                />
              )}
              <div className="font-bold">{workspace.name}</div>
            </div>

            <div className="text-left text-sm  flex-1">
              {workspace.workspaceMembers.length}{' '}
              {workspace.workspaceMembers.length === 1 ? 'Member' : 'Members'}
            </div>

            <div className="flex flex-1 justify-end">
              <BsGear onClick={handleGearClick} />
            </div>
          </div>
        ) : (
          <Link to={`/workspace/${workspace._id}`}>
            <div
              className="bg-dashGray px-4 rounded-xl m-2 py-1 flex justify-between items-center hover-bg hover-shadow"
              style={{ border: `3px solid ${workspace.color}` }}
            >
              <div className="flex items-center flex-1">
                {workspace.icon === '' ? (
                  <img
                    src={workspacePlaceholderIcon}
                    alt="Workspace Icon"
                    className="w-10 h-10 mr-4 rounded-full"
                  />
                ) : (
                  <img
                    src={workspace.icon}
                    alt="Workspace Icon"
                    className="w-10 h-10 mr-4 rounded-full"
                  />
                )}
                <div className="font-bold">{workspace.name}</div>
              </div>

              <div className="text-left text-sm  flex-1">
                {workspace.workspaceMembers.length}{' '}
                {workspace.workspaceMembers.length === 1 ? 'Member' : 'Members'}
              </div>

              <div className="flex flex-1 justify-end">
                <BsGear onClick={handleGearClick} />
              </div>
            </div>
          </Link>
        )}
      </>
      {showModal && (
        <>
          {modalIndex == 0 ? (
            <div className="modal-backdrop">
              <div className="relative flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                <div
                  className="absolute right-3 top-3 cursor-pointer z-10"
                  onClick={() => setShowModal(false)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '2rem',
                    height: '2rem',
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    borderRadius: '50%',
                    color: 'white',
                  }}
                >
                  <IoClose size={20} />
                </div>
                <div className="flex flex-col flex-grow">
                  <div className="justify-center mb-4 text-center">
                    <h2 className="font-bold">Edit Workspace</h2>
                    <p className="text-secondary leading-5">
                      A workspace is a dedicated folder of approvals for each
                      client, department, or project
                    </p>
                    <div className="flex justify-center mt-6">
                      <div className="bg-dashGray rounded-xl relative cursor-pointer p-4">
                        {workspace.icon === '' ? (
                          <label htmlFor="fileInput" className="cursor-pointer">
                            <img
                              src={
                                imageChanged
                                  ? imageData[0]?.data
                                  : workspacePlaceholderIcon
                              }
                              alt="Workspace Preview"
                              className="w-20 h-20 rounded-full"
                            />
                            <input
                              type="file"
                              id="fileInput"
                              className="hidden"
                              onChange={(e) => handleImageChange(e)}
                            />
                            <div className="absolute bottom-0 right-0 p-1 bg-dashGray hover-bg hover-shadow cursor-pointer rounded-full">
                              <FaPencilAlt />
                            </div>
                          </label>
                        ) : (
                          <div className="bg-dashGray rounded-xl  relative hover-shadow cursor-pointer p-4">
                            <label
                              htmlFor="fileInput"
                              className="cursor-pointer"
                            >
                              <img
                                src={
                                  imageChanged
                                    ? imageData[0]?.data
                                    : workspace.icon
                                }
                                alt="Workspace Preview"
                                className="w-20 h-20 rounded-full cursor-pointer"
                              ></img>
                              <input
                                type="file"
                                id="fileInput"
                                className="hidden"
                                onChange={(e) => handleImageChange(e)}
                              />
                              <div className="absolute bottom-0 right-0 p-1 bg-dashGray hover-bg hover-shadow cursor-pointer rounded-full">
                                <FaPencilAlt />
                              </div>
                            </label>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-center text-sm text-gray-400">
                    Change workspace icon
                  </p>
                  <div className="mt-4">
                    <input
                      value={workspaceName}
                      onChange={handleWorkspaceNameChange}
                      className="w-full bg-gray-50  px-3 py-2 mb-0 text-xs outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
                      id="firstName"
                      type="text"
                      placeholder="Enter Workspace Name"
                    />
                  </div>

                  <h2 className="font-bold text-center mt-2 text-sec">
                    Choose Workspace Color
                  </h2>
                  <div className="flex flex-wrap justify-center mt-4">
                    {availableColors.map((colorObj, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-center w-6 h-6 rounded-full m-1 cursor-pointer"
                        style={{ backgroundColor: colorObj.code }}
                        onClick={() => selectColor(colorObj.code)}
                      >
                        {selectedWorkspaceColor === colorObj.code && (
                          <FaCheck className="text-white" />
                        )}
                      </div>
                    ))}
                  </div>

                  <button
                    className="w-full mt-4 text-xs px-4 py-2 font-bold text-white bg-primary rounded-lg mt- focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                    type="button"
                    onClick={() => setModalIndex(1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="modal-backdrop">
              <div className="relative flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                <div
                  className="absolute right-3 top-3 cursor-pointer z-10"
                  onClick={() => setShowModal(false)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '2rem',
                    height: '2rem',
                    backgroundColor: 'rgba(0, 0, 0, 0.25)',
                    borderRadius: '50%',
                    color: 'white',
                  }}
                >
                  <IoClose size={20} />
                </div>
                <div className="flex flex-col flex-grow">
                  <div className="justify-center mb-4 text-center">
                    <h2 className="font-bold">Edit Workspace Members</h2>
                    <p
                      className="text-center text-sm text-gray-400"
                      style={{ lineHeight: '110%' }}
                    >
                      Workspace members are your team members. They will get
                      access to the workspace, be able to view projects, and can
                      be assigned feedback from approvers.
                    </p>
                    {validationError != '' && (
                      <div className="justify-center mt-1 text-center">
                        <p className="text-xs text-red-500">
                          {validationError}
                        </p>
                      </div>
                    )}
                  </div>

                  {emailInputs.map((input, index) => (
                    <div key={index} className="mt-2 flex justify-around">
                      <input
                        value={input.firstName}
                        onChange={(e) =>
                          handleEmailChange(e, index, 'firstName')
                        }
                        className="w-2/5 bg-gray-50 px-3 py-2 mb-0 mx-1 text-xs outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                        placeholder={`Enter first name`}
                      />
                      <input
                        value={input.email}
                        onChange={(e) => handleEmailChange(e, index, 'email')}
                        className="w-3/5 bg-gray-50 px-3 py-2 mb-0 mx-1 text-xs outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                        placeholder={`Enter email address`}
                      />
                    </div>
                  ))}

                  <button
                    className="mt-2 text-xs text-primary"
                    onClick={addNewEmailInput}
                  >
                    + Add another member
                  </button>

                  <div className="flex flex-row mt-2">
                    <button
                      className="w-full text-xs px-4 py-2 mx-2 font-bold text-primary bg-dashGray rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-gray-100"
                      type="button"
                      onClick={() => setModalIndex(0)}
                    >
                      Back
                    </button>

                    <button
                      className="w-full text-xs px-4 py-2 font-bold text-white bg-primary rounded-lg  focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                      type="button"
                      onClick={() => handleEditWorkspace()}
                    >
                      {isLoading ? (
                        <div className="spinner">
                          <Spinner />
                        </div>
                      ) : (
                        'Save'
                      )}
                    </button>
                  </div>
                </div>

                <div className="items-center text-center text-gray-400 mt-8">
                  <Link to="/workspaces">
                    <p className="text-xs underline">Set up later</p>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WorkspaceListCard;
