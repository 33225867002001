import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import logoutIcon from '../icons/logoutIcon.png';
import swiftApproveIcon from '../icons/swiftApprove.png';
import { BiSidebar, BiLayout } from 'react-icons/bi';
import { BsGear } from 'react-icons/bs';
import { MdOutlineNotifications } from 'react-icons/md';
import priceToPlan from '../utils/priceIdToPlan'; // Adjust the import path based on your file structure
import { AvatarComponent } from 'avatar-initials';

export default function Sidebar() {
  const history = useNavigate();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [user, setUser] = useRecoilState(userAtom);
  const [shouldWiggle, setShouldWiggle] = useState(false);

  const location = useLocation();

  const formatBytesToGB = (bytes) => {
    return (
      bytes / (1024 * 1024 * 1024) < 0.01 ? 0.01 : bytes / (1024 * 1024 * 1024)
    ).toFixed(2); // Convert MB to GB and fix to 2 decimal places
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Start the wiggle effect
      setShouldWiggle(true);
      // Stop the wiggle effect after 2 seconds
      const timeout = setTimeout(() => {
        setShouldWiggle(false);
      }, 2000); // 2 seconds

      // Clean up the timeout when component unmounts or before the next interval starts
      return () => clearTimeout(timeout);
    }, 15000); // 67 seconds

    // Clean up the interval when component unmounts
    return () => clearInterval(interval);
  }, []);

  function UserPlanInfo({ user }) {
    // Logger.log(user);
    const storageUsedGB = formatBytesToGB(user.storageUsed * 1024); // Convert MB to bytes then to GB
    const storageCapGB =
      priceToPlan[user.plan]?.title === 'STARTER'
        ? 5
        : priceToPlan[user.plan]?.title === 'PREMIUM'
        ? 50
        : 500; // Assuming these are your storage caps
    const progressPercentage = (storageUsedGB / storageCapGB) * 100;

    return (
      <div className="my-4 px-2 w-full flex flex-col items-center">
        <div className="w-full flex justify-between items-center"></div>
        <div className="w-full bg-secondary rounded-full my-2 overflow-hidden">
          <div
            className="bg-primary h-2 rounded-full"
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div className="text-sm">
          <span>{`${storageUsedGB} GB of ${storageCapGB} GB used`}</span>
        </div>
        <button
          className={`bg-white text-primary hover-bg hover-shadow py-1 mt-2 px-3 rounded-lg font-bold border-primary ${
            shouldWiggle ? 'wiggle' : ''
          }`}
          style={{ borderWidth: '2px' }}
          onClick={() => history('/settings?upgrade=true')}
        >
          Upgrade
        </button>
      </div>
    );
  }

  const icons = {
    Workspaces: <BiLayout size={24} className="text-black font-bold mr-2" />,
    Notifications: (
      <MdOutlineNotifications size={24} className="text-black font-bold mr-2" />
    ),
    Settings: <BsGear size={24} className="text-black font-bold mr-2" />,
    'Log out': logoutIcon,
  };

  const routes = {
    Workspaces: '/workspaces',
    Notifications: '/notifications',
    Settings: '/settings',
  };

  const handleLogout = () => {
    setUser({});
    history('/login');
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  function NavItem({ title, navSize }) {
    const isActive = location.pathname === routes[title];
    const iconClass = isActive ? 'text-primary font-bold' : 'text-black';
    const textClass = isActive ? 'text-black' : 'text-secondary';

    return (
      <div className="mt-2 w-full text-left px-2">
        <Link
          to={routes[title]}
          className={`hover:text-black hover:font-bold ${textClass}`}
        >
          <div className="flex flex-row items-center">
            {React.cloneElement(icons[title], {
              className: `${iconClass} mr-2`,
            })}
            <span className={`text-xl ${textClass}`}>
              {title == 'Settings' ? (
                <p>
                  {title}
                  <span style={{ color: 'transparent' }}>ssss</span>{' '}
                </p>
              ) : (
                title
              )}
            </span>
          </div>
        </Link>
      </div>
    );
  }

  function Logout({ title, navSize }) {
    return (
      <div className="mt-2 w-full text-left px-2 cursor-pointer">
        <div
          className="w-full hover:text-black hover:font-bold text-gray-600 flex items-center"
          onClick={() => handleLogout()}
        >
          <div className="flex flex-row items-center">
            <img
              className="w-6 h-6 mb-1 mr-2"
              src={icons[title]}
              alt="workspace icon"
            />
            <span className="text-xl">{title}</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* Icon outside the sidebar to toggle visibility */}
      {isSidebarCollapsed && (
        <BiSidebar
          size={40}
          className="fixed top-4 left-4 cursor-pointer mb-4 bg-gray-300 p-2 rounded-lg hover-shadow hover-bg"
          onClick={toggleSidebar}
        />
      )}

      {/* Conditional styling for collapsing the sidebar */}
      <div
        className={`h-screen bg-dashGray flex flex-col relative transition-all duration-300 ${
          isSidebarCollapsed ? 'w-0 overflow-hidden' : 'w-1/6'
        }`}
        style={{
          minWidth: isSidebarCollapsed ? 0 : 160,
          position: 'sticky',
          top: 0,
        }}
      >
        <div className="flex flex-col w-3/4 mt-10 mb-4 self-center ">
          {!isSidebarCollapsed && (
            <BiSidebar
              size={40}
              className="mb-4 bg-gray-300 p-2 rounded-lg hover-shadow hover-bg cursor-pointer"
              onClick={toggleSidebar}
            />
          )}
          <img src={swiftApproveIcon} alt="SwiftApprove" />
        </div>

        <>
          <div className="flex flex-col w-3/4 self-center">
            <NavItem title="Workspaces" />
            <NavItem title="Notifications" />
            <NavItem title="Settings" />
            <UserPlanInfo user={user} />
          </div>
          <div className="absolute bottom-0 w-3/4 self-center mb-4 items-center flex flex-col">
            <Logout title="Log out" />
          </div>
        </>
      </div>
    </>
  );
}
