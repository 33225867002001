function darkerShade(hexColor, darkenPercent) {
    // Ensure darkenPercent is between 0 and 1
    darkenPercent = Math.min(Math.max(darkenPercent, 0), 1);
  
    // Convert hex to RGB
    let r = parseInt(hexColor?.substring(1, 3), 16);
    let g = parseInt(hexColor?.substring(3, 5), 16);
    let b = parseInt(hexColor?.substring(5, 7), 16);
  
    // Calculate darker color
    r = Math.floor(r * (1 - darkenPercent));
    g = Math.floor(g * (1 - darkenPercent));
    b = Math.floor(b * (1 - darkenPercent));
  
    // Convert back to hex and return
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
  }
  export { darkerShade };