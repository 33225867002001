import React from 'react'
import fullLogo from '../icons/Full.png'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { useToast } from '@chakra-ui/react';

const VerifyEmail = () => {

  const [error, setError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const { firebaseAuthUUID } = useParams();
  const history = useNavigate();
  const toast = useToast();

  React.useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.patch(`/api/auth/verify-email/${firebaseAuthUUID}`);
        if (response.status === 200) {
          setIsLoading(false);
        }
      } catch (error) {
        setError(true);
        setIsLoading(false);
        toast({
          title: "An error occurred.",
          description: error.response.data,
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
    }

    verifyEmail();
  }, [])


  return (
    <div className="flex items-center bg-dashGray justify-center h-screen">
      <div className="w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl">
          <div className="mb-2">
            <div className='justify-center mb-4 text-center'>
              <img src={fullLogo} alt="SwiftApprove" className='w-2/3' style={{margin: '0 auto'}} />
            </div>
  
            <div className='justify-center mb-4 text-center'>
              { isLoading && <p className='text-md'>Please wait while we verify your email</p>}
              { (!isLoading && !error ) && <p className='text-md'>Your email has been verified, You can now proceed to <span className='text-primary cursor-pointer' onClick={() => history('/login')}> Sign In.</span></p>}
              {error && <p className='text-md'>Your email could not be verified, Please check the link and try again or <span className='text-primary cursor-pointer' onClick={() => history('/')}>go Home.</span></p>}
            </div>

          </div>
      </div>
    </div>
  )
}

export default VerifyEmail