import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import AuthScreen from '../components/AuthScreens';
import FormInput from '../components/Form/FormInput';
import Spinner from '../components/Spinner/Spinner';

const ForgotPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const history = useNavigate();
  const [resetData, setResetData] = useState({
    email: '',
  });
  const [validationError, setValidationError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const validateForm = () => {
    const email = resetData.email;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      setValidationError('Invalid email address.');
      return false;
    }

    return true;
  };

  const handleReset = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const auth = getAuth(firebaseApp);
      const email = resetData.email;

      try {
        await sendPasswordResetEmail(auth, email);
        setIsLoading(false);
        setEmailSent(true);
        // Navigate back to login after successful password reset
      } catch (error) {
        setIsLoading(false);
        setValidationError(error.message); // Display the error message
      } finally {
        setResetData({
          email: '',
        });
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setResetData({
      ...resetData,
      [id]: value,
    });
  };

  return (
    <AuthScreen>
      <div className="flex flex-col gap-8 sm:mx-28 sm:w-[360px]">
        <div className="flex flex-col gap-3">
          <h1 className="text-4xl font-normal text-center lg:text-left">
            Forgot Password
          </h1>
          {!emailSent && (
            <p className="text-sm text-center lg:text-left">
              Forgot your password? Reset it here
            </p>
          )}

          {emailSent && (
            <h1 className="text-md text-center lg:text-left">
              An email has been sent to reset your password.{' '}
              <span
                className="text-primary cursor-pointer"
                onClick={() => setEmailSent(false)}
              >
                Resend link
              </span>
            </h1>
          )}
        </div>
        {
          <form
            className="flex flex-col gap-2 sm:gap-2"
            onSubmit={(e) => handleReset(e)}
          >
            {!emailSent && (
              <FormInput
                label="Email*"
                id="email"
                type="email"
                placeholder="Enter your email"
                autoComplete="email"
                onChange={handleChange}
              />
            )}
            {!emailSent && (
              <div className="flex items-center justify-between">
                <button
                  className="w-full bg-primary-600 text-white py-2 rounded-md sm:h-11 text-sm"
                  type="submit"
                >
                  {isLoading ? (
                    <div className="spinner">
                      <Spinner />
                    </div>
                  ) : (
                    'Reset password'
                  )}
                </button>
              </div>
            )}

            <div className="items-center text-center text-gray-400 mt-8 text-sm">
              <p className="text-md">
                Remember your credential?{' '}
                <span
                  className="text-primary cursor-pointer"
                  onClick={() => history('/login')}
                >
                  Log in
                </span>
              </p>
            </div>
          </form>
        }
      </div>
    </AuthScreen>
  );
};

export default ForgotPassword;
