const StepperProgress = ({ active, completed }) => {
  return (
    <div className="flex w-full">
      <div
        className={`border w-full h-0.5 ${
          active || completed ? 'border-primary' : ''
        }`}
      ></div>
      <div
        className={`border w-full h-0.5 ${completed ? 'border-primary' : ''}`}
      ></div>
    </div>
  );
};

export default StepperProgress;
