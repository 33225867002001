import { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { firebaseApp } from '../firebase/firebaseConfig';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useToast } from '@chakra-ui/react'; // Import the useToast hook from Chakra UI
import fullLogo from '../icons/Full.png'

const PNF = () => {
  const history = useNavigate();
  const accessCodeRef = useRef(); // Ref for the early access code input
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast(); // Initialize the toast

  const EARLY_ACCESS_CODE = "FNP949YNMB"; // Replace with your actual early access code

  const handleEarlyAccessSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const accessCode = accessCodeRef.current.value;

    if (accessCode === EARLY_ACCESS_CODE) {
      setIsLoading(false);
      history('/signup'); // Redirect to /signup 
    } else {
      setIsLoading(false);
      // Show error toast
      toast({
        title: "Error",
        description: "Incorrect Early Access code.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="flex items-center bg-dashGray justify-center h-screen">
      <div className="w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl">
        <form onSubmit={handleEarlyAccessSubmit}>
            <div className="mb-2">
            <p className='text-center font-extrabold text-4xl'>404</p>
                <p className='text-center font-extrabold text-xl'>Page not found</p>
                <div className='justify-center my-4 text-center'>
                <img src={fullLogo} alt="SwiftApprove" className='w-2/3' style={{margin: '0 auto'}} />
                </div>
    
                
    
             
            </div>
        </form>
      </div>
    </div>
  );
};

export default PNF;
