import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import { useToast } from '@chakra-ui/react'; // Import the useToast hook from Chakra UI
import fullLogo from '../icons/Full.png';
import Spinner from '../components/Spinner/Spinner';

const EarlyAccess = () => {
  const history = useNavigate();
  const accessCodeRef = useRef(); // Ref for the early access code input
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast(); // Initialize the toast

  const EARLY_ACCESS_CODE = 'FNP949YNMB'; // Replace with your actual early access code

  const handleEarlyAccessSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const accessCode = accessCodeRef.current.value;

    if (accessCode === EARLY_ACCESS_CODE) {
      setIsLoading(false);
      history('/signup'); // Redirect to /signup
    } else {
      setIsLoading(false);
      // Show error toast
      toast({
        title: 'Error',
        description: 'Incorrect Early Access code.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (user) {
      history('/workspaces'); // Redirect to /signup
    }
  }, []);

  return (
    <div className="flex items-center bg-dashGray justify-center h-screen">
      <div className="w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl">
        <form onSubmit={handleEarlyAccessSubmit}>
          <div className="mb-2">
            <div className="justify-center mb-4 text-center">
              <img
                src={fullLogo}
                alt="SwiftApprove"
                className="w-2/3"
                style={{ margin: '0 auto' }}
              />
            </div>

            <p className="text-center font-extrabold text-lg">Early Access</p>
          </div>
          <div className="mb-4">
            <input
              className="w-full bg-gray-50 px-3 py-2 text-md outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
              id="accessCode"
              type="password"
              placeholder="Enter Early Access Code..."
              ref={accessCodeRef}
            />
          </div>
          <div className="flex items-center justify-between">
            <button
              className="w-full text-md px-4 py-2 font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
              type="submit"
            >
              {isLoading ? (
                <div className="spinner">
                  <Spinner />
                </div>
              ) : (
                'Submit Code'
              )}
            </button>
          </div>
          {/* ... other components ... */}
        </form>
      </div>
    </div>
  );
};

export default EarlyAccess;
