/* global Dropbox */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import axios from 'axios';
// import AWS from 'aws-sdk';
import uploadToS3 from '../utils/uploadToS3';
import GooglePicker from 'react-google-picker';
import { ImInfo } from 'react-icons/im';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Text,
} from '@chakra-ui/react';
import { useToast } from '@chakra-ui/react';
import ReactConfetti from 'react-confetti';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import logo from '../icons/workspacePlaceholderIcon.png';
import xIcon from '../icons/xIcon.png';
import { MdOutlinePhonelink } from 'react-icons/md';
import priceToPlan from '../utils/priceIdToPlan';
import {
  removeAnchorTagHref,
  scrubIframeDoc,
} from '../utils/remove-anchor-href';
import { lazyImageLoader } from '../utils/html-utils';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';
import LeftArrow from '../components/ContentViewer/LeftArrow';
import RightArrow from '../components/ContentViewer/RightArrow';
import CloseButton from '../components/CloseButton';

const EditApprovalRequest = () => {
  const [members, setMembers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [user] = useRecoilState(userAtom);
  const [maxWidth, setMaxWidth] = useState('50%');
  const history = useNavigate();
  const workspaceID = new URLSearchParams(window.location.search).get(
    'workspaceID'
  );
  const [approvalTitle, setApprovalTitle] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [description, setDescription] = useState('');
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const dateInputRef = useRef(null);
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const { approvalReqID } = useParams();
  const [showPopover, setShowPopover] = useState(false);
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const [htmlContents, setHtmlContents] = useState([]);

  const [imageName, setImageName] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isValidLink, setIsValidLink] = useState(true);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);
  const [showSentModal, setShowSentModal] = useState(false);
  const [isNextSlide, setIsNextSlide] = useState(false);
  const titleRef = useRef();
  const emailRef = useRef();
  const emailRef2 = useRef();
  const contentURL = useRef();
  const toast = useToast();
  const descriptionRef = useRef();
  const caprionRef = useRef();
  const linkRef = useRef();
  const [subCat, setSubCat] = useState('');

  const [_URL, setURL] = useState('');

  const [isDesktopView, setIsDesktopView] = useState(true);

  const customStyle = `
  .react-datepicker__day--selected {
    background-color: #0D76DB !important; /* Selected day background */
    color: #fff !important; /* Selected day text color */
  }
  .react-datepicker__day--selected:hover {
    background-color: #1d5d90 !important; /* Darken selected day on hover */
  }
  .react-datepicker {
    border-radius: 10px; /* Rounded corners for the calendar */
    border: 1px solid #ccc; /* Thin gray border */
    background-color: #fff; /* Calendar background */
  }
  .react-datepicker__header {
    background-color: #0D76DB; /* Header background */
    border-top-left-radius: 10px; /* Rounded corner top left */
    border-top-right-radius: 10px; /* Rounded corner top right */
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name {
    color: #fff; /* Text color for the header and day names */
  }
  .react-datepicker__navigation-icon::before {
    border-color: #fff; /* Make the navigation arrows white */
  }
  .react-datepicker__triangle {
    display: none; /* Hide the triangle */
  }
`;

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation regex, you might want to use a more complex one depending on your needs
    return re.test(email);
  };

  const validateLink = (url) => {
    // Basic regex for HTTPS URL validation
    const regex = /^https:\/\/([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/;
    return regex.test(url);
  };

  const handleLinkChange = (e) => {
    const url = e.target.value;
    setLink(url);

    // Update the validation state based on the link
    if (url === '' || validateLink(url)) {
      setIsValidLink(true);
    } else {
      setIsValidLink(false);
    }
  };

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const fetchExistingApprovalRequest = async () => {
    try {
      const res = await axios.get(`/api/approvalRequests/get/${approvalReqID}?firebaseUID=${user?.firebaseAuthUUID}`);
      const data = res.data.approvalRequest;
      const dueDateFormatted = new Date(data.dueDate)
        .toISOString()
        .split('T')[0];
      Logger.log(data);

      setApprovalTitle(data.approvalTitle.trim());
      setDueDate(new Date(data.dueDate));
      setDescription(data.description);
      setCaption(data.content.caption.trim());
      setLink(data.content.link);
      //   setImageData(data.content.media);
      setHtmlContents(data.content.media);
      // setHtmlContent(data.content.media[0].src);
      setApprovers(data.approvers);
      setLink(data.link);
      setSubCat(data.contentTypeCategory.subCategory);
    } catch (error) {
      Logger.error('Error fetching existing approval request:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchExistingApprovalRequest();
  }, []);

  const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length; // Changed from const to let
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };

  const InstagramPost = () => {
    const fileInputRef = React.createRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const openUploadModal = () => {
      setIsModalOpen(true);
    };

    const closeUploadModal = () => {
      setIsModalOpen(false);
    };

    // Function to trigger the hidden file input click event
    const triggerFileInput = () => {
      fileInputRef.current.click();
    };

    const openDropboxChooser = () => {
      Dropbox.choose({
        success: function (files) {
          // 'files' is an array of file objects. We'll map over this array to extract the links.
          const fileLinks = files.map((file) => file.link);
          // Pass the array of links to the handler
          handleDropboxFiles(fileLinks);
        },
        linkType: 'direct', // "direct" gives a direct link to the file, which we can convert to a blob
        multiselect: false, // Allow multiple files to be selected
        extensions: ['.html'],
      });
    };

    const loadDropboxSDK = (callback) => {
      const script = document.createElement('script');
      script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
      script.id = 'dropboxjs';
      script.dataset.appKey = 'YOUR_APP_KEY'; // Ensure to replace with your actual App key
      script.onload = callback;
      document.body.appendChild(script);
    };

    const handleImageUpload = async (e) => {
      const file = e.target.files[0]; // Get the first file only
      if (file && file.type === 'text/html') {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Update the state with the HTML content
          setHtmlContents([
            ...htmlContents,
            { src: reader.result, type: 'html' },
          ]);
        };
        reader.readAsText(file); // Read the file as text
        setMediaIsChanged(true);
      } else {
        // alert('Please upload a valid HTML file.');
        toast({
          title: 'An error occurred.',
          description: `Please upload a valid HTML file.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
      closeUploadModal();
    };

    const handleDropboxFiles = async (fileLinks) => {
      // Map over the fileLinks to create an array of fetch requests
      const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

      // Use Promise.all to wait for all of them
      const responses = await Promise.all(fetchPromises);

      // Create a new array to store HTML data
      const newHtmlData = [];

      // Process each response
      for (const [index, response] of responses.entries()) {
        // Check if the response is of type 'text/html'
        if (response.headers.get('Content-Type').includes('text/html')) {
          // Read response as text for HTML files
          const htmlContent = await response.text();
          newHtmlData.push({
            name: `htmlFile${index}.html`, // Consider a more meaningful naming convention
            data: htmlContent,
            type: 'text/html',
          });
        } else {
          // Display a toast for non-HTML files
          toast({
            title: 'Invalid File Type',
            description: 'The selected file is not an HTML file.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }

      // Update the state with the new HTML data, overwriting any existing data
      if (newHtmlData.length > 0) {
        const oldHtmlContents = [...htmlContents];
        if (isUpdate) {
          oldHtmlContents[currentPage] = {
            src: newHtmlData[0].data,
            type: 'html',
          };
        } else {
          oldHtmlContents.push({ src: newHtmlData[0].data, type: 'html' });
        }
        setHtmlContents([
          ...htmlContents,
          { src: newHtmlData[0].data, type: 'html' },
        ]); // Assuming setHtmlContent is your state update function
        setMediaIsChanged(true);
      }
      closeUploadModal();
      setIsUpdate(false);
    };

    const fetchHtmlContent = async (url) => {
      setIsUploading(true);

      // Function to check if the URL is valid
      const isValidHttpUrl = (str) => {
        let url;

        try {
          url = new URL(str);
        } catch (_) {
          Logger.log("We faced an error: ",_);
          return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
      };

      // Prepend 'http://' if the URL is missing it
      if (
        !isValidHttpUrl(url) &&
        !url.startsWith('http://') &&
        !url.startsWith('https://')
      ) {
        url = 'https://' + url;
      }

      // Check if the modified URL is valid
      if (!isValidHttpUrl(url)) {
        toast({
          title: 'Invalid URL',
          description: 'The URL provided is not valid.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
        return;
      }

      try {
        const serverUrl = `https://jznalehjc7.execute-api.us-east-2.amazonaws.com/default/getHTML`;
        const response = await axios.post(
          'https://jznalehjc7.execute-api.us-east-2.amazonaws.com/default/getHTML',
          { url: url }
        );

        const htmlString = response.data;

        // Use DOMParser to parse the HTML string
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');

        // Query all anchor tags
        const links = doc.querySelectorAll('a');

        // Fix the issue of lazy loading
        lazyImageLoader(doc);

        // Loop through each link to modify external links
        links.forEach((link) => {
          // Check if the link is external
          // if (link.hostname !== window.location.hostname) {
          // Method 1: Remove the href attribute
          link.removeAttribute('href');

          // Method 2: Add a click event listener that prevents navigation
          // link.addEventListener('click', function(e) {
          //   e.preventDefault();
          //   alert('External links are disabled.');
          // });

          // Additional modifications as needed
          // }
        });

        // Serialize the modified document back to a string
        const serializer = new XMLSerializer();
        const modifiedHtmlString = serializer.serializeToString(doc);
        // setHtmlContent(modifiedHtmlString);
        const oldHtmlContents = [...htmlContents];
        if (isUpdate) {
          oldHtmlContents[currentPage] = {
            src: modifiedHtmlString,
            type: 'html',
          };
        } else {
          oldHtmlContents.push({ src: modifiedHtmlString, type: 'html' });
        }
        setHtmlContents(oldHtmlContents); // Assuming setHtmlContent is your state update function
        setIsUploading(false);
        setURL(url);
        setMediaIsChanged(true);
        setIsUpdate(false);
      } catch (error) {
        Logger.error('Error fetching HTML content:', error);
        toast({
          title: 'An error occurred.',
          description: `There was an error while fetching the contents of: ${url}`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setIsUploading(false);
      }
    };

    return (
      <div
        className="mx-auto"
        style={{
          width: 'fit-content',
          width: '100%',
          borderRadius: '8px',
          padding: '16px',
        }}
      >
        {/* Carousel Container */}
        {htmlContents.length > 0 && (
          <div
            className="group relative"
            style={{ overflow: 'hidden', width: '100%', maxHeight: '80vh' }}
          >
            <div className="flex justify-between mb-2 relative">
              <MdOutlinePhonelink
                size={30}
                className="text-secondary cursor-pointer mx-4"
                onClick={() => setIsDesktopView(!isDesktopView)}
              />
              <CloseButton
                onClick={() => {
                  setHtmlContents(
                    htmlContents.filter((_, index) => index !== currentPage)
                  );
                  setCurrentPage(currentPage - 1 < 0 ? 0 : currentPage - 1);
                  setMediaIsChanged(true);
                }}
                isVisible={true}
              />
            </div>
            <LeftArrow
              onClick={() => setCurrentPage(currentPage - 1)}
              isVisible={currentPage > 0}
            />
            <RightArrow
              onClick={() => setCurrentPage(currentPage + 1)}
              isVisible={currentPage < htmlContents?.length - 1}
            />
            <iframe
              onLoad={scrubIframeDoc}
              srcDoc={removeAnchorTagHref(htmlContents[currentPage].src)}
              style={{
                width: isDesktopView ? '200%' : '60%', // Increase width
                height: isDesktopView ? '160vh' : '80vh',
                border: 'none',
                margin: '0 auto',
                // boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                transform: isDesktopView ? 'scale(0.5)' : 'scale(1.0)',
                transformOrigin: '0 0',
                position: 'relative',
                pointerEvents: 'none',
              }}
            ></iframe>
          </div>
        )}

        <div className="flex justify-center w-full gap-2 mt-4">
          {isUploading ? (
            <div className="spinner">
              <Spinner />
            </div>
          ) : (
            <>
              {htmlContents[currentPage] ? (
                <button
                  className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-2 "
                  onClick={() => {
                    openUploadModal();
                    setIsUpdate(true);
                  }}
                >
                  Add new version
                </button>
              ) : null}
              <button
                className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-2 "
                onClick={openUploadModal}
              >
                {htmlContents[0] ? 'Add new content' : 'Upload content'}
              </button>
            </>
          )}
        </div>

        {isModalOpen && (
          // <UploadModal
          //   onClose={() => setIsModalOpen(false)}
          //   onFileSelect={handleImageUpload}
          //   onDropboxFileSelect={handleDropboxFiles}
          // />\
          <UploadModal
            onClose={() => setIsModalOpen(false)}
            onFileSelect={handleImageUpload}
            onDropboxFileSelect={handleDropboxFiles}
            fetchHtmlContent={fetchHtmlContent}
            triggerFileInput={triggerFileInput}
            fileInputRef={fileInputRef}
            handleImageUpload={handleImageUpload}
            // platform={platform}
            isLoading3={isUploading}
            subCat={subCat}
          />
        )}
      </div>
    );
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if you're using a form

      if (validateEmail(emailRef.current.value)) {
        // If it's a valid email, add it to your emails array and clear the input
        let updatedMembers = [...members, emailRef.current.value];
        setMembers(updatedMembers);
        setEmails(updatedMembers.map((email) => ({ email, level: null })));
        Logger.log(updatedMembers);
        setEmail('');
      } else {
        // If it's not a valid email, handle the error (e.g., show an error message)
        toast({
          title: 'An error occurred.',
          description: 'Invalid email',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        Logger.error('Invalid email'); // Replace with your error handling logic
      }
    }
  };

  const handleKeyDown2 = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if you're using a form

      if (validateEmail(emailRef2.current.value)) {
        // If it's a valid email, add it to your emails array and clear the input
        let updatedMembers = [...members, emailRef2.current.value];
        setMembers(updatedMembers);
        setEmails(updatedMembers.map((email) => ({ email, level: null })));
        setEmail('');
        setShowEmailModal(true);
        emailRef2.current.value = '';
      } else {
        // If it's not a valid email, handle the error (e.g., show an error message)
        toast({
          title: 'An error occurred.',
          description: 'Invalid email',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        Logger.error('Invalid email'); // Replace with your error handling logic
      }
    }
  };

  const validateRequest = (
    approvalTitle,
    dueDate,
    description,
    approvers,
    htmlContent
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!htmlContents.length) missingFields.push('Content');
    if (approvers.length === 0) missingFields.push('Approvers');

    return missingFields;
  };

  const handleUpdateApprovalRequest = async () => {
    setIsLoading2(true);
    const missingFields = validateRequest(
      approvalTitle,
      dueDate,
      description,
      approvers,
      htmlContents
    );

    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }

    if (priceToPlan[user?.plan]?.title == 'STARTER') {
      toast({
        title: 'Upgrade your plan',
        description: `Users on Starter Plan do not have access to version control. Upgrade to Premium to send multiple Approval Requests in one timeline, with comments and version history.`,
        status: 'info',
        duration: 5000,
        isClosable: false,
      });
      setIsLoading2(false);
      return;
    }

    try {
      let imageUrl;
      //   if(mediaIsChanged){
      //     imageUrl = await uploadToS3(htmlContent, user);
      //   }

      const payload = {
        approvalReqID, // Include the approval request ID
        title: titleRef.current.value,
        dueDate,
        description: descriptionRef.current.value,
        caption: caprionRef.current.value,
        htmlContents: htmlContents,
        // htmlContent: (subCat == 'landingPage' || subCat == 'website') ? null : htmlContent,
        approvers,
        link: linkRef.current.value,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: members,
        _URL: (subCat == 'landingPage' || subCat == 'website') && _URL,
        mediaIsChanged,
      };

      const response = await axios.post(
        '/api/approvalRequests/update',
        payload
      ); // Assuming the endpoint is for PUT requests

      if (response.status === 200) {
        // alert("Approval Request updated successfully.");
        setShowSentModal(true);
        setIsLoading2(false);
      }
    } catch (error) {
      Logger.error('Error updating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error updating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
    }
  };

  const scrollContainerRef = useRef(null);

  // Variable to hold the initial mouse position
  let isDragging = false;
  let startX = 0;
  let scrollLeft = 0;

  const onMouseDown = (e) => {
    isDragging = true;
    startX = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft = scrollContainerRef.current.scrollLeft;
  };

  const onMouseLeave = () => {
    isDragging = false;
  };

  const onMouseUp = () => {
    isDragging = false;
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const scroll = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - scroll;
  };

  const removeMember = (name) => {
    setMembers(members.filter((member) => member !== name));
  };

  const NameCard = ({ name, onRemove }) => (
    <div
      style={{ backgroundColor: '#DEDEDE' }}
      className="cursor-pointer rounded-lg px-2 py-2 mr-2 flex items-center whitespace-nowrap select-none"
    >
      <span className="mr-2 text-sm font-semibold">{name}</span>
      <div className="w-4 h-4 flex items-center justify-center">
        <img
          className="object-cover cursor-pointer"
          src={xIcon}
          alt="Remove"
          onClick={() => onRemove(name)}
        />
      </div>
    </div>
  );

  const validateRequest2 = (
    approvalTitle,
    dueDate,
    description,
    selectedApprovals,
    htmlContent,
    members
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!htmlContent[0]) missingFields.push('Content');

    return missingFields;
  };

  const handleNextClick = () => {
    const missingFields = validateRequest2(
      titleRef.current.value,
      dueDate,
      descriptionRef.current.value,
      selectedApprovals,
      htmlContent,
      members
    );
    Logger.log(missingFields);
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields before continuing: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }
    setIsNextSlide(true);
  };
  const handleBackButtonClick = () => {
    if (isNextSlide) {
      setIsNextSlide(false);
    } else {
      history(-1);
    }
  };

  if (isLoading) {
    return (
      <div className="h-3/5 flex-1 flex">
        <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
          <div className="blackSpinner"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-1 h-screen">
      <div className="bg-dashGray w-1/3 px-6 py-8 overflow-y-auto h-full">
        {isLoading ? (
          <div className="h-3/5 flex-1 flex">
            <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
              <div className="blackSpinner"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Top Bar */}
            <div className="flex justify-between mb-8">
              <p
                className="text-secondary cursor-pointer hover-bg hover-shadow"
                onClick={() => handleBackButtonClick()}
              >
                {'< Back'}
              </p>
              <p className="text-lg font-bold">Edit Approval Request</p>
              {/* <button className='bg-primary text-white px-3 rounded-lg py-1' onClick={() => handleUpdateApprovalRequest()}>
          {isLoading2 ? (
            <div className="spinner"><Spinner /></div>
          ) : (
            'Update Request'
          )}
          </button> */}
              <div className="text-dashGray">.</div>
            </div>

            {/* Form */}
            <div style={{ display: isNextSlide ? 'none' : '' }}>
              {/* Approval Title and Due Date */}
              <div className="flex mb-6 justify-between">
                <div className="mb-3 w-1/2 pr-2">
                  <label className="block text-black font-bold">
                    Approval Title
                  </label>
                  <input
                    className="w-full rounded-lg p-2"
                    type="text"
                    placeholder="Approval Title"
                    defaultValue={approvalTitle}
                    ref={titleRef}
                  />
                </div>

                <div className="w-1/2 mb-3 pl-2">
                  <label className="block text-black font-bold">Due Date</label>
                  {/* <input className='w-full rounded-lg p-2' type='date' ref={dateInputRef} onChange={handleDateChange}/> */}
                  <>
                    <style>{customStyle}</style>
                    <DatePicker
                      // showIcon
                      // selected={startDate}
                      className="w-full rounded-lg p-2 text-black"
                      selected={dueDate}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </>
                </div>
              </div>

              {/* Description */}
              <div className="mb-6">
                <label className="block text-black font-bold">
                  Description
                </label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter description"
                  defaultValue={description}
                  ref={descriptionRef}
                ></textarea>
              </div>

              <button
                className={`bg-primary text-white w-full py-3 font-bold text-xl rounded-lg hover:bg-blue-700`}
                onClick={() => handleUpdateApprovalRequest()}
              >
                {isLoading2 ? (
                  <div className="spinner">
                    <Spinner />
                  </div>
                ) : (
                  'Send Update Request'
                )}
              </button>
            </div>
            <div style={{ display: !isNextSlide ? 'none' : '' }}>
              {/* Caption */}
              <div className="mb-6">
                <label className="block text-black font-bold">Caption</label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter a caption for the media if it has one"
                  defaultValue={caption}
                  ref={caprionRef}
                ></textarea>
              </div>

              <div>
                {/* Link */}
                <div className="mb-3 pr-2">
                  <label className="block text-black font-bold">
                    Add a link to the post
                  </label>
                  <input
                    className={`w-full rounded-lg p-2 ${
                      !isValidLink && 'border-red-500'
                    }`}
                    type="text"
                    placeholder="https://example.com"
                    defaultValue={link}
                    ref={linkRef}
                    style={{ borderColor: !isValidLink ? 'red' : '' }} // Alternative way to set border color
                  />
                  {!isValidLink && (
                    <div className="text-red-500 text-sm mt-1">
                      Please enter a valid HTTPS link.
                    </div>
                  )}
                </div>
              </div>

              <div className="my-6">
                <button
                  className={`bg-primary text-white w-full py-3 font-bold text-xl rounded-lg hover:bg-blue-700`}
                  onClick={() => handleUpdateApprovalRequest()}
                >
                  {isLoading2 ? (
                    <div className="spinner">
                      <Spinner />
                    </div>
                  ) : (
                    'Send Update Request'
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Right Side */}
      <div className="w-3/5 bg-white mx-auto justify-center align-middle self-center">
        <div
          className="mx-auto justify-center align-middle self-center"
          style={{ margin: '0 auto', width: '100%' }}
        >
          <InstagramPost />
        </div>
        {showSentModal && (
          <div className="modal-backdrop">
            {/* <ReactConfetti /> */}
            <div
              className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content"
              style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
            >
              <div className="flex flex-col flex-grow py-4">
                <div className="justify-center mb-4 text-center">
                  <h2 className="font-bold text-2xl">New approval sent!</h2>

                  <button
                    className="bg-primary text-white px-4 font-bold py-1 mt-4 rounded-lg hover:bg-blue-700 hover-shadow"
                    onClick={() => history(-1)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


const UploadModal = ({ 
  // onClose,
  onClose,
  fetchHtmlContent,
  triggerFileInput,
  fileInputRef,
  handleImageUpload,
  platform,
  isLoading3,
  subCat,
 }) => {
  const contentURL = useRef();

  if (subCat == 'landingPage' || subCat == 'website') {
    return (
      <div className="modal-backdrop">
        <div
          className="flex w-full max-w-md p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content items-center "
          style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
        >
          {isLoading3 ? (
            <div className="flex-1 flex">
              <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
                <div className="blackSpinner">
                  <Spinner />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col w-full">
              <p className="text-xl font-bold text-center mb-4">
                Enter link to your content
              </p>
              <div className="flex flex-col justify-center">
                <input
                  placeholder="Ex. https://swiftapprove.io"
                  className="rounded-lg px-2 py-1"
                  style={{ outline: '2px solid #F9F9F9' }}
                  ref={contentURL}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      await fetchHtmlContent(contentURL.current.value);
                    }
                  }}
                />

                <button
                  className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-4 py-2 "
                  onClick={async () => await fetchHtmlContent(contentURL.current.value)}
                >
                  Confirm
                </button>
              </div>

              <span
                className="close text-primary text-center mt-4 cursor-pointer px-3 rounded-lg hover-shadow w-fit justify-center"
                style={{ margin: '0 auto' }}
                onClick={onClose}
              >
                Cancel
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="modal-backdrop">
      <div className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
        <div className="flex flex-col w-full">
          <p className="text-xl font-bold text-center mb-4">
            Select your upload method
          </p>
          <div className="flex flex-col justify-center">
            {/* Hidden file input */}
            <input
              type="file"
              ref={fileInputRef}
              multiple={false}
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              accept="text/html"
            />
            {/* Button to trigger file input */}
            <button
              className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
              onClick={triggerFileInput}
            >
              Device
            </button>

            {/* <button className='bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 ' onClick={() => {loadDropboxSDK(); openDropboxChooser();}}>Dropbox</button> */}

            {/* <GooglePicker 
            clientId={"701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com"}
            developerKey={"AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo"}
            scope={['https://www.googleapis.com/auth/drive']}
            onChange={data => Logger.log('on change:', data)}
            onAuthFailed={data => Logger.log('on auth failed:', data)}
            multiselect={true}
            navHidden={true}
            authImmediate={false}
            mimeTypes={['image/png', 'image/jpeg']}
            viewId={'DOCS_IMAGES'}
          >
              <button  className='bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full'>
                  Google Drive 
              </button>
          </GooglePicker>
*/}
          </div>
          <span
            className="close text-primary text-center mt-4 cursor-pointer"
            onClick={onClose}
          >
            Cancel
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditApprovalRequest;
