import { lazyImageLoader, removeModalsByClassName } from './html-utils';

export function removeAnchorTagHref(content) {
  // this is just for an extra layer of precaution. Don't blame me🤪
  return (
    content +
    `<script>
    window.onload(() => {
      document.querySelectorAll('a').forEach((tag) => {
        if (tag.href && !tag.href.startsWith('#')) {
          tag.href = ""
          tag.removeAttribute("href")
        }
    
        tag.removeAttribute("target")
      })
    })
  
  </script>`
  );
}

/**
 * The sole purpose of this suite of code is to gain access to Iframe document
 * You can add more to it depending on your use case
 */
export function scrubIframeDoc(e) {
  if (e?.target?.contentDocument?.addEventListener) {
    e?.target?.contentDocument?.addEventListener('click', () => {});
  }
  // disable pointer events
  if (e?.target?.style?.pointerEvents) {
    e.target.style.pointerEvents = '';
  }

  if (e.target.contentDocument) {
    e.target.contentDocument.querySelectorAll('a').forEach((tag) => {
      if (tag.href && !tag.href.startsWith('/')) {
        tag.href = '';
        tag.removeAttribute('href');
      }
      if (tag.href && tag.href.startsWith('./')) {
        tag.href = '';
        tag.removeAttribute('href');
      }
      if (tag.href && tag.href.startsWith('../')) {
        tag.href = '';
        tag.removeAttribute('href');
      }
      if (tag.href && !tag.href.startsWith('#')) {
        tag.href = '';
        tag.removeAttribute('href');
      }

      tag.removeAttribute('target');
    });
    e.target.contentDocument.querySelectorAll('input').forEach((tag) => {
      if (tag.type && tag.type.startsWith('submit')) {
        tag.disabled = true;
      }
    });
    e.target.contentDocument.querySelectorAll('button').forEach((tag) => {
      if (tag.type && tag.type.startsWith('submit')) {
        tag.disabled = true;
      }
    });

    removeModalsByClassName(e.target.contentDocument, [
      'elementor-popup-modal',
    ]);

    lazyImageLoader(e.target.contentDocument);
    // TODO: Figure out nested Iframes
  }
}
