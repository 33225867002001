import { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { currentTimeAtom } from '../../recoil/currentTimeAtom';
import { Logger } from '../../utils/logger';
import { FaPause, FaPlay } from 'react-icons/fa';
import { AvatarComponent } from 'avatar-initials';
import { darkerShade } from '../../utils/darkerShade';
import { formatAgoDate } from '../../utils/formatDate';

const VideoPlayer = ({
  src,
  videoRef,
  video,
  comments,
  enlarge,
  imageDimensions,
  setMediaIndex,
  currentSlide,
  setCommentCoords,
  addingComment,
  sender,
  setSelectedCommentID,
  handleLabelClick,
  scrollToComment,
  setShowExpandedViewModal,
  showExpandedViewModal,
  selectedCommentID,
  isPlaying,
  setIsPlaying,
}) => {
  const [currentTime, setCurrentTime] = useRecoilState(currentTimeAtom);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [activeComments, setActiveComments] = useState([]);
  const newVideoRef = useRef(video);

  videoRef = videoRef ?? newVideoRef;

  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  comments = comments.filter((comment) => comment.mediaIndex == currentSlide);

  useEffect(() => {
    Logger.log('Something needs to be done here', selectedCommentID);
  }, [selectedCommentID]);

  useEffect(() => {
    if (videoRef.current) {
      setVideoDimensions({
        width: videoRef.current.offsetWidth,
        height: videoRef.current.offsetHeight,
      });
    }
  }, [videoRef.current?.offsetWidth, videoRef.current?.offsetHeight]);

  useEffect(() => {
    if (videoRef.current.currentTime !== currentTime) {
      videoRef.current.currentTime = currentTime;
    }
  }, [showExpandedViewModal]);

  useEffect(() => {
    setDuration(videoRef?.current?.duration);
    videoRef.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    if (!addingComment) return;

    videoRef?.current?.pause();
  }, [addingComment]);

  const togglePlayPause = (e) => {
    if (addingComment && imageDimensions) {
      e.preventDefault();
      e.stopPropagation();
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position within the element.
      const y = e.clientY - rect.top; // y position within the element.
      const relativeX = x / imageDimensions.width;
      const relativeY = y / imageDimensions.height;

      // Store these in state and use for the comment submission
      setMediaIndex(currentSlide); // Assuming currentSlide state holds the index of the current media
      setCommentCoords({
        x: relativeX,
        y: relativeY,
        absoluteX: e.clientX,
        absoluteY: e.clientY,
      });
      return;
    }
    const video = videoRef.current;

    if (video?.currentTime == duration) {
      setCurrentTime(0);
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
      return;
    }

    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    const time = (e.target.value * video?.duration) / 100;
    video.currentTime = time;
  };

  const getProgress = () => {
    const video = videoRef.current;
    if (!video?.duration) return 0;
    return (video?.currentTime / video?.duration) * 100;
  };
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current?.currentTime;
    setCurrentTime(currentTime);

    if (currentTime === duration) {
      setIsPlaying(false);
    }

    // Filter comments that should be active based on the current time
    const active = comments.filter((comment) => {
      return (
        currentTime >= comment?.coords?.videoTimestamp - 3 &&
        currentTime <= comment?.coords?.videoTimestamp + 3
      );
    });

    setActiveComments(
      active.map((comment) => ({
        ...comment,
        x: comment.coords.x * videoDimensions.width,
        y: comment.coords.y * videoDimensions.height,
      }))
    );
  };

  // Calculate the position of a comment on the progress bar
  const calculateCommentPosition = (timestamp) => {
    const video = videoRef.current;
    if (!video?.duration) return 0;
    const position = (timestamp / video.duration) * 100;
    return `${position - 5}%`;
  };

  const handleCommentClick = (timestamp) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timestamp;
    }
  };

  return (
    <div className="bg-dashGray w-full mx-auto">
      <video
        ref={videoRef}
        src={src}
        className={addingComment ? 'adding-comment rounded-lg' : 'rounded-lg'}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(videoRef.current.duration)}
        onClick={(e) => togglePlayPause(e)}
        style={{
          margin: '0 auto',
          boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
          height: enlarge ? '85vh' : '',
        }}
      />
      <div className="relative bottom-0 w-full flex justify-between items-center px-4 py-2 bg-gray-800 bg-opacity-75">
        <button
          onClick={togglePlayPause}
          className="focus:outline-none text-white"
        >
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="relative w-1/2">
          {!enlarge && (
            <>
              {comments.map((comment) => {
                return (
                  <div
                    key={comment._id}
                    className={`flex rounded-full gap-2 group/comment hover:z-40 cursor-pointer border bg-white hover:p-0.5 hover:rounded-lg items-end p-0.5 ${
                      comment._id == selectedCommentID
                        ? 'bg-primary-default text-white'
                        : ''
                    }`}
                    style={{
                      left: calculateCommentPosition(
                        comment?.coords?.videoTimestamp + 0.1
                      ),
                      // borderRadius: '50%',

                      position: 'absolute',
                      bottom: '24px',
                      zIndex: comment._id === selectedCommentID ? 100 : 99,
                      // height: '32px' /* adjust as needed */,
                      // width: '32px' /* adjust as needed */,
                    }}
                  >
                    {comment.sender.email === sender.email &&
                    sender?.profilePicURL ? (
                      <img
                        src={sender.profilePicURL}
                        onClick={() => {
                          handleCommentClick(comment.coords.videoTimestamp);
                          setSelectedCommentID(comment._id);
                          handleLabelClick(
                            comment?.resolved ? 'resolved' : 'comments'
                          );
                          scrollToComment(comment._id);
                          videoRef.current.currentTime =
                            comment.coords.videoTimestamp;
                          videoRef.current.pause();
                          setIsPlaying(false);
                        }}
                        style={{
                          // left: calculateCommentPosition(
                          //   comment?.coords?.videoTimestamp + 0.1
                          // ),
                          borderRadius: '50%',
                          // position: 'absolute',
                          // bottom: '24px',
                          // zIndex: comment._id === selectedCommentID ? 100 : 99,
                          height: '32px' /* adjust as needed */,
                          width: '32px' /* adjust as needed */,
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          // left: calculateCommentPosition(
                          //   comment?.coords?.videoTimestamp + 0.1
                          // ),
                          borderRadius: '50%',
                          // position: 'absolute',
                          // bottom: '24px',
                          zIndex: comment._id === selectedCommentID ? 100 : 99,
                          height: '32px' /* adjust as needed */,
                          width: '32px' /* adjust as needed */,
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectedCommentID(comment._id);
                          handleLabelClick(
                            comment?.resolved ? 'resolved' : 'comments'
                          );
                          scrollToComment(comment._id);
                          videoRef.current.currentTime =
                            comment?.coords?.videoTimestamp;
                          videoRef.current.pause();
                          setIsPlaying(false);
                        }}
                      >
                        <AvatarComponent
                          classes="rounded-full"
                          useGravatar={false}
                          color={darkerShade(comment.bg, 0.55)}
                          background={comment.bg}
                          fontSize={16}
                          fontWeight={400}
                          className="text-primary cursor-pointer"
                          size={32}
                          initials={comment.sender.email
                            .substring(0, 1)
                            .toUpperCase()}
                        />
                      </div>
                    )}
                    <div className="hidden group-hover/comment:block text-xs flex flex-col max-w-[200px] gap-1 text-left">
                      <div className="flex gap-4 w-full">
                        <span className="text-nowrap truncate grow">
                          {comment.sender.user?.fullName ??
                            comment.sender.email}
                        </span>
                        <span className="text-nowrap">
                          {formatAgoDate(comment.dateCreated)}
                        </span>
                      </div>
                      <div className="max-h-[50px] overflow-hidden text-ellipsis">
                        {comment?.comment}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}

          <input
            type="range"
            min="0"
            max="100"
            value={getProgress()}
            onChange={handleProgressChange}
            className="w-full custom-range cursor-pointer"
          />
        </div>

        <div className="text-white w-24 text-center">
          <span>{formatTime(currentTime)}</span> /{' '}
          <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
