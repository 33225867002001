import IntegrationList from '../Integrations/IntergrationList';

const IntegrationModal = ({ setShowModal, user }) => {
  return (
    <div className="modal-backdrop">
      <div className="relative flex flex-col w-full max-w-2xl p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-0 right-0 mt-4 mr-4"
          id='integrationsListCloseBtn'
        >
          <svg
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <IntegrationList user={user} />
      </div>
    </div>
  );
};

export default IntegrationModal;
