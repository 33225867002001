import React from 'react';

const FormRadioButton = ({
  labels,
  id,
  placeholder,
  error,
  hint,
  checked,
  onClick,
  defaultValue,
  className = '',
}) => {
  const [selectedValue, setSelectedValue] = React.useState(defaultValue ?? '');
  const handleClicked = (value) => {
    setSelectedValue(value);
    onClick && onClick(value);
  };
  return (
    <div className="flex flex-col gap-1.5 w-full justify-start items-center">
      {labels.map(({ label, value }) => (
        <div
          className="flex gap-1.5 w-full justify-start items-center"
          onClick={() => handleClicked(value)}
          key={value}
        >
          <span
            className={`w-4 h-4 rounded-full border flex items-center justify-center cursor-pointer ${
              value === selectedValue ? 'border-primary' : ''
            }`}
          >
            {value === selectedValue && (
              <span className="w-2 h-2 bg-primary rounded-full"></span>
            )}
          </span>
          <label htmlFor={id} className={className}>
            {label}
          </label>
        </div>
      ))}
    </div>
  );
};

export default FormRadioButton;
