import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import Icon from '../components/Icon';
import PageWrapper from '../components/PageWrapper';
import searchIcon from '../icons/search-icon.png';
import plusWhiteIcon from '../icons/plus-white-icon.png';
import CreateApprovalModal from '../components/Modal/CreateApprovalModal';
import {
  activeWorkspaceAtom,
  approvalRequestsAtom,
  workspaceNamesAtom,
} from '../recoil/workspaceAtom';
import { useNavigate, useParams } from 'react-router-dom';
import { getRandomPastelColor } from '../utils/pastels';
import axios from 'axios';
import { Logger } from '../utils/logger';
import filterIcon from '../icons/filter-lines.png';
import Check from '../components/SVGs/Check';
import ApprovalFilterModal from '../components/Modal/ApprovalFilterModal';
import ApprovalRequestBoardView from '../components/Views/ApprovalRequestBoardView';
import ApprovalRequestCardView from '../components/Views/ApprovalRequestCardView';
import ApprovalRequestTableView from '../components/Views/ApprovalRequestTableView';
import DeleteModal from '../components/Modal/DeleteModal';
import DropDownSort from '../components/DropDownSort';

const Approvals = () => {
  const { workspaceID } = useParams();
  const navigate = useNavigate();
  const [, setActiveWorkspace] = useRecoilState(activeWorkspaceAtom);

  const [selectedView, setSelectedView] = useState('table');
  const [sortOrder, setSortOrder] = useState('asc');
  const [toggleSortView, setToggleSortView] = useState(false);

  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [approvalRequestData, setApprovalRequestData] =
    useRecoilState(approvalRequestsAtom);
  const [workspaceNames, setWorkspaceNames] =
    useRecoilState(workspaceNamesAtom);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleShowCreateApprovalModal = () => {
    setShowCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleDeleteModal = (selectedApprovals) => {
    setSelectedApprovals(selectedApprovals);
    setOpenDeleteModal(true);
  };

  const assignPastelColorsToApprovers = (approvalRequests) => {
    const emailColorMap = new Map();

    approvalRequests.forEach((request) => {
      request.approvers.forEach((approver) => {
        if (!emailColorMap.has(approver.email)) {
          emailColorMap.set(approver.email, getRandomPastelColor());
        }
        approver.bg = emailColorMap.get(approver.email);
      });
    });

    return approvalRequests;
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/api/workspace/getApprovalRequestTimeline/${workspaceID}`
      );
      if (res.status === 200) {
        const updatedApprovalRequests = assignPastelColorsToApprovers(
          res.data.approvalRequests
        );
        setApprovalRequestData({
          ...approvalRequestData,
          [workspaceID]: updatedApprovalRequests,
        });
        setWorkspaceNames({
          ...workspaceNames,
          [workspaceID]: res.data.workspaceName,
        });
        setActiveWorkspace(res.data.workspaceName);
      } else {
        Logger.error('Failed to fetch data');
      }
    } catch (error) {
      Logger.error('An error occurred while fetching data:', error);
    }
    setIsLoading(false);
  };

  const handleDelete = async () => {
    setIsDeleting(true);

    try {
      const response = await axios.put(
        '/api/approvalRequests/deleteApprovalRequestTimelines',
        {
          timelineIds: selectedApprovals,
        }
      );

      if (response.status === 200) {
        setSelectedApprovals([]);
        await fetchData();
        setOpenDeleteModal(false);
      } else {
        Logger.error('Failed to delete approval requests');
        alert('Failed to delete approval requests');
      }
    } catch (error) {
      Logger.error(
        'An error occurred while deleting approval requests:',
        error
      );
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const sortData = [...(approvalRequestData[workspaceID] || [])];
    if (sortOrder === 'asc') {
      setApprovalRequestData({
        ...approvalRequestData,
        [workspaceID]: sortData?.sort(
          (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated)
        ),
      });
    } else {
      setApprovalRequestData({
        ...approvalRequestData,
        [workspaceID]: sortData?.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
        ),
      });
    }
  }, [sortOrder]);

  return (
    <PageWrapper
      title="Technical workspace"
      actionButton={
        <button
          className="py-2.5 px-4 border rounded-lg bg-primary text-white w-fit flex items-center justify-baseline gap-2"
          onClick={() => setShowCreateModal(true)}
        >
          <Icon src={plusWhiteIcon} /> Create Approval
        </button>
      }
    >
      <div className="flex flex-col grow gap-5 overflow-hidden">
        <div className="mt-6 flex justify-between md:h-9 items-center flex-col md:flex-row">
          <div className="text-3xl font-semibold">Approval Request</div>
          <div className="flex md:w-fit h-fit rounded-lg bg-gray-200 p-1 text-sm gap-0.5 cursor-pointer w-full justify-evenly text-center">
            <div
              className={`px-1.5 py-0.5 w-full md:w-fit rounded-lg ${
                selectedView === 'table' ? 'bg-white' : ''
              }`}
              onClick={() => {
                setSelectedView('table');
              }}
            >
              Table
            </div>
            <div
              className={`px-1.5 py-0.5 w-full md:w-fit rounded-lg ${
                selectedView === 'card' ? 'bg-white' : ''
              }`}
              onClick={() => {
                setSelectedView('card');
              }}
            >
              Card
            </div>
            <div
              className={`px-1.5 py-0.5 w-full md:w-fit rounded-lg ${
                selectedView === 'board' ? 'bg-white' : ''
              }`}
              onClick={() => {
                setSelectedView('board');
              }}
            >
              Board
            </div>
          </div>
        </div>
        {isLoading ? <p className="text-center">Loading...</p> : null}
        {approvalRequestData[workspaceID]?.length ? (
          <div className="flex flex-col gap-6 w-full grow overflow-hidden">
            <div className="flex justify-end gap-3 flex-col md:flex-row w-full">
              <div className="flex gap-3 ">
                {/* <div
                  className="border rounded-lg flex gap-2 py-2.5 px-4 relative cursor-pointer"
                  onClick={() => {
                    setToggleSortView(!toggleSortView);
                  }}
                >
                  <div className="flex  gap-2 items-center">
                    <Icon alt="sort" src={sortIcon} size={5} /> Sort{' '}
                  </div>
                  {toggleSortView ? (
                    <div
                      className={
                        'absolute top-full py-1.5 px-2 flex flex-col border mt-2 right-0 rounded bg-white z-20 gap-1 justify-stretch'
                      }
                    >
                      <div
                        className="flex text-nowrap gap-4 p-2.5 justify-between rounded bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setSortOrder('asc');
                        }}
                      >
                        <p>Ascending order </p>
                        <Check
                          classNames={
                            sortOrder === 'asc' ? 'stroke-primary-600' : 'none'
                          }
                        />
                      </div>
                      <div
                        className="flex text-nowrap gap-4 p-2.5 justify-between rounded bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setSortOrder('desc');
                        }}
                      >
                        <p>Descending order </p>
                        <Check
                          classNames={
                            sortOrder === 'desc' ? 'stroke-primary-600' : 'none'
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </div> */}
                <DropDownSort
                  sortOrder={sortOrder}
                  setSortOrder={setSortOrder}
                />
                {/* <div className="border rounded-lg flex gap-2 py-2.5 px-4">
                  <div
                    className="flex  gap-2 items-center cursor-pointer"
                    onClick={() => {
                      setShowFilterModal(true);
                    }}
                  >
                    <Icon alt="sort" src={filterIcon} size={5} /> Filter
                  </div>
                </div> */}
              </div>
              {/* <button
                className="py-2.5 px-4 border rounded-lg bg-primary text-white w-fit flex items-center justify-baseline gap-2"
                onClick={() => setShowCreateModal(true)}
              >
                <Icon src={plusWhiteIcon} /> Create Approval
              </button> */}
            </div>
            {selectedView === 'table' ? (
              <ApprovalRequestTableView
                approvalRequests={approvalRequestData[workspaceID]}
                handleDelete={handleDeleteModal}
              />
            ) : selectedView === 'card' ? (
              <ApprovalRequestCardView
                approvalRequests={approvalRequestData[workspaceID]}
                handleDelete={handleDeleteModal}
              />
            ) : (
              <ApprovalRequestBoardView
                approvalRequests={approvalRequestData[workspaceID]}
                handleDelete={handleDeleteModal}
              />
            )}
          </div>
        ) : isLoading ? null : (
          <div className="flex items-center flex-col">
            <div className="h-[480px] w-[480px] flex flex-col justify-end radial-background">
              <div className="w-full h-full radial-g ">
                <div className="w-full h-full flex flex-col justify-end items-center gap-8 px-8 py-8">
                  <Icon
                    name="search"
                    alt="search"
                    src={searchIcon}
                    className="p-3 border rounded-lg"
                    size={12}
                  />
                  <div className="flex flex-col items-center text-center gap-1">
                    <h3 className="text-lg font-semibold">
                      No Approvals Found
                    </h3>
                    <div className="text-gray-600">
                      This workspace does not have any approval requests at this
                      time. You can create one by clicking the button.
                    </div>
                  </div>
                  <button
                    className="py-2.5 px-4 border rounded-lg bg-primary text-white w-fit flex items-center justify-baseline gap-2"
                    onClick={() => setShowCreateModal(true)}
                  >
                    <Icon src={plusWhiteIcon} /> Create Approval
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <CreateApprovalModal
        show={showCreateModal}
        handleClose={handleCloseCreateModal}
        workspaceID={workspaceID}
      />
      <ApprovalFilterModal
        show={showFilterModal}
        handleClose={() => setShowFilterModal(false)}
      />
      <DeleteModal
        handleClose={() => setOpenDeleteModal(false)}
        openModal={openDeleteModal}
        handleDelete={handleDelete}
        message={`Are you sure you want to delete the selected approval request(s)?`}
        loading={isDeleting}
      />
    </PageWrapper>
  );
};

export default Approvals;
