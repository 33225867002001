import StepperGroup from './StepperGroup';

const StepperIndicator = ({ activeStep = 1, steps = [], handleStepSelect }) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center w-full">
        {steps.map((step, index) => {
          return (
            <StepperGroup
              active={activeStep === index + 1}
              completed={activeStep > index + 1}
              isLast={index + 1 === steps.length}
              key={index}
              selectStep={() => handleStepSelect(index + 1)}
            />
          );
        })}
      </div>
      <div className="flex justify-between w-full">
        {steps.map((steps, index) => {
          return (
            <div
              className="flex flex-col items-center cursor-pointer"
              key={index}
              onClick={() => handleStepSelect(index + 1)}
            >
              <p className="text-xs text-gray-700">{steps}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepperIndicator;
