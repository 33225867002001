import Icon from '../Icon';
import TableCell from './TableCell';
import deleteIcon from '../../icons/trash-icon.png';
import { useEffect, useState } from 'react';

const TableHead = ({
  onSelect,
  fields = [],
  deleteAction,
  showActions,
  partial,
  allSelected,
  cols,
}) => {
  // let gridClass = `grid-cols-${fields.length + (showActions ? 1 : 0)}`;
  const [gridClass, setGridClass] = useState('');

  useEffect(() => {
    setGridClass(`grid-cols-${fields.length * 2 + (showActions ? 1 : 0)}`);
  }, [fields, showActions]);

  return (
    <thead className="sticky top-0">
      <tr
        className={`grid border-b py-3 bg-primary-50 rounded-t-lg`}
        style={{
          gridAutoColumns: 'max-content',
          gridTemplateColumns: `repeat(${
            cols || fields.length + (showActions ? 1 : 0)
          }, minmax(0, 1fr))`,
        }}
      >
        {fields.map(({ value, span }, index) => (
          <TableCell
            key={index}
            value={value}
            partial={partial}
            span={span}
            allSelected={allSelected}
            {...(index === 0 && {
              onSelect,
              allSelected,
              partial,
            })}
          />
        ))}
        {showActions && (
          <TableCell>
            <div className="flex gap-6 justify-end px-6">
              {deleteAction && (partial || allSelected) ? (
                <Icon
                  src={deleteIcon}
                  alt="edit"
                  onClick={deleteAction}
                  size={4}
                />
              ) : null}
            </div>
          </TableCell>
        )}
      </tr>
    </thead>
  );
};

export default TableHead;
