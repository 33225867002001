import CompletedStep from '../SVGs/CompletedStep';
import PendingStep from '../SVGs/PendingStep';

const StepperCheckPoint = ({ active, selectStep }) => {
  return (
    <div onClick={selectStep} className="cursor-pointer">
      {active ? <CompletedStep /> : <PendingStep />}
    </div>
  );
};

export default StepperCheckPoint;
