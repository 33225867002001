import React, { useState } from 'react';
import FormRadioButton from '../Form/FormRadioButton';
import FormInput from '../Form/FormInput';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { lazyImageLoader } from '../../utils/html-utils';

const WebUploadSelector = ({
  loading,
  setIsLoading,
  onFileChange,
  closeUploadModal,
  setTitle,
  setHtmlContent,
  setURL,
  setPlatform,
  platform = '',
}) => {
  const fileUploadRef = React.createRef(null);
  const toast = useToast();

  const [selectedWebContentType, setSelectedWebContentType] =
    useState(platform);
  const [selectedEmailUploadMethod, setSelectedEmailUploadMethod] =
    useState('');
  const [selectedWebUrl, setSelectedWebUrl] = useState('');

  const [stage, setStage] = useState(platform ? 2 : 1);

  const handleWebContentTypeChange = (value) => {
    setPlatform(value, value);
    setSelectedWebContentType(value);
  };

  const handleEmailUploadMethodChange = (value) => {
    setSelectedEmailUploadMethod(value);
  };

  const handleWebUrlChange = (e) => {
    setSelectedWebUrl(e.target.value);
  };

  const handleUploader = () => {
    closeUploadModal();
  };

  const fetchHtmlContent = async (url) => {
    setIsLoading(true);

    // Function to check if the URL is valid
    const isValidHttpUrl = (string) => {
      let url;

      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === 'http:' || url.protocol === 'https:';
    };

    // Prepend 'http://' if the URL is missing it
    if (
      !isValidHttpUrl(url) &&
      !url.startsWith('http://') &&
      !url.startsWith('https://')
    ) {
      url = 'https://' + url;
    }

    // Check if the modified URL is valid
    if (!isValidHttpUrl(url)) {
      toast({
        title: 'Invalid URL',
        description: 'The URL provided is not valid.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://jznalehjc7.execute-api.us-east-2.amazonaws.com/default/getHTML',
        { url: url }
      );
      // Assuming response.data contains the HTML string fetched from the API
      const htmlString = response.data;

      // Use DOMParser to parse the HTML string
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');

      // Query all anchor tags
      const links = doc.querySelectorAll('a');

      // Fix the issue of lazy loading
      lazyImageLoader(doc);

      // Loop through each link to modify external links
      links.forEach((link) => {
        // Check if the link is external
        // if (link.hostname !== window.location.hostname) {
        // Method 1: Remove the href attribute
        link.removeAttribute('href');

        // Method 2: Add a click event listener that prevents navigation
        // link.addEventListener('click', function(e) {
        //   e.preventDefault();
        //   alert('External links are disabled.');
        // });

        // Additional modifications as needed
        // }
      });

      // Serialize the modified document back to a string
      const serializer = new XMLSerializer();
      const modifiedHtmlString = serializer.serializeToString(doc);

      // Now, modifiedHtmlString contains the modified HTML
      // You can set it as the content of an element in your DOM
      // setHtmlContent(modifiedHtmlString);

      setHtmlContent({ src: response.data, type: 'html' });

      setIsLoading(false);
      setURL(url);
    } catch (error) {
      console.error('Error fetching HTML content:', error);
      toast({
        title: 'An error occurred.',
        description: `There was an error while fetching the contents of: ${url}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    } finally {
      closeUploadModal();
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]; // Get the first file only
    if (file && file.type === 'text/html') {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Update the state with the HTML content
        setHtmlContent({ src: reader.result, type: 'html' });
      };
      reader.readAsText(file); // Read the file as text
    } else {
      // alert('Please upload a valid HTML file.');
      toast({
        title: 'An error occurred.',
        description: `Please upload a valid HTML file.`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    closeUploadModal();
  };

  const handleNext = () => {
    if (stage === 1 && selectedWebContentType) {
      if (['website', 'landingPage'].includes(selectedWebContentType) || selectedEmailUploadMethod === 'link') {
        setTitle('Add your content URL');
      } else {
        setTitle('Select your upload method');
      }
      setStage(2);
      return;
    } else if (stage === 2) {
      if (['website', 'landingPage'].includes(selectedWebContentType) || selectedEmailUploadMethod === 'link') {
        fetchHtmlContent(selectedWebUrl)
          .then(() => {
            closeUploadModal();
          })
          .catch((error) => {
            toast({
              title: 'An error occurred.',
              description:
                'There was an error while fetching the contents of the page.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            console.error('Error fetching HTML content:', error);
          });
      } else {
        fileUploadRef.current.click();
      }
    }
  };

  const handlePrev = () => {
    if (stage === 2) {
      setTitle('Select the type of content');
      setSelectedEmailUploadMethod('');
      setStage(1);
    } else if (stage === 1) {
      closeUploadModal();
    }
  };

  return (
    <div className="flex flex-col items-center gap-2 text-sm pt-2">
      {stage === 1 && (
        <>
          <div className="flex justify-start w-full">
            <FormRadioButton
              labels={[
                {
                  label: 'Landing page',
                  value: 'landingPage',
                },
                {
                  label: 'Email',
                  value: 'email',
                },
                {
                  label: 'Website',
                  value: 'website',
                },
              ]}
              onClick={handleWebContentTypeChange}
              defaultValue={selectedWebContentType}
            />
          </div>
        </>
      )}

      {stage === 2 && (
        <>
      {   (['landingPage', 'website'].includes(selectedWebContentType)  || selectedEmailUploadMethod === 'link') && <p className="flex justify-start w-full">
            This must be a live URL that has been published online.
          </p>}
          {(['landingPage', 'website'].includes(selectedWebContentType) || selectedEmailUploadMethod === 'link') ? (
            <div className="flex justify-start w-full">
              <FormInput
                id={'webUrl'}
                label={'Link to content'}
                type={'text'}
                placeholder={'Enter URL'}
                onChange={handleWebUrlChange}
                // value={approverData.firstName}
                name={'webUrl'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    fetchHtmlContent(e.target.value);
                    // if (emailContentLink) {
                    //   setEmailContentLink(false);
                    // }
                  }
                }}
                // hint={errors.firstName}
                // error
              />
            </div>
          ) : (
            <div className="flex justify-start w-full">
              <FormRadioButton
                labels={[
                  {
                    label: 'Device',
                    value: 'device',
                  },
                  {
                    label: 'Link',
                    value: 'link',
                  },
                ]}
                onClick={(value) => {
                  if (value === 'device') {
                    handleEmailUploadMethodChange();
                  }
                   else {
                    setSelectedEmailUploadMethod(value);
                    setTitle('Add your content URL');
                  }
                }}
                defaultValue={selectedEmailUploadMethod}
              />

            </div>
          )}
        </>
      )}

      <input
        ref={fileUploadRef}
        type="file"
        className="hidden"
        onChange={handleImageUpload}
        accept="text/html"
      />

      <div className="flex justify-between w-full gap-4 mt-2">
        <button
          className="border rounded-lg py-2.5 px-5 w-full"
          onClick={handlePrev}
          disabled={loading}
        >
          Cancel
        </button>
        <button
          className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
          onClick={handleNext}
          disabled={loading}
        >
         { loading ? "Please wait" : "Confirm"}
        </button>
      </div>
    </div>
  );
};

export default WebUploadSelector;
