export class Logger {
  static log(...message) {
    if (process.env.NODE_ENV !== 'production') {
      console.log(...message);
    }
  }

  static error(...message) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(...message);
    }
  }

  static warn(...message) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(...message);
    }
  }
}
