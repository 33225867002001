import Icon from "../Icon";
import plusIcon from '../../icons/plus-circle.png';

const AddWorkspaceCard = ({ onClick }) => {
  return (
    <div className="border border-dashed max-w-[276px] w-full flex flex-col justify-center items-center rounded-lg text-center min-w-[150px] min-h-[190px]" onClick={onClick}>
      <Icon src={plusIcon} alt="add workspace" />
      <p>Add new workspace</p>
    </div>
  );
};

export default AddWorkspaceCard;