import { useToast } from '@chakra-ui/react';
import axios from 'axios';

const PreferenceModal = ({
  user,
  fetchData,
  setShowModal,
  reminders,
  setReminders,
}) => {
  const toast = useToast();

  const handleReminderCountChange = (e) => {
    const count = parseInt(e.target.value);
    const newReminders = [...reminders];

    if (count < newReminders.length) {
      setReminders(newReminders.slice(0, count));
    } else {
      for (let i = newReminders.length; i < count; i++) {
        newReminders.push({ id: i + 1, days: 0, hours: 0 });
      }
      setReminders(newReminders);
    }
  };

  const handleDayChange = (id, days) => {
    setReminders(
      reminders.map((reminder) =>
        reminder.id === id ? { ...reminder, days: days } : reminder
      )
    );
  };

  const handleHourChange = (id, hours) => {
    setReminders(
      reminders.map((reminder) =>
        reminder.id === id ? { ...reminder, hours: hours } : reminder
      )
    );
  };

  const checkRemindersValidity = () => {
    let lastTotalHours = 0;

    for (const reminder of reminders) {
      if (reminder.days === 0 && reminder.hours === 0) {
        toast({
          title: 'Invalid Reminder Setting',
          description: 'No reminder can have both 0 days and 0 hours.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return false;
      }

      const totalHours = reminder.days * 24 + reminder.hours;
      if (totalHours <= lastTotalHours) {
        toast({
          title: 'Invalid Reminder Sequence',
          description:
            'Each subsequent reminder must be set for a later time than the previous one.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return false;
      }
      lastTotalHours = totalHours;
    }

    return true;
  };

  const handleSavePreferences = async () => {
    if (checkRemindersValidity()) {
      try {
        const response = await axios.post(`/api/auth/update-user-preferences`, {
          _id: user._id,
          notificationPreferences: reminders,
        });
        toast({
          title: 'Your changes have been saved.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        await fetchData();
        setShowModal(false);
      } catch (error) {
        toast({
          title: 'An error occurred.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="flex flex-col w-full max-w-xl p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content">
        <div className="justify-center mb-4 text-center">
          <h2 className="font-bold text-2xl">
            Approver Notification Preferences
          </h2>
          <p className="text-secondary leading-5">
            Customize how many and how often approvers will receive automated
            email notifications to review content.
          </p>
        </div>

        {/* Preference Settings */}

        {/* Number of reminders selector */}
        <div className="mb-4 flex flex-1">
          <label
            htmlFor="reminder-count"
            className="block text-gray-700 text-sm font-bold mb-2 w-5/6"
          >
            # of approver reminders
          </label>
          <select
            id="reminder-count"
            onChange={handleReminderCountChange}
            defaultValue={reminders?.length || 2}
            className="shadow border rounded w-1/6 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            {/* Options for reminder count */}
            {[...Array(5).keys()].map((number) => (
              <option key={number} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </select>
        </div>

        <div>
          {reminders.map((reminder, index) => (
            <div
              key={reminder.id}
              className="flex justify-between items-center mb-4"
            >
              <span className="text-gray-700 text-sm font-bold">
                Reminder {index + 1}
              </span>
              <div className="flex">
                <select
                  value={reminder.days}
                  onChange={(e) =>
                    handleDayChange(reminder.id, parseInt(e.target.value))
                  }
                  className="border rounded-l py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={0}>0 days</option>
                  {/* Options for days */}
                  {[...Array(31).keys()].slice(1).map((day) => (
                    <option key={day} value={day}>
                      {day} days
                    </option>
                  ))}
                </select>
                <select
                  value={reminder.hours}
                  onChange={(e) =>
                    handleHourChange(reminder.id, parseInt(e.target.value))
                  }
                  className="border rounded-r py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  {/* Options for hours */}
                  {[...Array(24).keys()].map((hour) => (
                    <option key={hour} value={hour}>
                      {hour} hours
                    </option>
                  ))}
                </select>
              </div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div
          className="flex items-center mt-4 w-5/6  justify-center "
          style={{ margin: '0 auto' }}
        >
          <button
            className="w-1/2 -bg-transparent hover-bg hover-shadow text-primary font-semibold py-4 px-4 mx-2 bg-dashGray rounded-xl"
            onClick={() => setShowModal(false)}
          >
            Back
          </button>
          <button
            className="w-1/2 bg-primary text-white font-bold py-4 px-4 mx-2 rounded-xl hover-shadow"
            onClick={() => handleSavePreferences()}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreferenceModal;
