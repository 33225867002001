import { FaChevronRight } from 'react-icons/fa';

const RightArrow = ({ onClick, isVisible }) =>
  isVisible && (
    <div
      className="absolute right-0 top-1/2 transform -translate-y-1/2 cursor-pointer z-10"
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '3rem',
        height: '3rem',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '50%',
        color: 'white',
      }}
    >
      <FaChevronRight size={20} />
    </div>
  );

export default RightArrow;