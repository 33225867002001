import ModalContent from './ModalContent';

const DefaultModal = ({
  title,
  description,
  content,
  closeModal,
  isOpen = true,
  handleConfirm,
  isLoading,
}) => {
  const handleCloseModal = () => {
    !isLoading && closeModal();
  };

  return (
    <ModalContent
      title={title}
      className={'max-w-[480px] w-full'}
      closeModal={handleCloseModal}
      openModal={isOpen}
    >
      <div className="flex flex-col items-center gap-2 text-sm pt-2">
        <p className="flex justify-start w-full">{description}</p>
        <div className="flex justify-start w-full gap-3 font-semibold">
          {content}
        </div>

        <div className="flex justify-between w-full gap-4 mt-2">
          <button
            className="border rounded-lg py-2.5 px-5 w-full"
            onClick={handleCloseModal}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
            onClick={handleConfirm}
          >
            {isLoading ? 'Confirming...' : 'Confirm'}
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default DefaultModal;
