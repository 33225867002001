import { useToast } from '@chakra-ui/react';
import { validateEmailOrToast } from '../../utils/validateUtils';
import uploadToS3 from '../../utils/uploadToS3';
import axios from 'axios';
import { FaPencilAlt, FaRegUserCircle } from 'react-icons/fa';
import { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from '../../firebase/firebaseConfig';
import { Logger } from '../../utils/logger';

const ProfileModal = ({
  profilePicURL,
  mediaIsChanged,
  imageData,
  handleProfilePicChange,
  firstName,
  lastName,
  email,
  setIsUploadModalOpen,
  setFirstName,
  setLastName,
  user,
  fetchData,
  setEmail,
  setShowModal,
  // handlePasswordUpdate,
  // changePassword, setChangePassword
}) => {
  const toast = useToast();
  const [changePasswordView, setChangePasswordView] = useState(false);
  const [changePassword, setChangePassword] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleSaveChanges = async () => {
    try {
      if (!validateEmailOrToast(email, toast)) {
        return;
      }
      // Assuming you have set up a PUT endpoint like "/api/user/update/:id"
      let imageUri = await uploadToS3(imageData, user);
      const response = await axios.post(`/api/auth/user/update/${user._id}`, {
        firstName,
        lastName,
        email,
        profilePicURL: mediaIsChanged ? imageUri : null,
      });
      // setUser(response.data);
      toast({
        title: 'Your changes have been saved.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      await fetchData();
      setShowModal(false); // Close the modal after saving changes
    } catch (error) {
      let description = 'Unable to save changes.';

      // Check if the error is specifically related to the email
      if (
        error.response &&
        error.response.data &&
        error.response.data.msg.includes('Email already in use')
      ) {
        description = error.response.data.msg;
      }

      toast({
        title: 'An error occurred.',
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePasswordUpdate = async () => {
    try {
      if (
        !changePassword.password ||
        !changePassword.newPassword ||
        !changePassword.confirmNewPassword
      ) {
        toast({
          title: 'Please fill in all fields.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (changePassword.newPassword !== changePassword.confirmNewPassword) {
        toast({
          title: 'Passwords do not match.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const auth = getAuth(firebaseApp);
      signInWithEmailAndPassword(auth, email, changePassword.password)
        .then(async (userCredential) => {
          var fBUser = userCredential.user;

          await axios.patch(`/api/auth/user/${user._id}/password`, {
            firebaseUUID: fBUser.uid,
            newPassword: changePassword.newPassword,
          });

          toast({
            title: 'Password changed successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          setChangePassword({
            password: '',
            newPassword: '',
            confirmNewPassword: '',
          });

          setChangePasswordView(false);
        })
        .catch((error) => {
          let description = 'Unable to change password.';
          if (error.code === 'auth/wrong-password') {
            description = 'Incorrect password';
          } else if (error.response && error.response.data) {
            description =
              error.response?.data?.message ??
              error.response?.data ??
              error.description;
          }

          toast({
            title: 'An error occurred.',
            description: description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      Logger.error('Password update error:', error);
    }
  };

  const handleResendVerificationEmail = async () => {
    if (user.emailVerified) {
      toast({
        title: 'Email already verified.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.get(
        `api/users/resend-verification-email?firebaseAuthUUID=${user.firebaseAuthUUID}`
      );

      toast({
        title: 'Verification Email sent',
        status: 'success',
        toastId: 'resend-verification',
      });
    } catch (error) {
      toast({
        title: 'Unable to send verification email.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePasswordChange = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <div className="modal-backdrop">
      {
        <div className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content">
          {changePasswordView ? (
            <div className="flex flex-col flex-grow">
              <div className="justify-center mb-4 text-center">
                <h2 className="font-bold text-xl">Change Password</h2>
                <p className="text-secondary leading-5 text-lg">
                  Update your password
                </p>
              </div>

              <div className="mt-4">
                <input
                  value={changePassword.password}
                  onChange={handlePasswordChange}
                  className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Password"
                  required
                />
                <input
                  value={changePassword.newPassword}
                  onChange={handlePasswordChange}
                  className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="newPassword"
                  type="password"
                  placeholder="New Password"
                  required
                  minLength={4}
                />
                <input
                  value={changePassword.confirmNewPassword}
                  onChange={handlePasswordChange}
                  className="w-full bg-gray-50 px-3 py-2 mb-0 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="confirmNewPassword"
                  type="password"
                  placeholder="Confirm New Password"
                  minLength={4}
                />
              </div>

              <button
                className="w-full mt-4 text-lg px-4 py-2 hover-shadow font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                type="button"
                onClick={handlePasswordUpdate}
              >
                Change Password
              </button>
            </div>
          ) : (
            <div className="flex flex-col flex-grow">
              <div className="justify-center mb-4 text-center">
                <h2 className="font-bold text-xl">Edit Profile</h2>
                <p className="text-secondary leading-5 text-lg">
                  Update your personal information here
                </p>
              </div>

              {/* Profile Picture Section */}
              <div className="relative text-center mb-2 flex justify-center w-fit self-center">
                {profilePicURL && !mediaIsChanged ? (
                  <img
                    src={profilePicURL}
                    alt="Profile"
                    className="inline-block cursor-pointer h-20 w-20 rounded-full object-cover"
                    onClick={() => setIsUploadModalOpen(true)}
                  />
                ) : (
                  <>
                    {mediaIsChanged ? (
                      <img
                        src={imageData[0].data}
                        className="w-20 h-20 cursor-pointer hover-shadow rounded-full object-cover"
                        onClick={() => setIsUploadModalOpen(true)}
                      />
                    ) : (
                      <FaRegUserCircle
                        size={60}
                        className="cursor-pointer self-center hover-shadow hover-bg rounded-full"
                        onClick={() => setIsUploadModalOpen(true)}
                      />
                    )}
                  </>
                )}
                <div className="absolute bottom-0 right-0 p-1 bg-dashGray hover-bg hover-shadow cursor-pointer rounded-full">
                  <FaPencilAlt onClick={() => setIsUploadModalOpen(true)} />
                </div>
                <input
                  type="file"
                  id="profilePicInput"
                  className="hidden"
                  onChange={handleProfilePicChange}
                />
              </div>

              <div className="mt-4">
                <input
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                />
                <input
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full bg-gray-50 px-3 py-2 mb-0 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                  id="lastName"
                  type="text"
                  placeholder="Email"
                />
              </div>

              {user.emailVerified ? (
                <div className="text-center text-gray-400 mt-4">
                  <p className="text-xs">Email has been verified</p>
                </div>
              ) : (
                <div className="text-center text-gray-400 mt-4">
                  <p className="text-xs">
                    Email has not been verified.{' '}
                    <span
                      className="text-primary cursor-pointer"
                      onClick={handleResendVerificationEmail}
                    >
                      Resend verification email
                    </span>
                  </p>
                </div>
              )}

              <div className="text-center text-gray-400 mt-4">
                <p className="text-xs">
                  Change password{' '}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setChangePasswordView(true)}
                  >
                    here
                  </span>
                </p>
              </div>

              <button
                className="w-full mt-4 text-lg px-4 py-2 hover-shadow font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                type="button"
                onClick={() => handleSaveChanges()}
              >
                Save Changes
              </button>
            </div>
          )}

          <div className="items-center text-center text-gray-400 mt-8">
            <button
              onClick={() =>
                changePasswordView
                  ? setChangePasswordView(false)
                  : setShowModal(false)
              }
              className="text-xs underline"
            >
              {changePasswordView ? 'Back' : 'Cancel'}
            </button>
          </div>
        </div>
      }
    </div>
  );
};

export default ProfileModal;
