export const extractMentions = (value) => {
  const mentionPattern =
    /(?<=\s|^)@[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*(?:\.[a-zA-Z]{2,})(?=\s|$)|(?<=\s|^)@[a-zA-Z0-9_]+(?=\s|$)/g;
  const detectedMentions = value.match(mentionPattern) || [];
  return detectedMentions;
};

export const getMentionedUsersEmail = (mentions, users = []) => {
  const mappedUsers = users.reduce((mapper, user) => {
    return { ...mapper, [user.email]: user.email };
  }, {});

  return mentions
    .map((mention) => {
      let nameOrEmail = mention.substring(1);
      return mappedUsers[nameOrEmail];
    })
    .filter((v) => !!v);
};
