/* global Dropbox */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import axios from 'axios';
import AWS from 'aws-sdk';
import uploadToS3 from '../utils/uploadToS3';
import uploadVideoToS3 from '../utils/uploadVideoToS3';
import { useGoogleLogin } from '@react-oauth/google';
import useDrivePicker from 'react-google-drive-picker';
import { useToast } from '@chakra-ui/react';
import ReactConfetti from 'react-confetti';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { imagesDataAtom } from '../recoil/imageDataAtom';
// import { planValidationEditAppReq } from '../utils/checkNewARAgainstPlan';
import priceToPlan from '../utils/priceIdToPlan';

import ContentViewer from '../components/ContentViewer/ContentViewer';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';

const EditApprovalRequest = () => {
  const [members, setMembers] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [user] = useRecoilState(userAtom);
  const history = useNavigate();
  const workspaceID = new URLSearchParams(window.location.search).get(
    'workspaceID'
  );
  const [socialFormat, setSocialFormat] = useState('');
  const [approvalTitle, setApprovalTitle] = useState('');
  const [dueDate, setDueDate] = useState(new Date());
  const [description, setDescription] = useState('');
  const [caption, setCaption] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [selectedApprovals, setSelectedApprovals] = useState([]);
  const { approvalReqID } = useParams();
  const [contentTypeCategory, setContentTypeCategory] = useState({});

  const [imageData, setImageData] = useRecoilState(imagesDataAtom);
  const [currentImage, setCurrentImage] = useState(0);
  const [isValidLink, setIsValidLink] = useState(true);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);
  const [showSentModal, setShowSentModal] = useState(false);
  const [isNextSlide, setIsNextSlide] = useState(false);
  const titleRef = useRef();
  const toast = useToast();
  const descriptionRef = useRef();
  const caprionRef = useRef();

  const [imageDeleted, setImageDeleted] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);

  const linkRef = useRef();

  const [fileProgress, setFileProgress] = useState({});
  const [numVideosUploaded, setNumVideosUploaded] = useState(0);
  const [numImagesUploaded, setNumImagesUploaded] = useState(0);
  const [numDocsUploaded, setNumDocsUploaded] = useState(0);

  const [videoUrls, setVideoUrls] = useState([]);
  const [isPolling, setIsPolling] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const playerWrapperRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [openPicker, authResponse] = useDrivePicker();
  const [token, setToken] = useState(null);

  const customStyle = `
  .react-datepicker__day--selected {
    background-color: #0D76DB !important; /* Selected day background */
    color: #fff !important; /* Selected day text color */
  }
  .react-datepicker__day--selected:hover {
    background-color: #1d5d90 !important; /* Darken selected day on hover */
  }
  .react-datepicker {
    border-radius: 10px; /* Rounded corners for the calendar */
    border: 1px solid #ccc; /* Thin gray border */
    background-color: #fff; /* Calendar background */
  }
  .react-datepicker__header {
    background-color: #0D76DB; /* Header background */
    border-top-left-radius: 10px; /* Rounded corner top left */
    border-top-right-radius: 10px; /* Rounded corner top right */
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header,
  .react-datepicker__day-name {
    color: #fff; /* Text color for the header and day names */
  }
  .react-datepicker__navigation-icon::before {
    border-color: #fff; /* Make the navigation arrows white */
  }
  .react-datepicker__triangle {
    display: none; /* Hide the triangle */
  }
`;

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const addImageData = (newData) => {
    newData = { ...newData, index: imageData?.length };
    setImageData((preImageData) => [...preImageData, newData]);
    setMediaIsChanged(true);
    Logger.log(imageData);
    Logger.log(newData);
  };

  const fetchExistingApprovalRequest = async () => {
    try {
      const res = await axios.get(`/api/approvalRequests/get/${approvalReqID}?firebaseUID=${user?.firebaseAuthUUID}`);
      const data = res.data.approvalRequest;

      setApprovalTitle(data.approvalTitle.trim());
      setDueDate(new Date(data.dueDate));
      setDescription(data.description);
      setCaption(data.content.caption.trim());
      setLink(data.content.link);
      setImageData(data.content?.media);
      setApprovers(data.approvers);
      setLink(data.link);
      Logger.log(res.data);
      setContentTypeCategory(data.contentTypeCategory);
      setSocialFormat(data.socialFormat);
    } catch (error) {
      Logger.error('Error fetching existing approval request:', error);
    }
    setIsLoading(false);
  };

  const processFiles = async (type) => {
    const newVideoUrls = [];
    let additionalSizeMB = 0; // Initialize additional size

    // Process image files\
    // Logger.log(imageData);
    let photos = imageData?.filter((f) => f?.type === 'photo');
    let oldPhotos = imageData?.filter(
      (f) => f?.media === type && (f.type == null || f.type == undefined)
    );
    // let oldVideos = imageData?.filter(f => (f?.media === 'video' && (f.type == null || f.type == undefined)))
    // Logger.log('photos ', photos);
    // Logger.log('oldPhotos ', oldPhotos);
    // Logger.log('oldVideos ', oldVideos);
    const imagesNew = await uploadToS3(
      imageData?.filter((f) => f.type === type),
      user,
      contentTypeCategory.subCategory === 'document'
        ? contentTypeCategory.subCategory
        : undefined
    );
    additionalSizeMB += imagesNew.reduce((acc, curr) => acc + curr.size, 0);

    for (const file of imageData?.filter((f) => f.type === 'video')) {
      const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
      additionalSizeMB += moveResult.size; // Accumulate video file sizes
      newVideoUrls.push(moveResult);
    }

    // Logger.log('newVideoUrls ', newVideoUrls);

    setTimeout(() => {
      setTotalFileSize(additionalSizeMB);
    }, 1000); // 1000 milliseconds = 1 second

    // Output the result
    const combinedUrls = imagesNew.concat(newVideoUrls).concat(oldPhotos);
    return [combinedUrls, additionalSizeMB];
  };

  const s3 = new AWS.S3();

  const moveVideoToBucket = async (videoUrl, targetBucket) => {
    // Extract the bucket name and key from the URL
    const sourceBucket = videoUrl.split('.s3.')[0].split('://')[1];
    const key = videoUrl.split('.com/')[1];

    const { ContentLength } = await s3
      .headObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();
    const fileSizeMB = ContentLength / (1024 * 1024); // Convert size to MB
    Logger.log('fileSizeMB ', fileSizeMB);

    // Copy the object to the new bucket
    await s3
      .copyObject({
        Bucket: targetBucket,
        CopySource: `${sourceBucket}/${key}`,
        Key: key,
      })
      .promise();

    // Delete the original object
    await s3
      .deleteObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();

    // Return the new URL
    let url = `https://${targetBucket}.s3.${AWS.config.region}.amazonaws.com/${key}`;
    return {
      media: 'video',
      type: 'video',
      url: url,
      size: fileSizeMB, // Include the size in the return object
    };
  };

  const pollForVideoUrl = async (uploadPath) => {
    try {
      const response = await axios.post(`/api/auth/check-url`, {
        partialPath: uploadPath,
      });
      if (response.data.found) {
        setVideoUrls((prevUrls) => [...prevUrls, response.data.url]);
        addImageData({
          name: response.data.url,
          src: response.data.url,
          data: response.data.url,
          media: 'video',
          type: 'video',
        });
        if (videoUrls.length === Object.keys(fileProgress).length) {
          setIsPolling(false);
          setFileProgress({});
        }
      } else {
        setTimeout(() => pollForVideoUrl(uploadPath), 6000);
      }
    } catch (error) {
      Logger.error('Error polling for video URL:', error);
    }
  };

  useEffect(() => {
    fetchExistingApprovalRequest();
    return () => {
      setImageData([]);
    };
  }, []);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setToken(codeResponse.access_token);
      openPicker({
        clientId:
          '701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com',
        developerKey: 'AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo',
        viewId: 'DOCS_IMAGES_AND_VIDEOS',
        token: token,
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: true,
        // customViews: customViewsArray, // custom view
        callbackFunction: async (data) => {
          if (data.action === 'cancel') {
            Logger.log('User clicked cancel/close button');
          } else if (data.action === 'picked') {
            try {
              const imageDataArray = [];
              const videoDataPromises = [];

              // Separate image and video files from Google Drive
              const imageDocs = data.docs.filter((doc) =>
                doc.mimeType.includes('image/')
              );
              const videoDocs = data.docs.filter((doc) =>
                doc.mimeType.includes('video/')
              );
              setNumVideosUploaded(numVideosUploaded + videoDocs.length);
              setNumImagesUploaded(numImagesUploaded + imageDocs.length);

              setIsLoading3(true);
              for (const doc of data.docs) {
                const response = await fetch(
                  `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`,
                  {
                    headers: new Headers({ Authorization: `Bearer ${token}` }),
                  }
                );
                const blob = await response.blob();

                if (doc.mimeType.includes('image/')) {
                  // For images, convert to base64 and add to imageDataArray
                  const reader = new FileReader();
                  reader.readAsDataURL(blob);
                  await new Promise((resolve) => {
                    reader.onloadend = () => {
                      imageDataArray.push({
                        data: reader.result, // Base64 string
                        type: 'image',
                        name: doc.name,
                      });
                      resolve();
                    };
                  });
                } else if (doc.mimeType.includes('video/')) {
                  // For videos, prepare for uploading to AWS
                  videoDataPromises.push(
                    new Promise((resolve) => {
                      const reader = new FileReader();
                      reader.onload = (e) =>
                        resolve({
                          data: e.target.result,
                          media: 'video',
                          type: 'video',
                        });
                      reader.readAsDataURL(blob);
                    })
                  );
                }
              }

              // setImageDataFunc(imageDataArray);
              imageDataArray.forEach((imageData) => {
                addImageData(imageData);
              });

              // Initialize progress for video uploads
              const initialProgress = videoDocs.reduce((acc, _, index) => {
                acc[`file${index}`] = 0;
                return acc;
              }, {});
              setIsLoading3(false);
              setFileProgress(initialProgress);

              // Handle video upload
              const videosData = await Promise.all(videoDataPromises);
              const uploadResults = await Promise.all(
                videosData.map((data, index) => {
                  return uploadVideoToS3([data], user, (progress) => {
                    setFileProgress((prevProgress) => ({
                      ...prevProgress,
                      [`file${index}`]: progress,
                    }));
                  });
                })
              );

              uploadResults.forEach((result) => {
                setIsPolling(true);
                pollForVideoUrl(result[0].key);
              });
            } catch (error) {
              Logger.log('error', error);
              setIsLoading3(false);
            }
          }
        },
        authImmediate: false,
        onAuthenticate: (oauthToken) => {
          Logger.log('OAuth Token:', oauthToken);
          // You can save the oauthToken here for further use
        },
      });
    },
    onError: (error) => Logger.log('Login Failed:', error),
  });

  const handleOpenPicker = () => {
    login();
  };

  function proceedWithRemoval(index) {
    // Determine the new index based on the current state and the item being removed
    let newIndex;
    if (imageData?.length === 1) {
      newIndex = 0;
    } else if (imageData?.length > 1 && currentImage === 0) {
      newIndex = 0;
    } else {
      newIndex = currentImage - 1;
    }

    // Update the state based on the type of the item being removed
    if (imageData[index].type === 'video') {
      setNumVideosUploaded(numVideosUploaded - 1);
    } else {
      setNumImagesUploaded(numImagesUploaded - 1);
    }

    // Filter out the item being removed from imageData
    let imDat = imageData?.filter((_, idx) => idx !== index);
    Logger.log(imDat);

    // Update the states
    setImageDeleted(true);
    setCurrentImage(newIndex);
    setImageData(imDat);
    setMediaIsChanged(true);
  }

  function removeItemAtIndex(index) {
    // First, check if the item to be removed is a video
    if (imageData[index].type === 'video') {
      setIsPlaying(false); // Ensure the ReactPlayer stops playing the video

      // Allow a brief moment for the video to stop
      setTimeout(() => {
        proceedWithRemoval(index);
      }, 100);
    } else {
      // If it's not a video, proceed with removal immediately
      proceedWithRemoval(index);
    }
  }

  // TODO: Remove this code during clean up
  //   const InstagramPost = (
  //     platform
  //   ) => {
  //     const fileInputRef = React.createRef();
  //     const [isModalOpen, setIsModalOpen] = useState(false);

  //     const openUploadModal = () => {
  //       setIsModalOpen(true);
  //     };

  //     // Function to trigger the hidden file input click event
  //     const triggerFileInput = () => {
  //         fileInputRef.current.click();
  //     };

  //     const openDropboxChooser = () => {
  //       Dropbox.choose({
  //           success: function (files) {
  //               // 'files' is an array of file objects. We'll map over this array to extract the links.
  //               const fileLinks = files.map(file => file.link);
  //               // Pass the array of links to the handler
  //               handleDropboxFiles(fileLinks);
  //           },
  //           linkType: "direct", // "direct" gives a direct link to the file, which we can convert to a blob
  //           multiselect: true, // Allow multiple files to be selected
  //           extensions: ['.jpg', '.png'],
  //       });
  //     };

  //     const loadDropboxSDK = (callback) => {
  //         const script = document.createElement("script");
  //         script.src = "https://www.dropbox.com/static/api/2/dropins.js";
  //         script.id = "dropboxjs";
  //         script.dataset.appKey = "YOUR_APP_KEY";  // Ensure to replace with your actual App key
  //         script.onload = callback;
  //         document.body.appendChild(script);
  //     };

  //     const handleImageUpload = async (event) => {
  //         // const files = Array.from(event.target.files);
  //         // if (!files.length) return;

  //         // // setImageDataFunc([])
  //         // // Separate image and video files
  //         // const imageFiles = files.filter(file => file.type.startsWith('image'));
  //         // const videoFiles = files.filter(file => file.type.startsWith('video'));
  //         // setNumVideosUploaded(videoFiles.length)
  //         // setNumImagesUploaded(imageFiles.length)
  //         // // Add image data directly
  //         // const newImageData = await Promise.all(imageFiles.map(async (file) => {
  //         //     return new Promise((resolve) => {
  //         //         const reader = new FileReader();

  //         //         reader.onloadend = () => {
  //         //             resolve({
  //         //                 name: file.name,
  //         //                 src: reader.result,
  //         //                 data: reader.result,
  //         //                 media: 'photo',
  //         //                 type: 'photo'
  //         //             });
  //         //         };

  //         //         reader.readAsDataURL(file);
  //         //     });
  //         // }));
  //         // // setImageDataFunc(newImageData);
  //         // newImageData.forEach(imageData => {
  //         //   addImageData(imageData);
  //         // });

  //         // // Initialize progress for video uploads
  //         // const initialProgress = videoFiles.reduce((acc, _, index) => {
  //         //     acc[`file${index}`] = 0;
  //         //     return acc;
  //         // }, {});
  //         // setFileProgress(initialProgress);

  //         // // Upload video files
  //         // const videoDataPromises = videoFiles.map(file => {
  //         //     return new Promise((resolve, reject) => {
  //         //         const reader = new FileReader();
  //         //         reader.onload = (e) => resolve({ src: e.target.result, data: e.target.result, media: 'video', tpye: 'video' });
  //         //         reader.onerror = reject;
  //         //         reader.readAsDataURL(file);
  //         //     });
  //         // });

  //         // try {
  //         //     const videosData = await Promise.all(videoDataPromises);
  //         //     const uploadResults = await Promise.all(videosData.map((data, index) => {
  //         //         return uploadVideoToS3([data], user, (progress) => {
  //         //             setFileProgress(prevProgress => ({
  //         //                 ...prevProgress,
  //         //                 [`file${index}`]: progress
  //         //             }));
  //         //         });
  //         //     }));
  //         //     uploadResults.forEach(result => {
  //         //         setIsPolling(true);
  //         //         pollForVideoUrl(result[0].key);
  //         //     });
  //         // } catch (error) {
  //         //     Logger.error('Error processing files:', error);
  //         // }
  //     };

  //   const handleDropboxFiles = async (fileLinks) => {
  //     // Map over the fileLinks to create an array of fetch requests
  //     const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

  //     // Use Promise.all to wait for all of them
  //     const responses = await Promise.all(fetchPromises);

  //     // Convert responses to blobs
  //     const blobs = await Promise.all(responses.map((response) => response.blob()));

  //     // Create a new array of image data from the blobs
  //     const newImageData = await Promise.all(blobs.map(async (blob, index) => {
  //         // Consider extracting the actual filename and MIME type from the Dropbox response
  //         const file = new File([blob], `filename${index}`, { type: blob.type });

  //         return new Promise((resolve) => {
  //             const reader = new FileReader();

  //             reader.onloadend = () => {
  //                 resolve({
  //                     name: file.name,
  //                     src: reader.result,
  //                     data: reader.result,
  //                     media: file.type.startsWith('image') ? 'photo' : 'video',
  //                     type: file.type.startsWith('image') ? 'photo' : 'video'
  //                 });
  //             };

  //             reader.readAsDataURL(file);
  //         });
  //     }));

  //     // Update the state with the new image data, overwriting any existing data
  //     // setImageDataFunc(newImageData);
  //     setNumImagesUploaded(numImagesUploaded + newImageData.length)
  //     setFileProgress({})
  //     newImageData.forEach(imageData => {
  //       addImageData(imageData);
  //   });

  // };

  //     const UploadModal = ({ onClose }) => {
  //       return (
  //         <div className="modal-backdrop">
  //           <div className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">

  //             <div className='flex flex-col w-full'>
  //               <p className='text-xl font-bold text-center mb-4'>Select your upload method</p>
  //               <div className='flex flex-col justify-center'>
  //               {/* Hidden file input */}
  //               <input
  //                 type="file"
  //                 ref={fileInputRef}
  //                 multiple
  //                 onChange={handleImageUpload}
  //                 style={{display: 'none'}}
  //                 accept="image/*,video/*"
  //               />
  //               {/* Button to trigger file input */}
  //               <button
  //                 className='bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 '
  //                 onClick={triggerFileInput}
  //               >
  //                 Device
  //               </button>
  //               {!['document'].includes(platform) && <button
  //                 className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
  //                 onClick={() => {
  //                   loadDropboxSDK();
  //                   openDropboxChooser();
  //                 }}
  //               >
  //                 Dropbox
  //               </button>}

  //               {!['document'].includes(platform) && <button
  //                 onClick={() => {
  //                   handleOpenPicker();
  //                   setIsModalOpen(false);
  //                 }}
  //                 className="bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full"
  //               >
  //                 Google Drive
  //               </button>}

  //               {/* <button className='bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 ' onClick={() => {loadDropboxSDK(); openDropboxChooser();}}>Dropbox</button>

  //                   <button onClick={() => handleOpenPicker()} className='bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full'>
  //                       Google Drive
  //                   </button> */}

  //               </div>
  //               <span className="close text-primary text-center mt-4 cursor-pointer" onClick={onClose}>Cancel</span>

  //             </div>
  //           </div>
  //         </div>
  //       );
  //     }

  //     return (
  //       <div className='mx-auto' style={{ width: 'fit-content', border: imageData?.length > 0 ? '1px solid #ccc' : 'none', borderRadius: '8px', padding: '16px' }}>

  //         {
  //         !(Object.keys(fileProgress).length > 0 && imageData?.length != numVideosUploaded + numImagesUploaded) &&
  //         <div className='flex items-center justify-center mb-2 relative'>
  //             <LeftArrow onClick={() => setCurrentImage(currentImage - 1)} isVisible={currentImage > 0} />
  //             <RightArrow onClick={() => setCurrentImage(currentImage + 1)} isVisible={currentImage < imageData?.length - 1} />
  //             <CloseButton onClick={() => removeItemAtIndex(currentImage)} isVisible={true} />

  //           <div>
  //             {contentTypeCategory.category != 'other' && contentTypeCategory.subCategory != 'other' && <div className='flex flex-row items-center mb-2'>
  //               <img src={logo} className='h-8 w-8 mr-2' style={{border: '1px solid #ccc', borderRadius: '50%'}}/>
  //               <p className='font-bold'>{`${user?.firstName} ${user?.lastName}`}</p>

  //             </div>}
  //             {contentTypeCategory.category != 'other' && contentTypeCategory.subCategory != 'other' && <div className='w-full bg-dashGray h-3 rounded-lg mb-1' style={{backgroundColor: '#e0e0e0'}}/>}
  //             {contentTypeCategory.category != 'other' && contentTypeCategory.subCategory != 'other' && <div className='w-3/5 bg-dashGray h-3 rounded-lg mb-1' style={{backgroundColor: '#e0e0e0'}}/>}
  //           {imageData?.length > 0 &&
  //             <div className='flex items-center justify-center mt-2'>
  //               {mediaIsChanged
  //                 ?
  //                 <>
  //                 {
  //                   imageData[currentImage]?.media === 'photo' ? (
  //                     <img
  //                       src={imageData[currentImage].src}
  //                       alt={`Post ${currentImage}`}
  //                       style={{ maxHeight:'50vh', minHeight: '50vh', objectFit: 'cover', boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
  //                     />
  //                   ) : imageData[currentImage]?.media === 'pdf' ? (<>
  //                     <PdfPreview data={imageData[currentImage].src}/>
  //                   </>) : (
  //                     <div ref={playerWrapperRef} style={{ position: 'relative', width:'fit-content',}}>
  //                       <ReactPlayer
  //                         url={imageData[currentImage]?.src}
  //                         playing={isPlaying}
  //                         width='fit-content'
  //                         // height='100%'
  //                         style={{ maxHeight:'50vh', minHeight: '50vh', objectFit: 'cover', boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
  //                         controls={true}
  //                       />

  //                     </div>
  //                   )
  //                 }
  //                 </>
  //                 :
  //                 <>
  //                 {
  //                   imageData[currentImage]?.media === 'photo' ? (
  //                     <img
  //                       src={imageData[currentImage].src}
  //                       alt={`Post ${currentImage}`}
  //                       style={{ maxHeight:'50vh', minHeight: '50vh', objectFit: 'cover', boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
  //                     />
  //                   ) : imageData[currentImage]?.media === 'pdf' ? (<>
  //                     <PdfPreview data={imageData[currentImage].src}/>
  //                   </>) : (
  //                     <div ref={playerWrapperRef} style={{ position: 'relative', width:'fit-content',}}>
  //                       <ReactPlayer
  //                         url={imageData[currentImage]?.src}
  //                         playing={isPlaying}
  //                         width='fit-content'
  //                         // height='100%'
  //                         style={{ maxHeight:'50vh', minHeight: '50vh', objectFit: 'cover', boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)' }}
  //                         controls={true}
  //                       />

  //                     </div>
  //                   )
  //                 }

  //                 </>

  //               }
  //             </div>
  //           }
  //           </div>
  //         </div>
  //         }
  //         {/* Dots Indicator */}
  //         <div className='flex justify-center space-x-2'>
  //           {imageData?.map((_, idx) => (
  //             <span
  //               key={idx}
  //               onClick={() => handleDotClick(idx)}
  //               className={`inline-block h-2.5 w-2.5 rounded-full cursor-pointer ${currentImage === idx ? 'bg-gray-400' : 'bg-gray-200'}`}
  //             ></span>
  //           ))}
  //         </div>

  //         <div className='flex justify-center w-full mt-4'>
  //         <button
  //           className={`${Object.keys(fileProgress).length > 0 && imageData?.length != numVideosUploaded + numImagesUploaded ? 'bg-secondary cursor-not-allowed' : 'bg-primary'} text-white font-bold hover-shadow px-3 rounded-lg my-2 py-2 `}
  //           onClick={() => {!(Object.keys(fileProgress).length > 0 && imageData?.length != numVideosUploaded + numImagesUploaded) && openUploadModal() }}
  //         >
  //           {imageData[0] ? `Add${Object.keys(fileProgress).length > 0 && imageData?.length != numVideosUploaded + numImagesUploaded ? 'ing': ''} content` : `Upload${Object.keys(fileProgress).length > 0 && imageData?.length != numVideosUploaded + numImagesUploaded ? 'ing': ''} content`}
  //         </button>
  //       </div>

  //       {isModalOpen && (
  //         <UploadModal
  //           onClose={() => setIsModalOpen(false)}
  //           onFileSelect={handleImageUpload}
  //           onDropboxFileSelect={handleDropboxFiles}
  //         />
  //       )}
  //       {Object.keys(fileProgress).length > 0 && imageData?.length != numVideosUploaded + numImagesUploaded && (
  //       <div className={`upload-toast ${true ? 'enter' : 'exit'}`}>
  //       <div className="upload-header">
  //         <span className="upload-title">Uploading Files</span>
  //         {/* <button className="upload-close" >&times;</button> */}
  //       </div>
  //       <div className="upload-body">
  //         {Object.keys(fileProgress).map((key, index) => (
  //           <div key={key} className="upload-progress">
  //             <span className="upload-filename">Uploading video {index + 1}</span>
  //             <div className="upload-progress-bar">
  //               <div className="upload-progress-fill" style={{ width: `${fileProgress[key]}%` }}></div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //       <div className="upload-footer">
  //         {isPolling ? "Processing..." : ""}
  //       </div>
  //     </div>
  //     )}

  //     {isLoading3 &&

  //     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
  //           <div className="flex flex-col w-full max-w-md px-6 py-4 bg-white shadow-lg rounded-xl">
  //             <h2 className="text-lg font-semibold text-center mb-4">Downloading Media</h2>

  //             <div className='flex flex-col gap-4'>
  //               <div className='blackSpinner'></div>
  //             </div>

  //           </div>
  //       </div>
  //       }

  //         </div>
  //         );
  //   }

  const validateRequest = (
    approvalTitle,
    dueDate,
    description,
    approvers,
    imageData
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!imageData) missingFields.push('Content');
    if (approvers.length === 0) missingFields.push('Approvers');

    return missingFields;
  };

  const handleUpdateApprovalRequest = async () => {
    setIsLoading2(true);

    const missingFields = validateRequest(
      approvalTitle,
      dueDate,
      description,
      approvers,
      imageData
    );

    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }

    if (priceToPlan[user?.plan]?.title == 'STARTER') {
      toast({
        title: 'An error occurred.',
        description: `Users on Starter Plan do not have access to version control. Upgrade to Premium to send multiple Approval Requests in one timeline, with comments and version history.`,
        status: 'error',
        duration: 5000,
        isClosable: false,
      });
      setIsLoading2(false);
      return;
    }

    try {
      let imageUrl;
      let mediaSize;
      if (mediaIsChanged) {
        const imData = await processFiles(
          contentTypeCategory.subCategory === 'document'
            ? socialFormat
            : 'image'
        );

        imageUrl = imData[0];
        mediaSize = imData[1];
      }
      // alert('pause  ')
      Logger.log(imageUrl);
      if (imageUrl) {
        imageUrl = imageUrl.map((obj) => ({
          ...obj, // Spread operator to copy existing fields
          type: obj?.media, // Set 'type' field to the value of 'media' field
        }));
      }

      const payload = {
        approvalReqID, // Include the approval request ID
        title: titleRef.current.value,
        dueDate,
        description: descriptionRef.current.value,
        caption: caprionRef.current.value,
        imageUrl,
        approvers,
        link: linkRef.current.value,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: members,
        mediaIsChanged,
        totalFileSize: mediaSize,
      };

      const response = await axios.post(
        '/api/approvalRequests/update',
        payload
      ); // Assuming the endpoint is for PUT requests

      if (response.status === 200) {
        setShowSentModal(true);
      }
    } catch (error) {
      Logger.error('Error updating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error updating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
    }
  };

  const waitForVIdeos = () => {
    toast({
      title: 'Please wait',
      description: `Media files are not finished processing`,
      duration: 5000,
      isClosable: true,
    });
  };

  const validateRequest2 = (
    approvalTitle,
    dueDate,
    description,
    selectedApprovals,
    imageData,
    members
  ) => {
    const missingFields = [];

    if (!approvalTitle) missingFields.push('Approval Title');
    if (!dueDate) missingFields.push('Due Date');
    if (!description) missingFields.push('Description');
    if (!imageData[0]) missingFields.push('Content');

    return missingFields;
  };

  const handleNextClick = () => {
    const missingFields = validateRequest2(
      titleRef.current.value,
      dueDate,
      descriptionRef.current.value,
      selectedApprovals,
      imageData,
      members
    );
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields before continuing: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading2(false);
      return;
    }
    setIsNextSlide(true);
  };

  const handleBackButtonClick = () => {
    if (isNextSlide) {
      setIsNextSlide(false);
    } else {
      history(-1);
    }
  };

  const handleFullScreen = () => {
    const element = playerWrapperRef.current;
    if (!isFullScreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        /* Firefox */
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        /* IE/Edge */
        element.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className="flex flex-1 h-screen">
      <div className="bg-dashGray w-1/3 px-6 py-8 overflow-y-auto h-full">
        {isLoading ? (
          <div className="h-3/5 flex-1 flex">
            <div className="w-full text-xs px-4 py-2 font-bold text-black flex justify-center items-center">
              <div className="blackSpinner"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Top Bar */}
            <div className="flex justify-between mb-8">
              <p
                className="text-secondary cursor-pointer hover-bg hover-shadow"
                onClick={() => handleBackButtonClick()}
              >
                {'< Back'}
              </p>
              <p className="text-lg font-bold">Edit Approval Request</p>

              <div className="text-dashGray">.</div>
            </div>

            {/* Form */}
            <div style={{ display: isNextSlide ? 'none' : '' }}>
              {/* Approval Title and Due Date */}
              <div className="flex mb-6 justify-between">
                <div className="mb-3 w-1/2 pr-2">
                  <label className="block text-black font-bold">
                    Approval Title
                  </label>
                  <input
                    className="w-full rounded-lg p-2"
                    type="text"
                    placeholder="Approval Title"
                    defaultValue={approvalTitle}
                    ref={titleRef}
                  />
                </div>

                <div className="w-1/2 mb-3 pl-2">
                  <label className="block text-black font-bold">Due Date</label>
                  <>
                    <style>{customStyle}</style>
                    <DatePicker
                      className="w-full rounded-lg p-2 text-black"
                      selected={dueDate}
                      onChange={(date) => handleDateChange(date)}
                    />
                  </>
                </div>
              </div>

              {/* Description */}
              <div className="mb-6">
                <label className="block text-black font-bold">
                  Description
                </label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter description"
                  defaultValue={description}
                  ref={descriptionRef}
                ></textarea>
              </div>

              {['other', 'document'].includes(contentTypeCategory.category) &&
              ['other', 'document'].includes(
                contentTypeCategory.subCategory
              ) ? (
                <div className="my-6">
                  <button
                    disabled={isLoading2}
                    className={`${
                      Object.keys(fileProgress).length > 0 &&
                      imageData?.length != numVideosUploaded + numImagesUploaded
                        ? 'bg-secondary cursor-not-allowed'
                        : 'bg-primary hover:bg-blue-700 '
                    } text-white w-full py-3 font-bold text-xl hover:wiggle-light rounded-lg`}
                    onClick={() => {
                      !(
                        Object.keys(fileProgress).length > 0 &&
                        imageData?.length !=
                          numVideosUploaded + numImagesUploaded
                      )
                        ? handleUpdateApprovalRequest()
                        : waitForVIdeos();
                    }}
                  >
                    {isLoading2 ? (
                      <div className="spinner">
                        <Spinner />
                      </div>
                    ) : (
                      'Send Update Request'
                    )}
                  </button>
                </div>
              ) : (
                <button
                  className="bg-primary my-6 text-white w-full py-3 font-bold rounded-lg hover:bg-blue-700"
                  onClick={() => handleNextClick()}
                >
                  Next
                </button>
              )}
            </div>
            <div style={{ display: !isNextSlide ? 'none' : '' }}>
              {/* Caption */}
              <div className="mb-6">
                <label className="block text-black font-bold">Caption</label>
                <textarea
                  className="w-full rounded-lg p-2"
                  rows="3"
                  placeholder="Enter a caption for the media if it has one"
                  defaultValue={caption}
                  ref={caprionRef}
                ></textarea>
              </div>

              <div>
                {/* Link */}
                <div className="mb-3 pr-2">
                  <label className="block text-black font-bold">
                    Add a link to the post
                  </label>
                  <input
                    className={`w-full rounded-lg p-2 ${
                      !isValidLink && 'border-red-500'
                    }`}
                    type="text"
                    placeholder="https://example.com"
                    defaultValue={link}
                    ref={linkRef}
                    style={{ borderColor: !isValidLink ? 'red' : '' }} // Alternative way to set border color
                  />
                  {!isValidLink && (
                    <div className="text-red-500 text-sm mt-1">
                      Please enter a valid HTTPS link.
                    </div>
                  )}
                </div>
              </div>

              <div className="my-6">
                <button
                  disabled={isLoading2}
                  className={`${
                    Object.keys(fileProgress).length > 0 &&
                    imageData?.length != numVideosUploaded + numImagesUploaded
                      ? 'bg-secondary cursor-not-allowed'
                      : 'bg-primary hover:bg-blue-700 '
                  } text-white w-full py-3 font-bold text-xl hover:wiggle-light rounded-lg`}
                  onClick={() => {
                    !(
                      Object.keys(fileProgress).length > 0 &&
                      imageData?.length != numVideosUploaded + numImagesUploaded
                    )
                      ? handleUpdateApprovalRequest()
                      : waitForVIdeos();
                  }}
                >
                  {isLoading2 ? (
                    <div className="spinner">
                      <Spinner />
                    </div>
                  ) : (
                    'Send Update Request'
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Right Side */}
      <div className="w-3/5 bg-white mx-auto justify-center align-middle self-center">
        <div
          className="mx-auto justify-center align-middle self-center"
          style={{ margin: '0 auto', width: '100%' }}
        >
          {/* <InstagramPost /> */}
          <ContentViewer
            caption={caption}
            removeItemAtIndex={removeItemAtIndex}
            playerWrapperRef={playerWrapperRef}
            isPlaying={isPlaying}
            handleDotClick={handleDotClick}
            setCurrentImage={setCurrentImage}
            setIsPolling={setIsPolling}
            currentImage={currentImage}
            imageDeleted={imageDeleted}
            fileProgress={fileProgress}
            isPolling={isPolling}
            numVideosUploaded={numVideosUploaded}
            setNumImagesUploaded={setNumImagesUploaded}
            setNumDocsUploaded={setNumDocsUploaded}
            numDocsUploaded={numDocsUploaded}
            numImagesUploaded={numImagesUploaded}
            addImageData={addImageData}
            setFileProgress={setFileProgress}
            setImageDeleted={setImageDeleted}
            user={user}
            setNumVideosUploaded={setNumVideosUploaded}
            pollForVideoUrl={pollForVideoUrl}
            handleOpenPicker={handleOpenPicker}
            imageData={imageData}
            platform={contentTypeCategory.subCategory}
            isLoading3={isLoading3}
            socialFormat={socialFormat}
            processFiles={processFiles}
            // pollForDocumentUrl={pollForDocumentUrl}
          />
        </div>
        {showSentModal && (
          <div className="modal-backdrop">
            {/* <ReactConfetti /> */}
            <div
              className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content"
              style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
            >
              <div className="flex flex-col flex-grow py-4">
                <div className="justify-center mb-4 text-center">
                  <h2 className="font-bold text-2xl">New approval sent!</h2>

                  <button
                    className="bg-primary text-white px-4 font-bold py-1 mt-4 rounded-lg hover:bg-blue-700 hover-shadow"
                    onClick={() => history(-1)}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditApprovalRequest;
