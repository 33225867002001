import { useEffect, useState } from 'react';
import axios from 'axios';
import ZapierIntegration from './integration-items/ZapierIntegration';
import $ from 'jquery';

const IntegrationList = ({ user }) => {
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    async function fetchIntegrations() {
      const response = await axios
        .get(
          `/api/users/integrations?firebaseAuthUUID=${user?.firebaseAuthUUID}`
        )
        .catch((e) => ({ e }));
      if (response.data) {
        setIntegrations(response.data.integrations || []);
      }
    }

    fetchIntegrations();
  }, [user]);

  const handleLookingForMoreClick = () => {
    $('#integrationsListCloseBtn').click();
    $('#contactUs').click();
  };

  return (
    <>
      <div className="justify-center mb-4 text-center">
        <h2 className="font-bold text-2xl">Integrations</h2>
        <p className="text-secondary leading-5">
          Manage your integrations here.
        </p>
      </div>

      <div
        className="overflow-y-scroll mb-4 no-scrollbar"
        style={{ maxHeight: '25vh', minHeight: '15vh' }}
      >
        <div className="flex flex-col h-full justify-center gap-5 items-center">
          <ZapierIntegration
            integration={integrations.find((integration) => integration.type === 'zapier')}
          />
          <button 
            className='bg-primary text-white hover-shadow cursor-pointer rounded-xl p-2 font-bold w-1/3'
            onClick={handleLookingForMoreClick}
          >
            Looking for more?
          </button>
        </div>
      </div>
    </>
  );
};

export default IntegrationList;
