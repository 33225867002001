const UserProfileModal = ({
  handleFirstNameChange,
  handleLastNameChange,
  selectedOrgMember,
  handleRoleChange,
  handleRemoveMember,
  setShowUserManagement,
  setShowUserProf,
  handleSaveMember,
  addMemberIsLoading,
}) => {
  return (
    <div className="modal-backdrop">
      <div className="relative flex flex-col w-full max-w-md p-6 m-4 bg-white shadow-xl rounded-2xl modal-content">
        <button
          onClick={() => setShowUserProf(false)}
          className="absolute top-0 right-0 mt-4 mr-4"
        >
          <svg
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="mb-4 text-center">
          <h2 className="text-2xl font-bold">Manage User</h2>
          <p className="text-sm text-gray-500">Update user information.</p>
        </div>

        {/* User input form */}
        <div className="mb-4 w-3/5 self-center">
          <input
            type="text"
            placeholder="First Name"
            value={selectedOrgMember?.firstName || ''}
            onChange={handleFirstNameChange}
            className="mb-3 shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <input
            type="text"
            placeholder="Last Name"
            value={selectedOrgMember?.lastName || ''}
            onChange={handleLastNameChange}
            className="mb-3 shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <input
            type="email"
            placeholder="Email"
            value={selectedOrgMember?.email || ''}
            // onChange={handleEmailChange}
            className="mb-3 shadow border bg-dashGray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
          <select
            value={selectedOrgMember?.role || 'team'}
            onChange={handleRoleChange}
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="team">Team</option>
            <option value="admin">Admin</option>
          </select>
        </div>

        <button
          style={{ backgroundColor: '#be1c1c' }}
          className="w-1/2 text-white self-center font-semibold py-2 px-4 mx-2 rounded-xl"
          onClick={() => {
            handleRemoveMember();
          }}
        >
          Delete User
        </button>

        {/* Action Buttons */}
        <div className="flex items-center mt-4 justify-center">
          <button
            className="w-1/2 bg-dashGray hover:bg-gray-200 text-primary font-semibold py-2 px-4 mx-2 rounded-xl"
            onClick={() => {
              setShowUserManagement(true);
              setShowUserProf(false);
            }}
          >
            Back
          </button>
          <button
            disabled={addMemberIsLoading}
            className="w-1/2 bg-primary text-white font-bold py-2 px-4 mx-2 rounded-xl hover:bg-blue-700"
            onClick={() => handleSaveMember()}
          >
            {addMemberIsLoading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfileModal;
