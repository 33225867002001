import ModalContent from './ModalContent';

const DeleteModal = ({
  openModal,
  handleClose,
  handleDelete,
  message,
  loading,
}) => {
  return (
    <ModalContent
      title={'Delete Workspace(s)'}
      className={'max-w-[480px]'}
      closeModal={handleClose}
      openModal={openModal}
    >
      <div className="flex flex-col items-center gap-4 text-sm">
        <p>{message}</p>
        {loading ? <p>Deleting...</p> : null}
        <div className="flex justify-between w-full gap-4 mt-8 mb-4">
          <button
            className="border rounded-lg py-2.5 px-5 w-full"
            onClick={handleClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="bg-rose-700 text-white rounded-lg py-2.5 px-5 w-full"
            onClick={handleDelete}
            disabled={loading}
          >
            Delete
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default DeleteModal;
