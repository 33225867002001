import { useState } from 'react';
import Icon from '../Icon';
import DotIcon from '../SVGs/Dot';
import arrowLeftIcon from '../../icons/arrow-left.png';
import mapStatus from '../../utils/formatApprovalRequestStatus';
import { Link } from 'react-router-dom';

const ApprovalRequestCard = ({ approvalRequest, handleDelete }) => {
  const [showMenu, setShowMenu] = useState(false);

  const status = mapStatus(approvalRequest?.status);

  const statusClassNames =
    status === 'Feedback Received'
      ? 'bg-processing-50 text-processing-500' // Light red for Feedback Received
      : status === 'Approved'
      ? 'bg-success-50 text-success-500'
      : status === 'Rejected'
      ? 'bg-error-50 text-error-500' // Light green for Approved
      : 'bg-warning-50 text-warning-500'; // Default color

  const fillStatusClassName =
    status === 'Feedback Received'
      ? 'fill-processing-500' // Light red for Feedback Received
      : status === 'Approved'
      ? 'fill-success-500'
      : status === 'Rejected'
      ? 'fill-error-500' // Light green for Approved
      : 'fill-warning-500';

  return (
    <div className="border rounded-lg min pt-6 relative group max-h-min">
      <div
        className="absolute origin-center rotate-90 text-xl right-3 top-3 group-hover:block hidden cursor-pointer z-10"
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        ...
      </div>
      {showMenu ? (
        <div
          className="absolute right-0 px-3 py-2 border-gray-700 rounded-md flex flex-col gap-1.5 text-sm text-center bg-gray-100 cursor-pointer"
          onMouseLeave={() => {
            setShowMenu(false);
          }}
        >
          <div
            className="text-rose-700"
            onClick={() => {
              setShowMenu(false);
              handleDelete([approvalRequest?.timelineId]);
            }}
          >
            Delete
          </div>
          {/* <div className="" onClick={() => { setShowMenu(false); handleEdit()} }>Edit</div> */}
        </div>
      ) : null}

      <div className="px-5 border-b min-h-[76px] gap-3.5 flex flex-col">
        <p>
          Due date: {new Date(approvalRequest?.dueDate).toLocaleDateString()}
        </p>
        <h3 className="text-sm font-semibold ">
          {approvalRequest?.approvalTitle}
        </h3>
      </div>
      <div className="px-5 py-2.5 flex justify-between items-baseline">
        <div
          className={`flex gap-1.5 py-0.5 px-2 rounded-full items-baseline ${statusClassNames}`}
        >
          <DotIcon className={fillStatusClassName} /> {status}
        </div>
        <Link
          to={`/approvalRequest/${approvalRequest?.timelineId}`}
          className="text-sm flex gap-2 items-center font-semibold text-gray-500 cursor-pointer"
        >
          View{' '}
          <Icon
            alt="view"
            src={arrowLeftIcon}
            className="p-1.5 border rounded-lg"
            size={6}
          />
        </Link>
      </div>
    </div>
  );
};

export default ApprovalRequestCard;
