const CancelIcon = ({ size = "10" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 10"
      fill="none"
    >
      <path
        d="M7.5 2.5L2.5 7.5M2.5 2.5L7.5 7.5"
        stroke="#EA4335"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelIcon;