import CheckboxWithTick from '../CheckBox';
import Icon from '../Icon';
import CompletedStep from '../SVGs/CompletedStep';
import plusIcon from '../../icons/plus-icon.png';
import plusIconWhite from '../../icons/plus-white-icon.png';
import ApprovalLevel from './ApprovalLevel';
import { useState } from 'react';

const AddApprovalRequestApprovers = ({
  approvers = [],
  setApprovers,
  isSubmitting,
}) => {
  const MAX_APPROVAL_LEVEL = 2;
  const [approverLevel, setApproverLevel] = useState(1);

  const handleAddApproverLevel = () => {
    const levelApprover = approvers.find(
      (approver) => approver.level === approverLevel
    );

    if (!levelApprover) {
      return;
    }

    setApproverLevel(approverLevel + 1);
  };

  const handleAddApprover = (approver) => {
    const existingApprover = approvers.find(
      (existingApprover) => existingApprover.email === approver.email
    );

    if (existingApprover) {
      setApprovers(
        approvers.map((existingApprover) =>
          existingApprover.email === approver.email
            ? approver
            : existingApprover
        )
      );
      return;
    }

    setApprovers([...approvers, approver]);
  };

  const handleRemoveApprover = (email) => {
    const currentApprovers = approvers.filter(
      (approver) => approver.email !== email
    );

    const countLevelApprovers = currentApprovers.reduce((acc, approver) => {
      acc[approver.level] = acc[approver.level] ? acc[approver.level] + 1 : 1;
      return acc;
    }, {});

    let maxLevel = 1;

    for (let i = 1; i <= approverLevel; i++) {
      if (!countLevelApprovers[i]) {
        maxLevel = i;
        break;
      }
      maxLevel = i;
    }

    setApprovers(
      currentApprovers.filter((approver) => approver.level <= maxLevel)
    );
    setApproverLevel(maxLevel);
  };

  return (
    <div className="flex flex-col gap-5">
      {Array.from({ length: approverLevel }, (_, index) => {
        return (
          <ApprovalLevel
            level={index + 1}
            setLevel={setApproverLevel}
            setLevelName={setApproverLevel}
            approvers={approvers}
            addApprover={handleAddApprover}
            removeApprover={handleRemoveApprover}
            isSubmitting={isSubmitting}
          />
        );
      })}

      <div className="flex justify-center">
        <button
          className={`bg-primary text-white rounded-lg py-2.5 px-5 flex text-base font-semibold`}
          onClick={handleAddApproverLevel}
          disabled={isSubmitting}
        >
          <Icon src={plusIconWhite} /> Add approver level
        </button>
      </div>
    </div>
  );
};

export default AddApprovalRequestApprovers;
