import ApprovalRequestCard from '../Cards/ApprovalRequestCard';

const ApprovalRequestCardView = ({ approvalRequests, handleDelete }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-7 gap-y-3 text-xs h-full overflow-y-scroll no-scrollbar">
      {approvalRequests.map((approvalRequest) => (
        <ApprovalRequestCard
          approvalRequest={approvalRequest}
          key={approvalRequest.timelineId}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

export default ApprovalRequestCardView;
