import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import axios from 'axios';
import fullLogo from '../icons/Full.png';
import { useToast } from '@chakra-ui/react';
import AuthScreen from '../components/AuthScreens';
import FormInput from '../components/Form/FormInput';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';
import { isEmptyObject } from '../utils/validateUtils';

const Login = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const redirectLink = urlParams.get('login_to');
  const history = useNavigate();
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  if (!isEmptyObject(user)) {
    if (user.plan === 'freeTrial') {
      history('/select-plan');
    } else {
      if (redirectLink) {
        window.location.href = redirectLink;
      } else {
        history('/workspaces');
      }
      // use replace to prevent user from going back to login page// Redirect to /signup

    }
  }

  const validateForm = () => {
    const email = loginData.email;
    const password = loginData.password;

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      return false;
    }
    if (password.length == 0) {
      return false;
    }
    return true;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const email = loginData.email;
      const password = loginData.password;

      try {
        const auth = getAuth(firebaseApp);
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            var user = userCredential.user;
            // Logger.log(user);
            // User is signed in, now make an Axios POST request to your server
            return axios
              .post('/api/auth/login', {
                firebaseUUID: user.uid,
              })
              .then((response) => {
                // Handle the response as needed, for example:
                setUser(response.data.user);
                // This function creates visitors and accounts in Pendo
                // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
                // Please use Strings, Numbers, or Bools for value types.
                window.pendo.initialize({
                  visitor: {
                    id: response.data.user._id,
                    email: response.data.user.email, // Recommended if using Pendo Feedback, or NPS Email
                    full_name: response.data.user.fullname, // Recommended if using Pendo Feedback
                  },
                  account: {
                    id: response.data.user._id,
                  },
                });

                if (response.data.user.plan === 'freeTrial') {
                  history('/select-plan');
                } else {
                  if (redirectLink) {
                    window.location.href = redirectLink;
                  } else {
                    history('/workspaces');
                  }
                }

                setIsLoading(false);
              });
          })
          .catch((error) => {
            toast({
              title: 'Login error.',
              description:
                error.response?.data?.message ?? 'Invalid login credentials',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setIsLoading(false);
          });
      } catch (error) {
        Logger.error('Firebase sign-in error:', error);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    setLoginData({
      ...loginData,
      [id]: value,
    });
  }

  return (
    <AuthScreen>
      <div className="flex flex-col gap-8 sm:mx-28 sm:w-[360px]">
        <div className="flex flex-col gap-3">
          <h1 className="text-4xl font-normal">Login</h1>
          <p className="text-sm">Welcome back, log in to continue</p>
        </div>
        <form className="flex flex-col gap-2 sm:gap-2" onSubmit={(e) => handleLogin(e)}>
          <FormInput
            label="Email*"
            id="email"
            type="email"
            placeholder="Enter your email"
            autoComplete="email"
            onChange={handleChange}
            // forwardedRef={emailRef}
          />
          <FormInput
            label="Password*"
            id="password"
            type="password"
            placeholder="Create a password"
            autoComplete="current-password"
            onChange={handleChange}
            hint={loginData.password && loginData.password.length < 8 ? 'Must be at least 8 characters' : ''}
            // forwardedRef={passwordRef}
          />
          <div className="items-center text-center text-sm text-gray-500">
            <p className="cursor-pointer" onClick={() => history('/forgot-password')}>Forgot Password</p>
          </div>
          <div className="flex items-center justify-between">
            <button
              className="w-full bg-primary-600 text-white py-2 rounded-md sm:h-11 text-sm"
              type="submit"
            >
              {isLoading ? <div className="spinner"><Spinner /></div> : 'Login'}
            </button>
          </div>

          <div className="items-center text-center text-gray-400 mt-8 text-sm">
            <p className="text-md">
              Don't have an account?{' '}
              <span className="text-primary cursor-pointer" onClick={() => history('/signup')}>Sign Up</span>
            </p>
          </div>
        </form>
      </div>
    </AuthScreen>
  );
};

export default Login;
