import { useToast } from '@chakra-ui/react';
import axios from 'axios';

const ContactUsModal = ({
  setFirstName,
  setLastName,
  setSubject,
  setMessage,
  setShowModal,
  user,
  subject,
  message,
  firstName,
  lastName,
}) => {
  const toast = useToast();

  const handleContactSubmit = async () => {
    // Logic to send an email with the form details
    // You might want to use a backend service or a third-party service to send emails
    try {
      const res = axios.post('/api/auth/user/contact-us', {
        user,
        subject,
        message,
      });

      // Close the contact modal
      setShowModal(false);
      toast({
        title: 'Your request was sent.',
        description: "You'll here from us shortly.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setMessage('');
      setSubject('');
    } catch (error) {
      setShowModal(false);
      toast({
        title: 'Error',
        description: 'There was an error submitting your request',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content">
        <div className="justify-center mb-4 text-center">
          <h2 className="font-bold text-xl">Contact Us</h2>
          <p className="text-secondary leading-5">
            Let us know how we can help
          </p>
        </div>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
          id="contactFirstName"
          type="text"
          placeholder="First Name"
          readOnly // Make this field read-only if it's prepopulated
        />
        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
          id="contactLastName"
          type="text"
          placeholder="Last Name"
          readOnly // Make this field read-only if it's prepopulated
        />
        <input
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
          id="subject"
          type="text"
          placeholder="Subject"
        />
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="w-full bg-gray-50 px-3 py-2 mb-3 text-lg outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
          id="message"
          placeholder="Let us know how we can help"
          rows="4"
        ></textarea>
        <button
          className="w-full mt-4 text-lg px-4 py-2 font-bold text-white hover-shadow bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          type="button"
          onClick={() => handleContactSubmit()}
        >
          Submit
        </button>
        <button
          onClick={() => setShowModal(false)}
          className="text-sm hover-shadow hover-bg text-secondary underline mt-4"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ContactUsModal;
