import { useState } from 'react';
import ModalContent from './ModalContent';
import FormInput from '../Form/FormInput';
import useError from '../../hooks/useError';
import uploadIcon from '../../icons/upload-icon.png';
import FormRadioButton from '../Form/FormRadioButton';
import WebUploadSelector from '../UploadSelectors/WebUploadSelector';
import DocumentUploadSelector from '../UploadSelectors/DocumentUploadSelector';
import SocialUploadSelector from '../UploadSelectors/SocialUploadSelector';

const UploadContentModal = ({
  closeModal,
  isOpen = true,
  category,
  setHtmlContent,
  setURL,
  setPlatform,
  addImageData,
  platform,
  socialFormat,
}) => {
  const [modalTitle, setModalTitle] = useState('Select the type of content');

  const [loading, setLoading] = useState(false);

  const handleUploader = () => {
    closeModal();
  };

  const closeUploadModal = () => {
    !loading && closeModal();
  };

  const handleModalChange = (value) => {
    setModalTitle(value);
  };

  return (
    <ModalContent
      title={modalTitle}
      className={'max-w-[480px] w-full'}
      src={uploadIcon}
      closeModal={closeUploadModal}
      openModal={isOpen}
    >
      {category === 'web' && (
        <WebUploadSelector
          closeUploadModal={closeUploadModal}
          setTitle={handleModalChange}
          setHtmlContent={setHtmlContent}
          setURL={setURL}
          setPlatform={setPlatform}
          setIsLoading={setLoading}
          loading={loading}
          platform={platform}
        />
      )}
      {category === 'document' && (
        <DocumentUploadSelector
          closeUploadModal={closeUploadModal}
          setTitle={handleModalChange}
          setHtmlContent={setHtmlContent}
          setURL={setURL}
          setPlatform={setPlatform}
          addImageData={addImageData}
          setLoading={setLoading}
          loading={loading}
          platform={platform}
          socialFormat={socialFormat}
        />
      )}

      {['social', 'other'].includes(category) && (
        <SocialUploadSelector
          closeUploadModal={closeUploadModal}
          setTitle={handleModalChange}
          setHtmlContent={setHtmlContent}
          setURL={setURL}
          setPlatform={setPlatform}
          addImageData={addImageData}
          setLoading={setLoading}
          loading={loading}
          category={category}
          platform={platform}
        />
      )}
    </ModalContent>
  );
};

export default UploadContentModal;
