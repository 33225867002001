// test prices
  // const priceToPlan = {
  //   'price_1OpYhOEbkYJOo57VoTsIVXMG': { title: "STARTER", billingSchedule: 'monthly'},
  //   'price_1OpYhOEbkYJOo57VjaGCdwmN': { title: "STARTER", billingSchedule: 'yearly'},
  //   'price_1OpYjTEbkYJOo57Vr3WkUOix': { title: "PREMIUM", billingSchedule: 'monthly'},
  //   'price_1OpYjUEbkYJOo57VAHljq9fB': { title: "PREMIUM", billingSchedule: 'yearly'},
  //   'price_1OpYttEbkYJOo57V3BPE23CA': { title: "ULTIMATE", billingSchedule: 'monthly'},
  //   'price_1OpYttEbkYJOo57VDVn6OUWF': { title: "ULTIMATE", billingSchedule: 'yearly'}
  // };

  const priceToPlan = {
    'price_1PSKm7EbkYJOo57VJmvZLXK2': { title: "STARTER", billingSchedule: 'monthly'},
    'price_1PSKm7EbkYJOo57VJmvZLXK2': { title: "STARTER", billingSchedule: 'yearly'},
    'price_1PSKtuEbkYJOo57Vi4iJTke1': { title: "PREMIUM", billingSchedule: 'monthly'},
    'price_1PSKuaEbkYJOo57Vt1MGLy7g': { title: "PREMIUM", billingSchedule: 'yearly'},
    'price_1PSKyQEbkYJOo57VizQdqA2H': { title: "ULTIMATE", billingSchedule: 'monthly'},
    'price_1PSKz4EbkYJOo57VKMTyM7rB': { title: "ULTIMATE", billingSchedule: 'yearly'}
  };


  
  export default priceToPlan;
  