import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { userAtom } from '../recoil/userAtoms';
import WorkspaceCard from '../components/WorkspaceCard';
import WorkspaceListCard from '../components/WorkspaceListItem';
import { useRecoilState } from 'recoil';
import Sidebar from '../components/Sidebar'; // Make sure to import the Sidebar component from the correct path
import { Link } from 'react-router-dom';
import imageIcon from '../icons/imageIcon.png';
import cardViewIcon from '../icons/cardViewIcon.png';
import listViewIcon from '../icons/listViewIcon.png';
import { ImFolderUpload } from 'react-icons/im';
import { FaCheck } from 'react-icons/fa';
import uploadToS3 from '../utils/uploadToS3';
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';

const Onboard = () => {
  const history = useNavigate();
  const [modalIndex, setModalIndex] = useState(0);
  const [emailInputs, setEmailInputs] = useState([
    { email: '', firstName: '' },
  ]);
  const [user] = useRecoilState(userAtom);
  const [workspaces, setWorkspaces] = useState([]);
  const [cardView, setCardView] = useState('card');
  const [isLoading, setIsLoading] = useState(false);
  const [workspaceName, setWorkspaceName] = useState('');
  const [validationError, setValidationError] = useState('');
  const [selectedWorkspaceColor, setSelectedWorkspaceColor] =
    useState('#0D76DB');
  const [previewURL, setPreviewURL] = useState('');
  const [imageData, setImageData] = useState([]);

  const availableColors = [
    { label: 'primary', code: '#0D76DB' },
    { label: 'red', code: '#FF4F00' },
    { label: 'magenta', code: '#E55DFF' },
    { label: 'violet', code: '#8247F5' },
    { label: 'cyan', code: '#09E8F0' },
    { label: 'electric lime', code: '#CCF000' },
    { label: 'bright yellow', code: '#F8E436' },
    { label: 'orange', code: '#FFA600' },
  ];

  const addImageData = (image) => {
    setImageData((prevImages) => [image]);
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files); // Convert files to an array

    // Process each file in the files array
    files.forEach((file) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Use a function that sets an array of image data, instead of a single image data state
        addImageData({ name: file.name, data: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    });
  };

  const uploadImageToS3 = async () => {
    Logger.log(imageData);
    Logger.log(imageData.length);
    if (imageData.length == 0) {
      return [{ url: '' }];
    }
    // Assuming uploadToS3 is a function that uploads a file to S3 and returns the URL
    const url = await uploadToS3(imageData, user);
    return url;
  };

  const selectColor = (color) => {
    setSelectedWorkspaceColor(color);
  };

  const validateEmails = () => {
    Logger.log(emailInputs);
    if (
      emailInputs.length == 1 &&
      emailInputs[0].firstName == '' &&
      emailInputs[0].email == ''
    ) {
      return true;
    }
    for (let i = 0; i < emailInputs.length; i++) {
      const email = emailInputs[i].email;
      // Using a simple regex pattern for email validation
      const emailValid = /\S+@\S+\.\S+/.test(email);
      if (!emailValid) {
        return false;
      }
    }
    for (let i = 0; i < emailInputs.length; i++) {
      const firstName = emailInputs[i].firstName;
      // Using a simple regex pattern for email validation
      if (firstName.length < 1) {
        return false;
      }
    }
    return true;
  };

  const handleAddWorkspace = async () => {
    setIsLoading(true);
    if (!validateEmails()) {
      setValidationError('Please enter valid email addresses and name.');
      setIsLoading(false);
      return;
    }

    setValidationError(''); // Reset validation error if emails are valid
    try {
      const workspaceIconURL = await uploadImageToS3();
      // Perform the Axios POST request to the backend to create a new workspace
      const response = await axios.post('/api/workspace/createWorkspace', {
        firebaseAuthUUID: user?.firebaseAuthUUID,
        workspaceName,
        color: selectedWorkspaceColor,
        workspaceIcon: workspaceIconURL[0].url, // This is optional based on your setup
        workspaceMemberEmails: emailInputs.map((input) => input.email),
        workspaceMemberFirstNames: emailInputs.map((input) => input.firstName),
      });

      if (response.status === 201) {
        Logger.log('Successfully created new workspace', response.data);
        // Refetch workspaces to update the UI
        fetchWorkspaces();
        history('/workspaces');
        // Navigate or close the modal here if needed
      } else {
        Logger.error('Failed to create workspace: ', response.data);
      }
    } catch (error) {
      Logger.error('An error occurred while creating the workspace:', error);
      setValidationError('Internal Server Error');
    }
    setIsLoading(false);

    // Perform the rest of your logic, such as sending this information to the backend
  };

  const handleWorkspaceNameChange = (e) => {
    setWorkspaceName(e.target.value);
  };

  const handleEmailChange = (e, index, field) => {
    const newEmails = [...emailInputs];
    newEmails[index][field] = e.target.value;
    setEmailInputs(newEmails);
  };

  const fetchWorkspaces = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/api/workspace/getWorkspaces?firebaseAuthUUID=${user?.firebaseAuthUUID}`
      ); // Replace with your actual API endpoint
      setWorkspaces(res.data);
    } catch (error) {
      Logger.error('Failed to fetch workspaces:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchWorkspaces();
  }, []);

  const handleViewChange = () => {
    setCardView(cardView === 'card' ? 'list' : 'card');
  };

  const addNewEmailInput = () => {
    setEmailInputs([...emailInputs, { email: '' }]);
  };

  return (
    <div className="flex bg-white">
      <Sidebar />
      <div className="flex-1">
        <div className="flex flex-col">
          <div className="p-12 mt-5 flex flex-row justify-between">
            <p className="font-bold">Workspaces</p>
            <div className="flex">
              <img
                className="w-5 h-5 mr-3 my-auto cursor-pointer"
                src={cardView === 'card' ? cardViewIcon : listViewIcon}
                alt="View Selector"
                onClick={handleViewChange} // New click handler for changing view
              />
              <button
                className="text-xs px-4 py-0 font-bold text-white bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                type="button"
              >
                + Create Workspace
              </button>
            </div>
          </div>

          {/* New code to display workspaces */}
          <div className={`px-12 ${cardView === 'card' ? 'flex' : ''}`}>
            {workspaces?.map((workspace, index) => (
              <>
                {cardView === 'card' && (
                  <WorkspaceCard workspace={workspace} key={index} />
                )}
                {cardView !== 'card' && (
                  <WorkspaceListCard workspace={workspace} key={index} />
                )}
              </>
            ))}
          </div>

          <>
            {modalIndex == 0 ? (
              <div className="modal-backdrop">
                <div className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                  <div className="flex flex-col flex-grow">
                    <div className="justify-center mb-4 text-center">
                      <h2 className="font-bold">Add Workspace</h2>
                      <p className="text-secondary leading-5">
                        A workspace is a dedicated folder of approvals for each
                        client, department, or project
                      </p>
                      <div className="flex justify-center mt-6">
                        <div className="bg-dashGray rounded-xl cursor-pointer p-4">
                          {imageData[0] ? (
                            <label
                              htmlFor="fileInput"
                              className="cursor-pointer"
                            >
                              <img
                                src={imageData[0].data}
                                alt="Workspace Preview"
                                className="rounded-xl w-20 h-20"
                              />
                              <input
                                type="file"
                                id="fileInput"
                                className="hidden"
                                onChange={handleImageChange}
                              />
                            </label>
                          ) : (
                            <div className="bg-dashGray rounded-xl hover-shadow cursor-pointer p-4">
                              <label htmlFor="fileInput">
                                <ImFolderUpload
                                  className="text-primary cursor-pointer"
                                  size={36}
                                />
                                <input
                                  type="file"
                                  id="fileInput"
                                  className="hidden"
                                  onChange={handleImageChange}
                                />
                              </label>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className="text-center text-sm text-gray-400">
                      Add an image to this Workspace
                    </p>
                    <div className="mt-4">
                      <input
                        value={workspaceName}
                        onChange={handleWorkspaceNameChange}
                        className="w-full bg-gray-50  px-3 py-2 mb-0 text-xs outline-none text-gray-700  rounded appearance-none focus:outline-none focus:shadow-outline"
                        id="firstName"
                        type="text"
                        placeholder="Enter Workspace Name"
                      />
                    </div>

                    <h2 className="font-bold text-center mt-2 text-sec">
                      Choose Workspace Color
                    </h2>
                    <div className="flex flex-wrap justify-center mt-4">
                      {availableColors.map((colorObj, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-center w-6 h-6 rounded-full m-1 cursor-pointer"
                          style={{ backgroundColor: colorObj.code }}
                          onClick={() => selectColor(colorObj.code)}
                        >
                          {selectedWorkspaceColor === colorObj.code && (
                            <FaCheck className="text-white" />
                          )}
                        </div>
                      ))}
                    </div>

                    <button
                      className="w-full mt-4 text-xs px-4 py-2 font-bold text-white bg-primary rounded-lg mt- focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                      type="button"
                      onClick={() => setModalIndex(1)}
                    >
                      Next
                    </button>
                  </div>

                  <div className="items-center text-center text-gray-400 mt-8">
                    <Link to="/workspaces">
                      <p className="text-xs underline">Set up later</p>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div className="modal-backdrop">
                <div className="flex flex-col w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
                  <div className="flex flex-col flex-grow">
                    <div className="justify-center mb-4 text-center">
                      <h2 className="font-bold">Invite Workspace Members</h2>
                      <p
                        className="text-center text-sm text-gray-400"
                        style={{ lineHeight: '110%' }}
                      >
                        Workspace members are your team members. They will get
                        access to the workspace, be able to view projects, and
                        can be assigned feedback from approvers.
                      </p>
                      {validationError != '' && (
                        <div className="justify-center mt-1 text-center">
                          <p className="text-xs text-red-500">
                            {validationError}
                          </p>
                        </div>
                      )}
                    </div>

                    {emailInputs.map((input, index) => (
                      <div key={index} className="mt-2 flex justify-around">
                        <input
                          value={input.firstName}
                          onChange={(e) =>
                            handleEmailChange(e, index, 'firstName')
                          }
                          className="w-2/5 bg-gray-50 px-3 py-2 mb-0 mx-1 text-xs outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                          placeholder={`Enter first name`}
                        />
                        <input
                          value={input.email}
                          onChange={(e) => handleEmailChange(e, index, 'email')}
                          className="w-3/5 bg-gray-50 px-3 py-2 mb-0 mx-1 text-xs outline-none text-gray-700 rounded appearance-none focus:outline-none focus:shadow-outline"
                          placeholder={`Enter email address`}
                        />
                      </div>
                    ))}

                    <button
                      className="mt-2 text-xs text-primary"
                      onClick={addNewEmailInput}
                    >
                      + Add another member
                    </button>

                    <div className="flex flex-row mt-2">
                      <button
                        className="w-full text-xs px-4 py-2 mx-2 font-bold text-primary bg-dashGray rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-gray-100"
                        type="button"
                        onClick={() => setModalIndex(0)}
                      >
                        Back
                      </button>

                      <button
                        className="w-full text-xs px-4 py-2 font-bold text-white bg-primary rounded-lg  focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
                        type="button"
                        onClick={() => handleAddWorkspace()}
                      >
                        {isLoading ? (
                          <div className="spinner"><Spinner /></div>
                        ) : (
                          'Complete'
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="items-center text-center text-gray-400 mt-8">
                    <Link to="/workspaces">
                      <p className="text-xs underline">Set up later</p>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Onboard;
