import { useState } from "react";
import Draggable from "react-draggable";

const DraggableAvatar = ({
  comment,
  absoluteX,
  absoluteY,
  children,
  loading,
  setSelectedCommentID,
  scrollToComment,
  selectedCommentID,
  imageDimensions,
  handleDragStop,
}) => {
  const [relativePosition, setRelativePosition] = useState({ x: 0, y: 0 });
  
  const handleNewPosition = (e, data) => {
    if (data.x === 0 && data.y === 0) {
      return;
    }

    let bounds = {
      left: imageDimensions.left,
      right: imageDimensions.left + imageDimensions.width,
      top: imageDimensions.top,
      bottom: imageDimensions.top + imageDimensions.height,
    };

    // check bound and do not update position if out of bound. this should return the avatar back to original position and will not update.
    if (
      e.clientX < bounds.left ||
      e.clientX > bounds.right ||
      e.clientY < bounds.top ||
      e.clientY > bounds.bottom
    ) {
      return;
    }

    let avatarPosition = {
      x: data.x,
      y: data.y,
    };

    setRelativePosition(avatarPosition);
    handleDragStop(e, data, comment, absoluteX, absoluteY).then(() => {
      setRelativePosition({ x: 0, y: 0 });
    });
  };

  return (
    <Draggable onStop={handleNewPosition} position={relativePosition}>
      {loading ? (
        <div
          className="blackSpinner"
          style={{
            position: 'absolute',
            left: `${absoluteX}px`,
            top: `${absoluteY}px`,
          }}
        ></div>
      ) : (
        <button
          style={{
            position: 'fixed',
            left: `${absoluteX}px`,
            top: `${absoluteY}px`,
            border: '0.5px solid #c9c9c9',
          }}
          className={`text-primary cursor-pointer p-1 rounded-r-full rounded-t-full hover:rounded-t-lg hover:rounded-r-lg hover:z-40 hover:bg-primary-default hover:text-white group/comment ${
            selectedCommentID == comment._id ? 'adding-comment bg-primary' : 'bg-gray-50'
          }`}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedCommentID(comment._id);
            scrollToComment(comment._id);
          }}
        >
          {children}
        </button>
      )}
    </Draggable>
  );
};

export default DraggableAvatar;