import { useEffect, useState } from 'react';

const Icon = ({ src, alt, onClick, className = '', size = 6 }) => {
  const [data, setData] = useState({ size, className });
  useEffect(() => {
    setData({ size, className });
  }, [className, size, src]);
  return (
    <div
      className={`h-${data.size} w-${data.size} ${data.className} cursor-pointer`}
      onClick={onClick}
    >
      <img src={src} alt={alt} className="w-full h-full object-fit" />
    </div>
  );
};

export default Icon;
