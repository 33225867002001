import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom';
import notifIcon2 from '../icons/notifIcon2.png';
import { useToast } from '@chakra-ui/react';
import formatDate from '../utils/foramtDateComments';
import { Logger } from '../utils/logger';
import PageWrapper from '../components/PageWrapper';


const Notifications = () => {
  const [user] = useRecoilState(userAtom);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/api/notifications/get/${user.firebaseAuthUUID}`
      );
      setNotifications(res.data);
      Logger.log(res.data);
      Logger.log(user.email);
    } catch (error) {
      Logger.error(error);
    }
    setIsLoading(false);
  };

  const setAllNotificationsToSeen = async () => {
    try {
      const notificationIds = notifications.map((n) => n._id);
      await axios.post('/api/notifications/markAsSeen', { notificationIds });
    } catch (error) {
      Logger.error(error);
    }
  };

  const clearNotification = async (notificationId) => {
    try {
      await axios.post('/api/notifications/clearNotification', {
        notificationId,
      });
      fetchNotifications();
    } catch (error) {
      Logger.error(error);
    }
  };

  const clearAllNotifications = async () => {
    Logger.log('clearing...');
    try {
      const notificationIds = notifications.map((n) => n._id); // Extract notification IDs
      await axios.post('/api/notifications/clearMultipleNotifications', {
        notificationIds,
      });
      fetchNotifications(); // Refresh the notifications list
      toast({
        title: 'All notifications cleared.',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'An error occurred.',
        description: 'Unable to clear notifications.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchNotifications();
    setAllNotificationsToSeen();
  }, []);

  const NotificationListItem = ({ notification }) => {
    return (
      <Link to={`/approvalRequest/${notification.approvalRequestTimelineID}`}>
        <div
          className="flex hover-bg hover-shadow justify-between p-4 rounded-xl m-2 w-full bg-dashGray relative"
          style={{ borderLeft: `6px solid ${notification.workspaceColor}` }}
        >
          <div className="text-left flex items-center">
            {/* <div className="relative h-4 w-4 mr-2">
            <img 
              src={notifIcon2} 
              alt="Notif Icon 2"
            />
          </div> */}

            <div className="flex flex-col">
              <p className="text-lg leading-5 mb-1">
                <span className="text-secondary">Workspace </span>
                <b>{notification.workspaceName}</b>
              </p>
              <p className="text-lg leading-5 mb-1">
                <span className="text-secondary">Approval Request </span>
                <b>{notification.approvalRequestTitle}</b>
              </p>
              <p className="text-lg leading-5">{notification.message}</p>
              <p className="text-lg leading-5 text-secondary">
                {formatDate(notification.dateCreated)}
              </p>
            </div>
          </div>
          <div className="text-right flex items-center">
            {/* Your right column content */}
            <p>{'>'}</p>
          </div>
        </div>
      </Link>
    );
  };

  return (
    <PageWrapper>
  <div className="flex bg-white h-full">
    <div className="flex-1 h-full overflow-hidden">
      <div className="flex flex-col h-full">
        <div className="flex flex-row justify-between">
          <p className="font-bold text-3xl">Notifications</p>
          <div className="flex">
            <button
              className="text-secondary text-xl cursor-pointer select-none hover-bg hover-shadow px-2 rounded"
              onClick={clearAllNotifications}
            >
              Clear All
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="blackSpinner"></div>
        ) : (
          <>
            {notifications.length === 0 && (
              <p className="">No Notifications.</p>
            )}
            {/* New scrollable wrapper */}
            <div className="mt-4 h-full overflow-y-scroll">
              {notifications.map((notification, index) =>
                notification.triggererEmail == user?.email ? (
                  <NotificationListItem
                    key={index}
                    notification={notification}
                  />
                ) : (
                  <></>
                )
              )}
            </div>
          </>
        )}
      </div>
    </div>
  </div>
</PageWrapper>

  );
};

export default Notifications;
