import React from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
// import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

const FormTextEditor = ({
  label,
  id,
  type,
  placeholder,
  error,
  hint,
  value = '',
  handleChange,
  name,
}) => {
  const { quill, quillRef, editorRef } = useQuill({
    placeholder,
    modules: { toolbar: true },
  });

  React.useEffect(() => {
    if (quill) {
      const text = quill.getText().trim();

      if (!text) {
        quill.clipboard.dangerouslyPasteHTML(value);
      }
    }
  }, [value]);

  React.useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        handleChange(quill.root.innerHTML); // Get innerHTML using quill
      });

      quill.clipboard.dangerouslyPasteHTML(value);
    }
  }, [quill]);

  return (
    <div className="flex flex-col gap-1.5 w-full">
      <label htmlFor={id} className="">
        {label}
      </label>
      <div className="rounded-lg w-full border shadow-sm h-32 flex flex-col editor bg-white">
        <div ref={quillRef} id={id} />
      </div>
      {hint && (
        <p className={`text-xs ${error ? 'text-red-600' : 'text-gray-600'}`}>
          {hint}
        </p>
      )}
    </div>
  );
};

export default FormTextEditor;
