import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { CloseButton, useToast } from '@chakra-ui/react';
import ApprovalStatus from '../components/ApprovalStatus';
import PageWrapper from '../components/PageWrapper';
import DotIcon from '../components/SVGs/Dot';
import { useNavigate, useParams } from 'react-router-dom';
import { userAtom } from '../recoil/userAtoms';
import { Logger } from '../utils/logger';
import { getRandomPastelColor } from '../utils/pastels';
import ReactPlayer from 'react-player';
import DocPreview from '../components/DocumentViewers/DocPreview';
import PdfPreview from '../components/DocumentViewers/PdfPreview';
import { scrubIframeDoc } from '../utils/remove-anchor-href';
import NextArrow from '../components/SVGs/NextArrow';
import SendReminderModal from '../components/Modal/SendReminderModal';
import ChevronDown from '../components/SVGs/ChevronDown';
import Tick from '../components/SVGs/Tick';
import TextAreaComponent from '../components/Reply/TextArea';
import CommentSection from '../components/Comment/CommentSection';
import formatDateAbrev from '../utils/formatDateAbrev';
import Plus from '../components/SVGs/Plus';
import useIframe from '../hooks/useIframe';
import VideoPlayer from '../components/ContentViewer/VideoPlayer';
import Iframe from '../components/IFrame';
import { currentTimeAtom } from '../recoil/currentTimeAtom';
import { AvatarComponent } from 'avatar-initials';
import { darkerShade } from '../utils/darkerShade';
import DraggableAvatar from '../components/DraggableAvatar';
import CompletedStep from '../components/SVGs/CompletedStep';
import PendingStep from '../components/SVGs/PendingStep';
import foramtDateComments from '../utils/foramtDateComments';
import { AiFillCheckCircle } from 'react-icons/ai';
import DefaultModal from '../components/Modal/DefaultCtaModal';
import { formatAgoDate } from '../utils/formatDate';
import fullLogo from '../icons/Full.png';
// import VideoPlayer from '../components/VideoPlayer';

const ReviewApprovalR = () => {
  const history = useNavigate();
  const [user] = useRecoilState(userAtom);
  const [currentTime] = useRecoilState(currentTimeAtom);

  const urlParams = new URLSearchParams(window.location.search);
  const reviewerEmail = urlParams.get('reviewer');
  const reviewerId = urlParams.get('reviewer');
  const approvalRequestId = urlParams.get('aprReqID');

  const [approvalRequestTimeline, setApprovalRequestTimeline] = useState([]);
  const [timelineName, setTimelineName] = useState('');
  const [latestRequest, setLatestRequest] = useState({});
  const [workspaceIcon, setWorkspaceIcon] = useState('');
  const [notifications, setNotifications] = useState([]);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [isSendingReminder, setIsSendingReminder] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [selectionIds, setSelectionIds] = useState([]);
  const [toggleShowMore, setToggleShowMore] = useState(true);
  const [isDocumentComment, setIsDocumentComment] = useState(false);
  const [commentSent, setCommentSent] = useState(false);
  const [showVersionHistory, setShowVersionHistory] = useState(false);

  // const [isLoading, setIsLoading] = useState(false);
  // const { timelineID } = useParams();
  // const [approvalRequestTimeline, setApprovalRequestTimeline] = useState([]);
  // const [latestRequest, setLatestRequest] = useState({});
  // const [timelineName, setTimelineName] = useState('');
  const scrollContainerRef = useRef(null);
  const iframeRef = useRef(null);
  const showInputRef = useRef(null);
  const newCommentRef = useRef(null);

  const [maxWidth, setMaxWidth] = useState('95%');
  // const [notifications, setNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const [workspaceIcon, setWorkspaceIcon] = useState(null);
  const toast = useToast();
  const [isDesktopView, setIsDesktopView] = useState(true);
  const playerWrapperRef = useRef(null);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const [currentImage, setCurrentImage] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const [timelineApprovalRequests, setTimelineApprovalRequests] = useState([]);
  const [timelineID, setTimelineID] = useState(null);
  const [timeLine, setTimeline] = useState([]);
  const [requestNewLink, setRequestNewLink] = useState(false);
  // const [latestRequest, setAppReq] = useState({});
  const [sender, setSender] = useState('');
  const [reviewer, setReviewer] = useState(null);
  const [versionSelect, setVersionSelect] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showApprovalModal, setShowApprovalModal] = useState(false);

  const [selectedVersion, setSelectedVersion] = useState(approvalRequestId);

  // COMMENTS
  const commentRefs = useRef({});
  const scrollableDivRef = useRef(null);
  const [comments, setComments] = useState([]);
  const [users, setUsers] = useState([]);
  const [newComment, setNewComment] = useState(null);
  const [activeLabel, setActiveLabel] = useState('comments');
  const [selectedCommentID, setSelectedCommentID] = useState('');
  const imageRef = useRef(null);
  const [mediaIndex, setMediaIndex] = useState(null);
  const [pageId, setPageId] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [emailColorMap, setEmailColorMap] = useState(new Map());
  const [showExpandedViewModal, setShowExpandedViewModal] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadingPostNewComment, setLoadingPostNewComment] = useState(false);

  // Adding Comments
  const [addingComment, setAddingComment] = useState(false);
  const [segmentSelected, setSegmentSelected] = useState(false);
  const parentDivRef = useRef();
  const addCommentButtonRef = useRef(null);
  const [imageDimensions, setImageDimensions] = useState(null);
  const [commentCoords, setCommentCoords] = useState(null);

  const { scrubIframeDoc, handleComment, comment } = useIframe();

  const assignPastelColorsToCommenters = (comments) => {
    let updatedMap = new Map(emailColorMap);

    const updatedComments = comments.map((comment) => {
      if (!updatedMap.has(comment.sender.email)) {
        updatedMap.set(comment.sender.email, getRandomPastelColor());
      }
      return { ...comment, bg: updatedMap.get(comment.sender.email) };
    });

    setEmailColorMap(updatedMap); // Update state with the new map
    return updatedComments;
  };

  const fetchComments = async () => {
    setIsLoading2(true);
    try {
      const response = await axios.get(
        `/api/approvalRequests/get/${approvalRequestId}?reviewer=${
          reviewerId ? reviewerId : ''
        }&firebaseUID=${user?.firebaseAuthUUID}`
      );
      const updatedComments = assignPastelColorsToCommenters(
        response.data.comments
      );
      setComments(updatedComments);
    } catch (error) {
      Logger.error('Error fetching comments:', error);
    }
    setIsLoading2(false);
  };

  const scrollToComment = (commentID) => {
    setTimeout(() => {
      const commentElement = commentRefs.current[commentID]?.current;
      if (commentElement) {
        commentElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 70); // Wait for 0.5 seconds before executing
  };

  function convertSecondsToTimestamp(seconds) {
    if (seconds < 60) {
      return `${Math.floor(seconds)} sec`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes} min ${remainingSeconds} sec`;
    }
  }

  const toggleResolved = async (_id) => {
    try {
      const response = await axios.post(`/api/comments/toggle-resolved/${_id}`);
      const data = await response.data;

      if (data.success) {
        toast({
          title: 'Status updated',
          description: 'The resolved status has been updated.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Optionally, trigger a state update to re-fetch or adjust the comments displayed
        fetchComments();
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'An error occurred while resolving the comment',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const deleteComment = async (_id) => {
    try {
      const response = await axios.delete(
        `/api/comments/delete-comment/${_id}`
      );
      const data = response.data;

      if (data.success) {
        toast({
          title: 'Comment Deleted',
          description: 'The comment has been successfully deleted.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Optionally, trigger a state update to re-fetch or adjust the comments displayed
        fetchComments();
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      Logger.error('Error deleting comment:', error);
      toast({
        title: 'Error',
        description: 'An error occurred while deleting the comment',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateComment = async (commentId, newComment, mentions) => {
    try {
      const response = await axios.patch(
        `/api/comments/update-comment/${commentId}`,
        { comment: newComment, mentions }
      );
      const data = response.data;

      if (data.success) {
        toast({
          title: 'Comment Updated',
          description: 'The comment has been updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        fetchComments();
        // Optionally, trigger a state update to re-fetch or adjust the comments displayed
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'Error',
        description: 'An error occurred while updating the comment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `/api/approvalRequests/get/${approvalRequestId}?reviewer=${
          reviewerId ? reviewerId : ''
        }&firebaseUID=${user?.firebaseAuthUUID}`
      );

      const data = response.data.approvalRequest;
      setLatestRequest(data);
      //         setApprovalRequestTimeline(sortedData);
      //         setTimelineName(sortedData[0].approvalTitle.trim());
      //         setLatestRequest(sortedData[0]);
      //         setWorkspaceIcon(workspaceIcon);
      setTimelineApprovalRequests(response.data.timeline);
      setWorkspaceIcon(response.data.workspaceIcon);
      setTimelineID(response.data.timelineID);
      setSender(response.data.sender);
      setReviewer(
        data.approvers.find(
          (approver) =>
            approver._id === reviewerId || approver.email === reviewerId
        )
      );
      setTimeline(response.data.timeline);
      setIsLoading(false);
    } catch (error) {
      Logger.error('Error fetching data: ', error);
      if (error.response?.data?.message === 'Approver has expired') {
        // history('/workspaces');
        setRequestNewLink(true);
      } else if (error.response?.status === 401) {
        toast({
          title: 'Unauthorized',
          description: 'You do not have permission to view this page.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });

        history(`/login?login_to=${encodeURIComponent(window.location.href)}`);
      }
    } finally {
    }
  };

  const handleLabelClick = (label) => {
    setActiveLabel(label);
  };

  useEffect(() => {
    const updateWidth = () => {
      // setMaxWidth(`${(window.innerWidth * 5) / 12}px`);
    };
    window.addEventListener('resize', updateWidth);
    updateWidth(); // initialize maxWidth
    fetchData();
    fetchComments();

    if (reviewerId) {
      axios
        .post('/api/approvalRequests/viewRequest', {
          reviewerEmail,
          reviewerId,
          approvalRequestId,
        })
        .then((response) => {})
        .catch((error) => {});
    }

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (imageRef.current) {
        const rect = imageRef.current.getBoundingClientRect();
        setImageDimensions({
          width: rect?.width,
          height: rect?.height,
          left: rect?.left,
          top: rect?.top,
        });
      }
    };
    handleResize();

    // Adding an event listener to handle window resize
    window.addEventListener('resize', handleResize);
    if (parentDivRef.current) {
      parentDivRef.current.addEventListener('scroll', handleResize);
    }
    window.addEventListener('scroll', handleResize); // consider when the page scrolls

    return () => {
      window.removeEventListener('resize', handleResize);
      if (parentDivRef.current) {
        parentDivRef.current.removeEventListener('scroll', handleResize);
      }
      window.removeEventListener('scroll', handleResize);
    }; // Cleanup the event listener on unmount
  }, [imageRef.current, toggleShowMore]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addingComment &&
        showInputRef?.current &&
        !showInputRef?.current.contains(event.target) &&
        imageRef?.current &&
        !imageRef?.current.contains(event.target) &&
        addCommentButtonRef?.current &&
        !addCommentButtonRef?.current.contains(event.target) &&
        !event.target.classList.value.includes('mentions-pop')
      ) {
        setAddingComment(false);
      } else if (
        addCommentButtonRef?.current &&
        addCommentButtonRef?.current.contains(event.target)
      ) {
        setAddingComment(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [addingComment, showInputRef, imageRef, addCommentButtonRef]);

  // Document Proofing
  useEffect(() => {
    if (selectionIds.length) {
      setIsDocumentComment(true);
    }
  }, [selectionIds]);

  useEffect(() => {
    let usersObj = {};

    if (sender) {
      usersObj[sender.email] = {
        id: sender.id,
        email: sender.email,
        name: sender.firstName,
      };
    }

    latestRequest?.approvers?.forEach((approver) => {
      usersObj[approver.email] = {
        id: approver._id,
        email: approver.email,
        name: approver.firstName,
      };
    });

    comments.forEach((comment) => {
      usersObj[comment.sender.email] = {
        id: comment.sender.id,
        email: comment.sender.email,
        name: comment.sender.user?.firstName,
      };

      let replies = comment.replies || [];

      replies.forEach((reply) => {
        if (!usersObj[reply.email]) {
          usersObj[reply.email] = {
            id: reply.user?.id,
            email: reply.email,
            name: reply.user?.firstName,
          };
        }
      });
    });
    if (user && !usersObj[user?.email]) {
      usersObj[user.email] = {
        id: user?.id,
        email: user?.email,
        name: user?.firstName,
      };
    }

    if (reviewer && !usersObj[reviewer?.email]) {
      usersObj[reviewer.email] = {
        email: reviewer.email,
        name: reviewer.firstName,
      };
    }

    setUsers(Object.values(usersObj));
  }, [comments, sender]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const res = await axios.get(`/api/workspace/getTimeline/${timelineID}`);
  //       if (res.status === 200) {
  //         const { approvalRequests, workspaceIcon } = res.data;

  //         const sortedData = approvalRequests.sort(
  //           (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
  //         );

  //         setApprovalRequestTimeline(sortedData);
  //         setTimelineName(sortedData[0].approvalTitle.trim());
  //         setLatestRequest(sortedData[0]);
  //         setWorkspaceIcon(workspaceIcon); // Assuming you have a state variable for workspace icon
  //         console.log('Approval Request Timeline:', sortedData);
  //       } else {
  //         Logger.error('Failed to fetch data');
  //       }
  //     } catch (error) {
  //       Logger.error('An error occurred while fetching data:', error);
  //     }
  //     setIsLoading(false);
  //   };

  //   const fetchNotifications = async () => {
  //     try {
  //       const response = await axios.get(
  //         `/api/notifications/notificationsByTimeline/${timelineID}`
  //       ); // replace '/api/notifications/' with your actual route for fetching notifications
  //       if (response.status === 200) {
  //         setNotifications(response.data);
  //       } else {
  //         Logger.error('Failed to fetch notifications');
  //       }
  //     } catch (error) {
  //       Logger.error('An error occurred while fetching notifications:', error);
  //     }
  //   };

  //   fetchData();
  //   fetchNotifications();
  // }, []);

  const handleNextSlide = () => {
    if (currentImage < latestRequest?.content?.media?.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const handleFullScreen = (desktopView) => {
    setIsDesktopView(desktopView);
  };

  const handleSendReminder = async () => {
    try {
      const res = await axios.post('/api/approvalRequests/sendReminder', {
        aprReqID: latestRequest?._id,
      });
      // toast
      setShowReminderModal(false);
      toast({
        title: 'Reminders Sent.',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      setShowReminderModal(false);
      toast({
        title: 'An error occurred.',
        description: 'Unable to send reminders.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // Logger.error('An error occurred while sending reminder:', error);
    }
  };

  const handleVersionSelection = (requestId) => {
    setSelectedVersion(requestId);
    // Construct the new URL with the request._id query parameter
    const url = `/review?${
      reviewerId ? `reviewer=${reviewer?._id}&` : ''
    }aprReqID=${requestId}`;
    history(url); // This changes the URL and navigates to the new route
    window.location = window.location;
  };

  const handleOutClicks = () => {
    versionSelect && setVersionSelect(false);
  };

  const handleAddCommentClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (latestRequest.content?.media[currentImage]?.media == 'video') {
        parentDivRef.current.scrollTop = parentDivRef.current.scrollHeight;
      }
      setAddingComment((prevAddingComment) => !prevAddingComment);
      setSegmentSelected(false);
      handleComment(!comment);
    },
    [comment]
  );

  const handleImageLoaded = () => {
    setImageLoaded(true); // Set the image as loaded
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      setImageDimensions({
        width: rect.width,
        height: rect.height,
        left: rect.left,
        top: rect.top,
      });
    }
  };

  const handleImageClick = (e) => {
    if (addingComment && imageDimensions) {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position within the element.
      const y = e.clientY - rect.top; // y position within the element.
      const relativeX = x / imageDimensions.width;
      const relativeY = y / imageDimensions.height;

      // Store these in state and use for the comment submission
      setMediaIndex(currentImage); // Assuming currentSlide state holds the index of the current media
      setCommentCoords({
        x: relativeX,
        y: relativeY,
        absoluteX: e.clientX,
        absoluteY: e.clientY,
      });
      setShowInput(true);
    }
  };

  const handleNewComment = async (_id, _content, mentions) => {
    setLoadingPostNewComment(true);
    const approvalRequestID = urlParams.get('aprReqID');
    // const reviewerEmail = urlParams.get('reviewer');
    const reviewerEmail = reviewerId ? reviewer.email : user.email;
    const currentMediaType = latestRequest.content?.media[currentImage]?.media;

    let commentData = {
      approvalRequestID,
      comment: newCommentRef.current?.value
        ? newCommentRef.current.value
        : showInputRef.current.value,
      senderEmail: reviewerEmail,
      mediaIndex: mediaIndex ? mediaIndex : currentImage,
      mentions,
    };
    // setShowInput(false);
    // setAddingComment(false);

    // Check if the current media is a video and add timestamp
    if (currentMediaType === 'video') {
      commentData = {
        ...commentData,
        coords: {
          videoTimestamp: imageRef.current?.currentTime ?? currentTime,
          x: commentCoords?.x,
          y: commentCoords?.y,
        },
      };
    } else if (['pdf', 'doc'].includes(currentMediaType)) {
      commentData = {
        ...commentData,
        selectionIds: selectionIds,
      };
      // If there was already a selection, remove highlight.
      const tempElems = document.getElementsByClassName('temp-selection');
      const elemsArray = Array.from(tempElems);

      elemsArray.forEach((elem) => {
        // Remove the 'temp-selection' class from each element to deselect
        elem.classList.remove('temp-selection');
      });
    } else {
      // For images, use existing coordinates logic
      commentData = {
        ...commentData,
        coords: {
          x: commentCoords?.x,
          y: commentCoords?.y,
        },
        isMobile: !isDesktopView,
      };
    }

    setNewComment(commentData);
    setAddingComment(false);

    try {
      const response = await axios.post('/api/comments/post', commentData);
      const aprReqID = urlParams.get('aprReqID');
      // const email = urlParams.get('reviewer'); // Replace with the current user's email
      const email = reviewerId ? reviewer.email : user.email;
      await axios.put('/api/approvalRequests/updateStatus', {
        aprReqID,
        email,
        status: 'needsRevison',
      });

      const updatedComments = assignPastelColorsToCommenters([
        ...comments,
        response.data,
      ]);

      setComments(updatedComments);
      setNewComment(null);
      toast({
        title: 'Comment sent.',
        duration: 5000,
        isClosable: true,
      });
      setCommentSent(true);
      // window.location.reload(true)
    } catch (error) {
      toast({
        title: 'Unable to send comment.',
        duration: 5000,
        status: 'error',
        isClosable: true,
      });
      // Logger.error('Error posting new comment:', error);
    } finally {
      setSelectionIds([]);
      setShowInput(false);
      setLoadingPostNewComment(false);
    }
  };

  const updateCommentCoords = async (commentId, coords) => {
    try {
      const response = await axios.patch(
        `/api/comments/update-coords/${commentId}`,
        { coords }
      );
      const data = response.data;

      if (data.success) {
        // toast({
        //   title: "Comment Updated",
        //   description: "The comment's position has been updated successfully.",
        //   status: "success",
        //   duration: 5000,
        //   isClosable: true,
        // });
        await fetchComments(); // Re-fetch comments to update the UI
      } else {
        toast({
          title: 'Error',
          description: data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'Error',
        description: "An error occurred while updating the comment's position.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDragStop = async (e, data, comment, absoluteX, absoluteY) => {
    let x = (data.x + absoluteX - imageDimensions.left) / imageDimensions.width;
    let y = (data.y + absoluteY - imageDimensions.top) / imageDimensions.height;
    const newRelativeX = x;
    const newRelativeY = y;

    // Update the comment position in the backend
    handleLabelClick(comment?.resolved ? 'resolved' : 'comments');
    await updateCommentCoords(comment._id, {
      x: newRelativeX,
      y: newRelativeY,
    });
  };

  const handleApprove = () => {
    setSelectedStatus('approved');
    setShowApprovalModal(true);
  };

  const handleConfirm = async () => {
    try {
      setIsConfirming(true);
      const aprReqID = urlParams.get('aprReqID');
      // const email = urlParams.get('reviewer'); // Replace with the current user's email
      const email = reviewerId ? reviewer.email : user.email;

      await axios.put('/api/approvalRequests/updateStatus', {
        aprReqID,
        email,
        status: selectedStatus,
      });
      //  alert("Status updated successfully");
      toast({
        title: 'Request Approved.',
        duration: 5000,
        isClosable: true,
      });
      fetchData();
    } catch (error) {
      Logger.error('Error updating status: ', error);
      //  alert("Failed to update status");
      toast({
        title: 'Error: Unable to approve request.',
        duration: 5000,
        status: 'error',
        isClosable: true,
      });
    } finally {
      setIsConfirming(false);
      setShowApprovalModal(false);
    }
  };

  return (
    <PageWrapper
      title="Approval request timeline"
      hideSidebar={!user?.email && true}
      overlay={
        showVersionHistory && (
          <div className="absolute w-full h-screen bg-gray-300/[.90] flex justify-end z-50">
            <div className="max-w-[400px] h-screen overflow-hidden w-full bg-white border pt-12 p-5">
              <div className="w-full relative flex flex-col h-full overflow-hidden">
                <button
                  className="absolute top-0 right-0"
                  onClick={() => setShowVersionHistory(false)}
                >
                  <svg
                    className="h-6 w-6 text-gray-800"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                <div className="flex flex-col gap-4 py-2">
                  <div>
                    <p className="font-semibold text-xl">Version History</p>
                    <p className="text-sm text-gray-500">
                      View all past version
                    </p>
                  </div>
                </div>
                <div className="flex h-full overflow-hidden">
                  <div className="flex flex-col-reverse pl-3 pb-2 grow overflow-y-scroll justify-end no-scrollbar">
                    {timelineApprovalRequests.map((request, index) => (
                      <div
                        className={`flex flex-col relative pl-8 pb-5 border-l-2 border-primary cursor-pointer ${
                          request._id === selectedVersion ? 'text-primary' : ''
                        }`}
                        onClick={() => handleVersionSelection(request._id)}
                      >
                        <div className="flex absolute -left-4 top-0 rounded-full bg-white p-1">
                          {request._id === selectedVersion ? (
                            <PendingStep size={20} active={true} />
                          ) : (
                            <CompletedStep size={20} className="" />
                          )}
                        </div>
                        <div>
                          Version {index + 1}{' '}
                          {request._id === selectedVersion ? '(Current)' : ''}
                        </div>
                        <div className="text-xs">
                          {foramtDateComments(request.dateCreated)}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    >
      <div className="h-full flex flex-col gap-8" onClick={handleOutClicks}>
        {!user?.email && (
          <div>
            <img src={fullLogo} alt="SwiftApprove" className="w-[10%]" />
          </div>
        )}
        <div className="flex justify-between">
          <div className="w-1/3"></div>
          <div className="text-3xl font-semibold w-1/3 text-center">
            {latestRequest?.approvalTitle}
          </div>
          <div className="w-1/3 justify-end flex">
            {(() => {
              const urlParams = new URLSearchParams(window.location.search);
              const reviewerId = urlParams.get('reviewer');
              const approverObj = latestRequest.approvers?.find(
                (approver) =>
                  approver.email === reviewerId || approver._id === reviewerId
              );

              const allApproved = latestRequest.approvers?.every(
                (approver) => approver.status === 'approved'
              );

              if (allApproved) {
                return (
                  <button className="text-primary-600 bg-white outline cursor-default flex items-center text-md px-4 py-2 font-bold  rounded-lg focus:outline-none focus:shadow-outline-blue">
                    <span className="flex items-center">Request Approved </span>
                  </button>
                );
              }

              if (approverObj && user?.email != reviewer?.email) {
                if (
                  approverObj?.status === 'pending' ||
                  approverObj?.status === 'needsRevison'
                ) {
                  return (
                    <div className="flex">
                      <div
                        className="flex hover-shadow items-center cursor-pointer"
                        onClick={() => handleApprove()}
                      >
                        <button className='flex items-center text-md px-4 py-2 font-bold text-white bg-primary rounded-lg hover-shadow hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"'>
                          <svg
                            width="15"
                            height="11"
                            viewBox="0 0 15 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.1667 1L5.00004 10.1667L0.833374 6"
                              stroke="white"
                              stroke-width="1.66667"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <p className="ml-2">Approve</p>
                        </button>
                      </div>
                    </div>
                  );
                } else if (approverObj?.status === 'approved') {
                  return (
                    <button className="text-primary-600 bg-white outline cursor-default flex items-center text-md px-4 py-2 font-bold  rounded-lg focus:outline-none focus:shadow-outline-blue">
                      <span className="flex items-center">
                        Request Approved{' '}
                      </span>
                    </button>
                  );
                }
              }

              if (!latestRequest.approvers?.length && user?.email) {
                return (
                  <div className="flex">
                    <div className="flex text-xs hover-shadow px-4 rounded-xl py-1 items-center cursor-pointer">
                      <span className="flex items-center">
                        No Approval required{' '}
                      </span>
                    </div>
                  </div>
                );
              }

              return null;
            })()}
          </div>
        </div>

        <div
          className="flex overflow-x-scroll gap-6 overflow-y-hidden no-scrollbar"
          ref={parentDivRef}
        >
          <div className="max-w-[25%] min-w-[25%] flex flex-col">
            <div className="w-full border rounded-lg flex flex-col gap-3">
              <div className="flex justify-between flex-col">
                <div className="p-1.5 flex flex-col gap-2 w-fit rounded-lg items-baseline">
                  <p className="font-bold text-lg p-6">Approval Information</p>
                  <div
                    className={`flex flex-col justify-between p-6`}
                    style={{
                      margin: '0 auto',
                    }}
                  >
                    <div className="">
                      <p className="font-semibold text-md">
                        {latestRequest?.approvalTitle}
                      </p>

                      <div className="flex flex-row mt-2">
                        {sender.profilePicURL && (
                          <img
                            src={sender.profilePicURL}
                            className="h-7 w-7 mr-2"
                            style={{
                              border: '1px solid #ccc',
                              borderRadius: '50%',
                            }}
                          />
                        )}

                        <div className="flex flex-col">
                          <p className="text-sm ">{sender.fullName}</p>
                          <p className="text-xs  mb-2">
                            Sent on{' '}
                            {formatDateAbrev(latestRequest?.dateCreated)}
                          </p>
                        </div>
                      </div>

                      <>
                        <p className="text-sm font-semibold my-2.5">
                          Description
                        </p>
                        <div
                          className="mt-1 overflow-auto"
                          style={{ maxHeight: '80%' }}
                        >
                          {' '}
                          {/* Adjust maxHeight as needed */}
                          <p
                            className="text-sm"
                            style={{ whiteSpace: 'pre-wrap' }}
                            dangerouslySetInnerHTML={{
                              __html: latestRequest?.description,
                            }}
                          />
                        </div>
                      </>
                    </div>

                    <p className="text-sm font-semibold mt-4">
                      Due: {formatDateAbrev(latestRequest?.dueDate)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="max-h-[690px] h-full grow max-w-[50%] min-w-[25%] w-full flex flex-col gap-6">
            <div
              className={`flex items-baseline w-full ${
                latestRequest?.content?.media[currentImage]?.media === 'html'
                  ? 'justify-between'
                  : 'justify-end'
              }`}
            >
              {latestRequest?.content?.media[currentImage]?.media === 'html' ? (
                <div className="flex h-10 bg-gray-200 rounded p-1 cursor-pointer">
                  <div
                    className={`px-2 flex items-center ${
                      isDesktopView ? 'bg-white' : ''
                    }`}
                    onClick={() => handleFullScreen(true)}
                  >
                    Desktop View
                  </div>
                  <div
                    className={`px-2 flex items-center ${
                      isDesktopView ? '' : 'bg-white'
                    } rounded`}
                    onClick={() => handleFullScreen(false)}
                  >
                    Mobile View
                  </div>
                </div>
              ) : null}
              <div
                className="border rounded-lg h-10 flex items-center font-semibold cursor-pointer relative"
                onClick={() => setShowVersionHistory(true)}
              >
                <div className="px-3 flex items-center gap-2">
                  Version{' '}
                  {timelineApprovalRequests?.findIndex(
                    (version) => version._id === selectedVersion
                  ) + 1}{' '}
                  <span>
                    <ChevronDown />
                  </span>
                </div>
                {versionSelect && (
                  <div className="absolute w-max bg-white py-2 px-1 flex flex-col rounded-lg border z-20 top-0 gap-1">
                    {timelineApprovalRequests.map((request, index) => (
                      <div
                        className={`flex items-center gap-4 px-2 py-1 rounded-lg cursor-pointer hover:bg-gray-100 ${
                          request._id === selectedVersion ? 'bg-gray-200' : ''
                        }`}
                        key={index}
                        onClick={() => handleVersionSelection(request._id)}
                      >
                        <p>Version {index + 1}</p>
                        {request._id === selectedVersion && <Tick />}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className={`${isDesktopView ? '' : 'flex justify-center'}`}>
              <div
                className={`flex px-5 pt-5 border rounded-lg min-w-[582px] max-w-[900px] h-[535px] relative ${
                  isDesktopView ? '' : 'w-[250px] '
                }`}
              >
                <div
                  className=" w-full h-full relative group border overflow-hidden flex items-center justify-center"
                  // ref={parentDivRef}
                >
                  {latestRequest?.content?.media &&
                    latestRequest.content?.media.length > 0 && (
                      <>
                        {latestRequest.content?.media[currentImage]?.media ===
                        'video' ? (
                          <VideoPlayer
                            src={
                              latestRequest.content?.media[currentImage]?.src
                            }
                            videoRef={imageRef}
                            comments={comments.filter(
                              (comment) =>
                                (activeLabel === 'comments' &&
                                  !comment.resolved) ||
                                (activeLabel === 'resolved' && comment.resolved)
                            )}
                            imageDimensions={imageDimensions}
                            setMediaIndex={setMediaIndex}
                            currentSlide={currentImage}
                            setCommentCoords={setCommentCoords}
                            addingComment={addingComment}
                            sender={sender}
                            setSelectedCommentID={setSelectedCommentID}
                            handleLabelClick={handleLabelClick}
                            scrollToComment={scrollToComment}
                            setShowExpandedViewModal={setShowExpandedViewModal}
                            showExpandedViewModal={showExpandedViewModal}
                            selectedCommentID={selectedCommentID}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                          />
                        ) : latestRequest.content?.media[currentImage]
                            ?.media === 'html' ? (
                          <div
                            className={
                              addingComment
                                ? 'adding-comment rounded-lg cursor-comment w-full h-full'
                                : 'rounded-lg w-full h-full'
                            }
                            style={{
                              // overflow: 'hidden',
                              width: '100%',
                              // maxHeight: '70vh',
                            }}
                          >
                            <div className="flex justify-between">
                              {/* <MdOutlinePhonelink
                                  size={30}
                                  className=" cursor-pointer mx-4"
                                  onClick={() =>
                                    setIsDesktopView(!isDesktopView)
                                  }
                                />
                                <GoScreenFull
                                  size={30}
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setShowExpandedViewModal(
                                      !showExpandedViewModal
                                    )
                                  }
                                /> */}
                            </div>
                            <Iframe
                              onLoad={scrubIframeDoc}
                              scrubIframeDoc={scrubIframeDoc}
                              srcDoc={
                                latestRequest.content?.media[currentImage]?.src
                              }
                              style={{
                                width: isDesktopView ? '200%' : '100%', // Increase width
                                height: isDesktopView ? '200%' : '100%',
                                border: 'none',
                                margin: '0 auto',
                                transform: isDesktopView
                                  ? 'scale(0.5)'
                                  : 'scale(1.0)',
                                transformOrigin: '0 0',
                                position: 'relative',
                                pointerEvents: 'none',
                              }}
                              title={`Post ${currentImage}`}
                              originalRef={iframeRef}
                              comment={comment}
                              onInferredClick={(e) => {
                                // can add click logic here. not sure if I still need it but will keep for now.
                                scrubIframeDoc(e);
                              }}
                              addingcomment={addingComment}
                              setSegmentSelected={setSegmentSelected}
                              segmentselected={segmentSelected}
                              comments={comments.filter((comment) => {
                                const isCurrentImage =
                                  comment.mediaIndex === currentImage;
                                const isActiveLabelMatch =
                                  (activeLabel === 'comments' &&
                                    !comment.resolved) ||
                                  (activeLabel === 'resolved' &&
                                    comment.resolved);
                                const isViewModeMatch =
                                  (comment?.isMobile === true &&
                                    !isDesktopView) || // Show mobile comments in mobile view
                                  (comment?.isMobile === false &&
                                    isDesktopView) || // Show desktop comments in desktop view
                                  (comment?.isMobile === undefined &&
                                    isDesktopView); // Default to showing comments without `isMobile` in desktop view

                                return (
                                  isCurrentImage &&
                                  isActiveLabelMatch &&
                                  isViewModeMatch
                                );
                              })}
                              currentImage={currentImage}
                              setCommentCoords={setCommentCoords}
                              selectedCommentID={selectedCommentID}
                              setSelectedCommentID={setSelectedCommentID}
                              scrollToComment={scrollToComment}
                              user={
                                reviewer
                                  ? reviewer
                                  : user.email
                                  ? user
                                  : { email: reviewerEmail }
                              }
                              handleClickActions={(iframeRef, e) => {
                                const clickedY = e.view.scrollY + e.clientY;
                                const clickedX = e.view.scrollX + e.clientX;

                                const absoluteY =
                                  clickedY /
                                  e.target.ownerDocument.documentElement
                                    .scrollHeight;
                                const absoluteX =
                                  clickedX /
                                  e.target.ownerDocument.documentElement
                                    .scrollWidth;

                                var parentDiv =
                                  iframeRef?.current?.parentElement;

                                // Get the bounding rectangle of the iframe
                                var iframeRect =
                                  iframeRef?.current?.getBoundingClientRect();
                                var parentDivRect =
                                  parentDiv.getBoundingClientRect();

                                var iframeViewportHeight =
                                  iframeRef?.current?.contentWindow.innerHeight;
                                var iframeViewportWidth =
                                  iframeRef?.current?.contentWindow.innerWidth;

                                const xRatio =
                                  iframeRect.width / iframeViewportWidth;
                                const yRatio =
                                  iframeRect.height / iframeViewportHeight;

                                var clickX =
                                  xRatio * e.clientX + parentDivRect.left;
                                var clickY =
                                  yRatio * e.clientY + parentDivRect.top;

                                setCommentCoords({
                                  x: absoluteX,
                                  y: absoluteY,
                                  absoluteX: clickX,
                                  absoluteY: clickY,
                                });

                                setShowInput(true);
                              }}
                              reviewerEmail={(reviewer ?? user)?.email}
                            />
                          </div>
                        ) : latestRequest.content?.media[currentImage]
                            ?.media === 'pdf' ? (
                          <>
                            <PdfPreview
                              data={
                                latestRequest?.content?.media[currentImage].src
                              }
                              className={
                                addingComment
                                  ? 'adding-comment rounded-lg cursor-comment'
                                  : 'rounded-lg'
                              }
                              id={pageId}
                              setId={setPageId}
                              sender={sender}
                              addingComment={addingComment}
                              slide={currentImage}
                              setSelectionIds={setSelectionIds}
                              selectedIds={selectionIds}
                              comments={comments.filter(
                                (comment) =>
                                  (activeLabel === 'comments' &&
                                    !comment.resolved) ||
                                  (activeLabel === 'resolved' &&
                                    comment.resolved)
                              )}
                            />
                          </>
                        ) : latestRequest.content?.media[currentImage]
                            ?.media === 'doc' ? (
                          <>
                            <DocPreview
                              url={
                                latestRequest?.content?.media[currentImage].src
                              }
                              sender={sender}
                              addingComment={addingComment}
                              id={pageId}
                              setId={setPageId}
                              setSelectionIds={setSelectionIds}
                              comments={comments.filter(
                                (comment) =>
                                  (activeLabel === 'comments' &&
                                    !comment.resolved) ||
                                  (activeLabel === 'resolved' &&
                                    comment.resolved)
                              )}
                              slide={currentImage}
                              className={
                                addingComment
                                  ? 'adding-comment rounded-lg cursor-comment'
                                  : 'rounded-lg'
                              }
                            />
                          </>
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              // height: 'auto',
                              // maxHeight: '65vh',
                              height: '100%',
                              // minHeight: 'fit-content',
                              marginBottom: '1rem',
                              display: 'flex',
                              position: 'relative',
                            }}
                          >
                            <img
                              src={
                                latestRequest.content?.media[currentImage]?.src
                              }
                              onLoad={handleImageLoaded}
                              alt={`Post ${currentImage}`}
                              ref={imageRef}
                              className={
                                addingComment
                                  ? 'adding-comment rounded-lg cursor-comment'
                                  : 'rounded-lg'
                              }
                              style={{
                                objectFit: 'contain',
                                margin: 'auto',
                                display: 'block',
                                width: '100%',
                                height: '100%',
                              }}
                              onClick={(e) => handleImageClick(e)}
                            />
                            {imageLoaded &&
                              comments.length > 0 &&
                              !['video', 'html', 'doc', 'pdf'].includes(
                                latestRequest.content?.media[currentImage]
                                  ?.media
                              ) &&
                              comments
                                .filter(
                                  (comment) =>
                                    (activeLabel === 'comments' &&
                                      !comment.resolved) ||
                                    (activeLabel === 'resolved' &&
                                      comment.resolved)
                                )
                                .map((comment) => {
                                  if (comment.mediaIndex === currentImage) {
                                    const absoluteX =
                                      imageDimensions.left +
                                      comment.coords.x * imageDimensions.width;
                                    const absoluteY =
                                      imageDimensions.top +
                                      comment.coords.y * imageDimensions.height;

                                    return (
                                      <>
                                        {(reviewer ?? user)?.email ==
                                        comment.sender.email ? (
                                          <DraggableAvatar
                                            key={comment._id}
                                            absoluteX={absoluteX}
                                            absoluteY={absoluteY}
                                            loading={isLoading2}
                                            selectedCommentID={
                                              selectedCommentID
                                            }
                                            setSelectedCommentID={
                                              setSelectedCommentID
                                            }
                                            scrollToComment={scrollToComment}
                                            comment={comment}
                                            imageDimensions={imageDimensions}
                                            handleDragStop={handleDragStop}
                                          >
                                            <div
                                              onMouseDown={(e) => {
                                                e.preventDefault();
                                              }}
                                              className="flex rounded-lg gap-2 group/comment hover:z-40"
                                            >
                                              {comment.sender.email ==
                                                sender.email &&
                                              sender?.profilePicURL != null ? (
                                                <img
                                                  src={sender?.profilePicURL}
                                                  className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setSelectedCommentID(
                                                      comment._id
                                                    );
                                                    handleLabelClick(
                                                      comment?.resolved
                                                        ? 'resolved'
                                                        : 'comments'
                                                    );
                                                    setTimeout(() => {
                                                      scrollToComment(
                                                        comment._id
                                                      );
                                                    }, 30);
                                                  }}
                                                />
                                              ) : (
                                                <AvatarComponent
                                                  classes="rounded-full"
                                                  useGravatar={false}
                                                  color={darkerShade(
                                                    comment.bg,
                                                    0.55
                                                  )}
                                                  background={comment.bg}
                                                  fontSize={16}
                                                  fontWeight={400}
                                                  className="text-primary cursor-pointer"
                                                  size={32}
                                                  initials={comment.sender.email
                                                    .substring(0, 1)
                                                    .toUpperCase()}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setSelectedCommentID(
                                                      comment._id
                                                    );
                                                    handleLabelClick(
                                                      comment?.resolved
                                                        ? 'resolved'
                                                        : 'comments'
                                                    );
                                                    setTimeout(() => {
                                                      scrollToComment(
                                                        comment._id
                                                      );
                                                    }, 30);
                                                  }}
                                                />
                                              )}
                                              <div className="hidden group-hover/comment:block text-xs flex flex-col max-w-[200px] gap-1 text-left">
                                                <div className="flex gap-4 w-full">
                                                  <span className="text-nowrap truncate grow">
                                                    {comment.sender.user
                                                      ?.fullName ??
                                                      comment.sender.email}
                                                  </span>
                                                  <span className="text-nowrap">
                                                    {formatAgoDate(
                                                      comment.dateCreated
                                                    )}
                                                  </span>
                                                </div>
                                                <div>{comment.comment}</div>
                                              </div>
                                            </div>
                                          </DraggableAvatar>
                                        ) : (
                                          <button
                                            style={{
                                              position: 'fixed',
                                              left: `${absoluteX}px`,
                                              top: `${absoluteY}px`,
                                            }}
                                            className={`text-primary cursor-pointer p-1 bg-gray-50 rounded-full ${
                                              selectedCommentID == comment._id
                                                ? 'adding-comment'
                                                : ''
                                            }`}
                                            onClick={() => {
                                              setSelectedCommentID(comment._id);
                                              scrollToComment(comment._id);
                                            }}
                                            key={comment._id}
                                          >
                                            <div
                                              onMouseDown={(e) => {
                                                e.preventDefault();
                                              }}
                                              className="flex rounded-lg gap-2 group/comment hover:z-40"
                                            >
                                              {comment.sender.email ==
                                                sender.email &&
                                              sender?.profilePicURL != null ? (
                                                <img
                                                  src={sender?.profilePicURL}
                                                  className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setSelectedCommentID(
                                                      comment._id
                                                    );
                                                    handleLabelClick(
                                                      comment?.resolved
                                                        ? 'resolved'
                                                        : 'comments'
                                                    );
                                                    setTimeout(() => {
                                                      scrollToComment(
                                                        comment._id
                                                      );
                                                    }, 30);
                                                  }}
                                                />
                                              ) : (
                                                <AvatarComponent
                                                  classes="rounded-full"
                                                  useGravatar={false}
                                                  color={darkerShade(
                                                    comment.bg,
                                                    0.55
                                                  )}
                                                  background={comment.bg}
                                                  fontSize={16}
                                                  fontWeight={400}
                                                  className="text-primary cursor-pointer"
                                                  size={32}
                                                  initials={comment.sender.email
                                                    .substring(0, 1)
                                                    .toUpperCase()}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    setSelectedCommentID(
                                                      comment._id
                                                    );
                                                    handleLabelClick(
                                                      comment?.resolved
                                                        ? 'resolved'
                                                        : 'comments'
                                                    );
                                                    setTimeout(() => {
                                                      scrollToComment(
                                                        comment._id
                                                      );
                                                    }, 30);
                                                  }}
                                                />
                                              )}
                                              <div className="hidden group-hover/comment:block text-xs flex flex-col max-w-[200px] gap-1 text-left">
                                                <div className="flex gap-4 w-full">
                                                  <span className="text-nowrap truncate grow">
                                                    {comment.sender.user
                                                      ?.fullName ??
                                                      comment.sender.email}
                                                  </span>
                                                  <span className="text-nowrap">
                                                    {formatAgoDate(
                                                      comment.dateCreated
                                                    )}
                                                  </span>
                                                </div>
                                                <div>{comment.comment}</div>
                                              </div>
                                            </div>
                                          </button>
                                        )}
                                      </>
                                    );
                                  }
                                })}
                          </div>
                        )}
                      </>
                    )}
                </div>

                {currentImage > 0 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute rotate-180 left-0 bottom-2/4 cursor-pointer"
                    onClick={handlePrevSlide}
                  >
                    <NextArrow />
                  </div>
                )}
                {currentImage < latestRequest?.content?.media?.length - 1 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute right-0 bottom-2/4 cursor-pointer"
                    onClick={handleNextSlide}
                  >
                    <NextArrow />
                  </div>
                )}

                {addingComment &&
                  (latestRequest.content?.media[currentImage]?.media ==
                    'video' ||
                    (['pdf', 'doc'].includes(
                      latestRequest.content?.media[currentImage]?.media
                    ) &&
                      isDocumentComment)) && (
                    <div
                      style={{ boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)' }}
                      className="rounded-xl hover-shadow bg-white border-gray-200 p-2 absolute bottom-0 right-0"
                    >
                      <TextAreaComponent
                        initialValue={''}
                        id={'id'}
                        closeEdit={() => {}}
                        users={users}
                        placeholder="Write a new comment..."
                        currentUser={
                          reviewer
                            ? reviewer
                            : user.email
                            ? user
                            : { email: reviewerEmail }
                        }
                        reviewerEmail={(reviewer ?? user)?.email}
                        textareaRef={newCommentRef}
                        saveFn={handleNewComment}
                        mentionsPopPosition={'top'}
                      />
                    </div>
                  )}
              </div>
            </div>
            <div className="flex w-full justify-center gap-1.5 overflow-scroll no-scrollbar">
              {latestRequest?.content?.media?.map((_, idx) => (
                <div
                  // className="w-2.5 h-2.5 rounded-full bg-primary"
                  key={idx}
                  onClick={() => handleDotClick(idx)}
                  className={`w-2.5 h-2.5 rounded-full bg-primary cursor-pointer ${
                    currentImage === idx ? 'bg-primary' : 'bg-gray-400'
                  }`}
                ></div>
                // <span
                //   key={idx}
                //   onClick={() => handleDotClick(idx)}
                //   className={`inline-block h-2.5 w-2.5 rounded-full cursor-pointer ${
                //     currentImage === idx ? 'bg-gray-400' : 'bg-gray-200'
                //   }`}
                // ></span>
              ))}
              {/* <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
                <div className="w-2.5 h-2.5 rounded-full bg-gray-400"></div>
                <div className="w-2.5 h-2.5 rounded-full bg-gray-400"></div> */}
            </div>
            {showInput && (
              <div
                style={{
                  position: 'fixed',
                  boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.2)',
                  zIndex: '99',
                  left: `${commentCoords?.absoluteX}px`,
                  top: `${commentCoords?.absoluteY}px`,
                }}
                className={
                  addingComment
                    ? 'rounded-xl hover-shadow bg-white p-1 border-gray-200'
                    : 'text-primary cursor-pointer bg-gray-50 rounded-r-full rounded-t-full'
                }
                // className="rounded-xl hover-shadow bg-white border-gray-200 p-2 "
              >
                {addingComment ? (
                  <TextAreaComponent
                    initialValue={''}
                    id={'id'}
                    closeEdit={() => {}}
                    users={users}
                    currentUser={
                      reviewer
                        ? reviewer
                        : user.email
                        ? user
                        : { email: reviewerEmail }
                    }
                    reviewerEmail={(reviewer ?? user)?.email}
                    textareaRef={showInputRef}
                    saveFn={handleNewComment}
                  />
                ) : (
                  !['video', 'html', 'doc', 'pdf'].includes(
                    latestRequest.content?.media[currentImage]?.media
                  ) &&
                  (sender?.profilePicURL != null ? (
                    <img
                      src={sender?.profilePicURL}
                      className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                    />
                  ) : (
                    <AvatarComponent
                      classes="text-primary cursor-pointer p-1 bg-gray-50 rounded-full"
                      useGravatar={false}
                      color={darkerShade(
                        emailColorMap.get((reviewer ?? user)?.email),
                        0.55
                      )}
                      background={emailColorMap.get((reviewer ?? user)?.email)}
                      fontSize={16}
                      fontWeight={400}
                      className="text-primary cursor-pointer"
                      width={40}
                      height={40}
                      initials={(reviewer ?? user)?.email
                        .substring(0, 1)
                        .toUpperCase()}
                    />
                  ))
                )}
              </div>
            )}
            {/* {imageLoaded &&
              comments.length > 0 &&
              !['video', 'html', 'doc', 'pdf'].includes(
                latestRequest.content?.media[currentImage]?.media
              ) &&
              comments.map((comment) => {
                if (comment.mediaIndex === currentImage) {
                  const absoluteX =
                    imageDimensions.left +
                    comment.coords.x * imageDimensions.width;
                  const absoluteY =
                    imageDimensions.top +
                    comment.coords.y * imageDimensions.height;

                  return (
                    <>
                      {(reviewer ?? user)?.email == comment.sender.email ? (
                        <DraggableAvatar
                          key={comment._id}
                          absoluteX={absoluteX}
                          absoluteY={absoluteY}
                          loading={isLoading2}
                          selectedCommentID={selectedCommentID}
                          setSelectedCommentID={setSelectedCommentID}
                          scrollToComment={scrollToComment}
                          comment={comment}
                          imageDimensions={imageDimensions}
                          handleDragStop={handleDragStop}
                        >
                          <div
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}

                            className='flex rounded-lg gap-2'
                          >
                            {comment.sender.email == sender.email &&
                            sender?.profilePicURL != null ? (
                              <img
                                src={sender?.profilePicURL}
                                className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedCommentID(comment._id);
                                  handleLabelClick(
                                    comment?.resolved ? 'resolved' : 'comments'
                                  );
                                  setTimeout(() => {
                                    scrollToComment(comment._id);
                                  }, 30);
                                }}
                              />
                            ) : (
                              <AvatarComponent
                                classes="rounded-full"
                                useGravatar={false}
                                color={darkerShade(comment.bg, 0.55)}
                                background={comment.bg}
                                fontSize={16}
                                fontWeight={400}
                                className="text-primary cursor-pointer"
                                size={32}
                                initials={comment.sender.email
                                  .substring(0, 1)
                                  .toUpperCase()}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedCommentID(comment._id);
                                  handleLabelClick(
                                    comment?.resolved ? 'resolved' : 'comments'
                                  );
                                  setTimeout(() => {
                                    scrollToComment(comment._id);
                                  }, 30);
                                }}
                              />
                            )}
                            <div className='hidden group-hover:block text-xs flex flex-col max-w-[200px] gap-1 text-left'>
                              <div className='flex gap-4 w-full'>
                                <span className='text-nowrap truncate grow'>{comment.sender.user?.fullName ?? comment.sender.email}</span>
                                <span className='text-nowrap'>{formatAgoDate(comment.dateCreated)}</span>
                              </div>
                              <div >
                                {comment.comment}
                              </div>
                            </div>
                          </div>
                        </DraggableAvatar>
                      ) : (
                        <button
                          style={{
                            position: 'fixed',
                            left: `${absoluteX}px`,
                            top: `${absoluteY}px`,
                          }}
                          className={`text-primary cursor-pointer p-1 bg-gray-50 rounded-full ${
                            selectedCommentID == comment._id
                              ? 'adding-comment'
                              : ''
                          }`}
                          onClick={() => {
                            setSelectedCommentID(comment._id);
                            scrollToComment(comment._id);
                          }}
                          key={comment._id}
                        >
                          <div
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}
                          >
                            {comment.sender.email == sender.email &&
                            sender?.profilePicURL != null ? (
                              <img
                                src={sender?.profilePicURL}
                                className={`text-primary cursor-pointer h-8 w-8 rounded-full `}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedCommentID(comment._id);
                                  handleLabelClick(
                                    comment?.resolved ? 'resolved' : 'comments'
                                  );
                                  setTimeout(() => {
                                    scrollToComment(comment._id);
                                  }, 30);
                                }}
                              />
                            ) : (
                              <AvatarComponent
                                classes="rounded-full"
                                useGravatar={false}
                                color={darkerShade(comment.bg, 0.55)}
                                background={comment.bg}
                                fontSize={16}
                                fontWeight={400}
                                className="text-primary cursor-pointer"
                                size={32}
                                initials={comment.sender.email
                                  .substring(0, 1)
                                  .toUpperCase()}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectedCommentID(comment._id);
                                  handleLabelClick(
                                    comment?.resolved ? 'resolved' : 'comments'
                                  );
                                  setTimeout(() => {
                                    scrollToComment(comment._id);
                                  }, 30);
                                }}
                              />
                            )}
                            <div className='hidden group-hover:block text-xs flex flex-col max-w-[200px] gap-1 text-left'>
                              <div className='flex gap-4 w-full'>
                                <span className='text-nowrap truncate grow'>Time Developer dfsdf sdfsadf</span>
                                <span className='text-nowrap'>16 hours ago sfsd</span>
                              </div>
                              <div >
                                We are testing what we kow to be great, and we are going to be great.
                              </div>
                            </div>
                          </div>
                        </button>
                      )}
                    </>
                  );
                }
              })} */}
            {/* <div className="flex w-full justify-center gap-1.5 overflow-scroll no-scrollbar">
              <div className="w-2.5 h-2.5 rounded-full bg-primary"></div>
              <div className="w-2.5 h-2.5 rounded-full bg-gray-400"></div>
              <div className="w-2.5 h-2.5 rounded-full bg-gray-400"></div>
            </div> */}
          </div>

          <div className="max-w-[25%] min-w-[25%] flex flex-col">
            <div className="w-full border  flex flex-col gap-3">
              <div className="flex justify-between">
                <div className="p-1.5 flex gap-2 bg-gray-200 w-fit rounded-r-lg rounded-b-lg items-baseline cursor-pointerr ">
                  <div
                    className={`py-1.5 px-3 cursor-pointer ${
                      activeLabel === 'comments' ? 'bg-white' : ''
                    }`}
                    onClick={() => setActiveLabel('comments')}
                  >
                    Comments
                  </div>
                  <div
                    className={`py-1.5 px-3 cursor-pointer ${
                      activeLabel === 'resolved' ? 'bg-white' : ''
                    }`}
                    onClick={() => setActiveLabel('resolved')}
                  >
                    Resolved
                  </div>
                </div>
              </div>

              <div className="px-3 flex flex-col gap-1 max-h-40 overflow-x-scroll no-scrollbar relative">
                <p className="font-semibold sticky top-0 bg-white p-2">
                  Comments
                </p>
              </div>

              {/* <div className="py-4 px-6 flex gap-2">
                <button
                  className="border rounded-lg w-full py-2 font-semibold text-gray-700"
                  onClick={() => setShowReminderModal(true)}
                >
                  Send Reminder
                </button>
                <button className="border rounded-lg w-full py-2 font-semibold text-gray-700" onClick={() => {
                  history('/edit/' + latestRequest?._id);
                }}>
                  Edit Approval
                </button>
              </div> */}
            </div>
            <div className="w-full grow flex flex-col gap-3 px-6 pt-4 overflow-hidden border">
              <div
                className="flex w-full border rounded-lg h-10 items-center justify-center gap-2 cursor-pointer"
                ref={addCommentButtonRef}
                onClick={handleAddCommentClick}
              >
                {addingComment ? (
                  <>Cancel Comment</>
                ) : (
                  <>
                    <Plus className="stroke-gray-800" /> Add Comment
                  </>
                )}
              </div>
              <p className="font-semibold">Activity</p>
              <div className="grow overflow-y-scroll flex flex-col no-scrollbar">
                <CommentSection
                  scrollableDivRef={scrollableDivRef}
                  comments={comments}
                  newComment={newComment}
                  activeLabel={activeLabel}
                  reviewerEmail={(reviewer ?? user)?.email}
                  users={users}
                  appReq={latestRequest}
                  commentRefs={commentRefs}
                  convertSecondsToTimestamp={convertSecondsToTimestamp}
                  deleteComment={deleteComment}
                  imageRef={imageRef}
                  scrollToComment={scrollToComment}
                  selectedCommentID={selectedCommentID}
                  sender={sender}
                  setCurrentSlide={setCurrentImage}
                  setIsPlaying={setIsPlaying}
                  setSelectedCommentID={setSelectedCommentID}
                  toggleResolved={toggleResolved}
                  updateComment={updateComment}
                  user={
                    reviewer
                      ? reviewer
                      : user.email
                      ? user
                      : { email: reviewerEmail }
                  }
                  setMediaIndex={setMediaIndex}
                  setSelectionId={setPageId}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      <SendReminderModal
        isOpen={showReminderModal}
        closeModal={() => setShowReminderModal(false)}
        handleSendReminder={handleSendReminder}
        isSendingReminder={isSendingReminder}
        pendingApprovers={latestRequest?.approvers
          ?.filter((approver) => approver.status === 'pending')
          .map((approver) => approver.email)}
      />

      <DefaultModal
        isOpen={showApprovalModal}
        title="Approve Request"
        description="Are you sure you want to approve this request?"
        closeModal={() => setShowApprovalModal(false)}
        handleConfirm={handleConfirm}
        isLoading={isConfirming}
      />
    </PageWrapper>
  );
};

export default ReviewApprovalR;
