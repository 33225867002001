const PendingStep = ({ size = 18, active = false }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none">
      <rect
        x="0.375"
        y="1.25"
        width="15.25"
        height="15.25"
        rx="7.125"
        fill={active ? "#EFF4FF" : "#FFFFFF"}
      />
      <rect
        x="0.375"
        y="1.25"
        width="15.25"
        height="15.25"
        rx="7.125"
        stroke={active ? "#1570EF": "#EAECF0"}
        strokeWidth="0.75"
      />
      <circle cx="7.875" cy="8.75" r="2.25" fill={active ? "#1570EF": "#EAECF0"} />
    </svg>
  );
};

export default PendingStep;
