export function lazyImageLoader(doc) {
  if (doc) {
    const images = doc.querySelectorAll('img');
    images.forEach(image => {
      const src = image.getAttribute('data-lazy-src');
      if (src) {
        image.setAttribute('src', src);
      }
      // remove the sibling element called source
      const source = image.previousElementSibling;
      if (source && source.tagName === 'SOURCE') {
        source.remove();
      }
    });
  }
}

export function removeModalsByClassName(doc, classNames = [], idNames = []) {
  if (doc) {
    classNames.forEach(className => {
      const elems = doc.getElementsByClassName(className);
      for (const elem of elems) {
        elem.style = 'display: none;';
        elem.classList.add('remove-modal');
      }
    });

    idNames.forEach(idName => {
      const elem = doc.getElementById(idName);
      if (elem) {
        elem.style = 'display: none;';
      }
    });
  }
}