import { useEffect, useState } from 'react';
import fullLogo from '../icons/Full.png';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import { Switch } from '@chakra-ui/react';
import { userAtom } from '../recoil/userAtoms';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { useToast } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom'; // Import useSearchParams
import { Logger } from '../utils/logger';
import Spinner from '../components/Spinner/Spinner';

// Define the plan details
const plans = [
  {
    title: 'starter',
    price: 33,
    yearlyPrice: 29,
    previousPlan: null,
    features: [
      'Unlimited approval requests',
      'Unlimited reviewers',
      '5GB storage',
      'Proof video, image, & web content',
      'Industry leading security',
      'Proofing & review tools',
    ],
    isPopular: false,
  },
  {
    title: 'premium',
    price: 44,
    yearlyPrice: 39,
    previousPlan: 'starter',
    features: [
      '50GB storage',
      'Version management',
      'Integrations',
      '5 uploads per approval',
      '3 user minimum',
      'Includes 5 workspaces',
    ],
    isPopular: false,
  },
  {
    title: 'ultimate',
    price: 53,
    yearlyPrice: 47,
    previousPlan: 'premium',
    features: [
      '500GB storage',
      'Approval workflows',
      'Analytics',
      'Approval labels',
      '5 user minimum',
      'Includes 10 workspaces',
    ],
    isPopular: true,
  },
];

function SelectPlan() {
  const [isAnnual, setIsAnnual] = useState(true);
  // const [selectedPlan, setSelectedPlan] = useState('');
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams(); // Use useSearchParams to get the current search parameters
  const planParam = searchParams.get('plan'); // Get 'plan' parameter value
  const toast = useToast();

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user?._id) {
        try {
          const response = await axios.get(`/api/auth/user/${user._id}`);

          setUser(response.data);
        } catch (error) {
          toast({
            title: 'An error occurred.',
            description: 'Unable to retrive user information.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

    fetchData();
  }, []);

  const validPlans = ['starter', 'premium', 'ultimate'];

  const filteredPlans = plans.filter((plan) => {
    if (!planParam) return true; // No plan param, show all plans
    if (!validPlans.includes(planParam.toLowerCase())) return true; // If planParam is invalid, show all plans
    return plan.title.toLowerCase() === planParam.toLowerCase(); // Filter plans based on the param
  });

  return (
    <div className="p-12 bg-gray-50 h-screen">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mx-auto justify-between text-center flex flex-row">
          <div className="flex flex-col justify-center items-start text-left px-4">
            <p className="text-primary font-bold uppercase">
              Select the right plan for your team
            </p>
            <p className="text-black font-bold text-3xl">
              Get started for free today
            </p>
          </div>
          <img
            src={fullLogo}
            alt="SwiftApprove"
            className="w-1/5 self-center"
          />
        </div>

        <div className="mb-6 px-4 w-full justify-center items-center  mb-16">
          <p className="text-center mb-1 font-semibold">
            Save 13% on yearly plans
          </p>
          <div className="justify-center flex ">
            <Switch
              isChecked={isAnnual}
              onChange={handleToggle}
              colorScheme="blue"
              size={'md'}
            />
          </div>
        </div>

        <div className="bg-gray-50">
          {/* <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 border"> */}
          {/* Other JSX elements */}
          <div className="flex flex-col justify-center w-full md:flex-row gap-4 flex-wrap">
            {filteredPlans.map((plan, index) => (
              <PricingCard
                key={index}
                plan={plan}
                user={user}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                isAnnual={isAnnual}
                planParam={planParam}
              />
            ))}
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

function PricingCard({
  plan,
  user,
  isLoading,
  setIsLoading,
  isAnnual,
  planParam,
}) {
  const toast = useToast();

  const handleTrialStart = async (plan) => {
    // Assuming 'user' is the state containing the user's information
    setIsLoading(true);
    if (!user || !user._id) {
      toast({
        title: 'Error.',
        description: 'User information is missing.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);

      return;
    }

    if (!user.emailVerified) {
      toast({
        title: 'Verify your email.',
        description:
          'Please verify your email to start the trial. We sent you a verification email.\nIf you have verified your account, you can refresh the page.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);

      return;
    }

    try {
      const response = await axios.post('/api/auth/start-trial', {
        userId: user._id,
        planTitle: plan.title.toUpperCase(), // Assuming the plan object has a title property
        isAnnual,
      });

      const sessionURL = response.data.sessionURL;
      toast({
        title: 'Success.',
        description: "You're being redirected to complete the subscription.",
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      // Redirect the user to the Stripe Checkout session
      window.location.href = sessionURL;
    } catch (error) {
      Logger.error('Error starting the trial:', error);
      toast({
        title: 'An error occurred.',
        description:
          'There was an issue starting your trial. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <div
      className={`${
        planParam ? '' : 'md:w-full'
      } rounded-xl overflow-hidden bg-white ${
        plan.isPopular ? 'border-black border-2' : ''
      } md:max-w-80 md:min-w-64`}
    >
      <div
        className={`text-center h-10 ${
          plan.isPopular && 'bg-[#0077ff]'
        } flex justify-center items-center text-white text-sm font-bold`}
      >
        {plan.isPopular ? 'Most popular 🎉' : null}
      </div>
      <div className="px-10 py-3 flex flex-col">
        <div className="text-base font-bold uppercase pb-5">{plan.title}</div>
        <div className="font-bold flex items-center pb-5">
          <span className="text-5xl">
            ${isAnnual ? plan.price : plan.yearlyPrice}
          </span>
          <span className="text-xl">/user/{isAnnual ? 'year' : 'month'}</span>
        </div>
        {plan.previousPlan && (
          <div className="text-secondary pb-2 text-sm">
            Everything in {plan.previousPlan}, plus:
          </div>
        )}
        <div>
          <ul>
            {plan.features.map((feature) => (
              <li className="flex items-baseline mb-2 text-lg">
                <IoIosCheckmarkCircle
                  className="text-primary"
                  size={18}
                  width={20}
                />
                <p className="ml-2 font-semibold text-black">{feature}</p>
              </li>
            ))}
          </ul>
        </div>
        {plan.title === 'starter' ? null : (
          <p className="text-secondary">+ $9 / workspace</p>
        )}

        <button
          className={`mt-8 mb-4 bg-primary text-white py-2 px-4 rounded-lg w-full self-center`}
          onClick={() => handleTrialStart(plan)}
          disabled={isLoading}
        >
          {isLoading ? <div className="spinner"><Spinner /></div> : 'Get 7 days free'}
        </button>
      </div>
    </div>
  );
}

export default SelectPlan;
