import React, { useEffect, useState } from 'react';
import FormRadioButton from '../Form/FormRadioButton';
import FormInput from '../Form/FormInput';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { lazyImageLoader } from '../../utils/html-utils';
import { getInputAccept } from '../../utils/upload-utils';

const DocumentUploadSelector = ({
  onFileChange,
  closeUploadModal,
  setTitle,
  setHtmlContent,
  setURL,
  addImageData,
  setPlatform,
  platform = '',
  socialFormat = '',
}) => {
  const fileUploadRef = React.createRef(null);
  const toast = useToast();

  const [selectedDocumentContentType, setSelectedDocumentContentType] =
    useState(socialFormat);
  const [selectedUploadMethod, setSelectedUploadMethod] = useState('device');

  const [stage, setStage] = useState(socialFormat ? 2 : 1);

  useEffect(() => {
    if (socialFormat) {
      setTitle(`Upload your ${socialFormat} file`);
    } else {
      setTitle('Select the type of document');
    }
  }, []);

  const handleDocumentContentTypeChange = (value) => {
    setPlatform('document', value);
    setSelectedDocumentContentType(value);
  };

  const handleUploadMethodChange = (value) => {
    setSelectedUploadMethod(value);
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (!files.length) return;

    const docFiles = files.filter((file) =>
      file.type.includes(selectedDocumentContentType)
    );

    await documentUploader(docFiles, selectedDocumentContentType);
  };

  const documentUploader = async (documentFiles, type) => {
    if (!documentFiles.length) return;

    const documentData = await Promise.all(
      documentFiles.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type,
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    documentData.forEach((imageData) => {
      addImageData(imageData);
    });

    closeUploadModal();
  };

  const handleNext = () => {
    if (stage === 1 && selectedDocumentContentType) {
      setTitle(`Upload your ${selectedDocumentContentType} file`);

      setStage(2);
      return;
    } else if (stage === 2) {
      fileUploadRef.current.click();
    }
  };

  const handlePrev = () => {
    if (stage === 2) {
      setTitle('Select the type of document');
      setStage(1);
    } else if (stage === 1) {
      closeUploadModal();
    }
  };

  return (
    <div className="flex flex-col items-center gap-2 text-sm pt-2">
      {stage === 1 && (
        <>
          <div className="flex justify-start w-full">
            <FormRadioButton
              labels={[
                {
                  label: 'PDF Files(.pdf)',
                  value: 'pdf',
                },
                {
                  label: 'DOC Files(.doc, .docx)',
                  value: 'doc',
                },
              ]}
              onClick={handleDocumentContentTypeChange}
              defaultValue={selectedDocumentContentType}
            />
          </div>
        </>
      )}

      {stage === 2 && (
        <>
          <div className="flex justify-start w-full">
            <FormRadioButton
              labels={[
                {
                  label: 'Device',
                  value: 'device',
                },
              ]}
              onClick={handleUploadMethodChange}
              defaultValue={selectedUploadMethod}
            />
          </div>
        </>
      )}

      <input
        ref={fileUploadRef}
        type="file"
        className="hidden"
        onChange={handleImageUpload}
        accept={getInputAccept(selectedDocumentContentType)}
      />

      <div className="flex justify-between w-full gap-4 mt-2">
        <button
          className="border rounded-lg py-2.5 px-5 w-full"
          onClick={handlePrev}
        >
          Cancel
        </button>
        <button
          className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
          onClick={handleNext}
        >
          {stage === 1 ? 'Confirm' : 'Upload'}{' '}
        </button>
      </div>
    </div>
  );
};

export default DocumentUploadSelector;
