import React from 'react';
import { IoClose } from 'react-icons/io5';

const CloseButton = ({ onClick, isVisible }) =>
  isVisible && (
    <div
      className="right-0 top-0 cursor-pointer z-10 absolute group-hover:visible invisible"
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '2rem',
        height: '2rem',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '50%',
        color: 'white',
        border: '1px solid white',
      }}
    >
      <IoClose size={20} />
    </div>
  );

export default CloseButton;