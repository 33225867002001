import AWS from 'aws-sdk';
import { Logger } from './logger';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRETE_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const s3 = new AWS.S3();

const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length; // Changed from const to let
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const uploadToS3 = (imagesData, user, media = 'media') => {
  // imagesData is expected to be an array of objects, each containing the base64 data and name of an image

  // The promises array will store all the upload promises
  const promises = imagesData.map((image) => {
    const file = dataURLtoBlob(image.data); // Convert base64 to Blob
    const fileSizeInMegabytes = file.size / (1024 * 1024); // Convert size to megabytes
    Logger.log('fileSizeInMegabytes ', fileSizeInMegabytes);

    const params = {
      Bucket: 'swiftapprove-1',
      Key: `${media}/${user?.firebaseAuthUUID}/${generateUUID()}`, // if you want to use the image name, ensure it's unique, or include it in the generated path
      Body: file,
      // ACL: 'public-read'
    };

    // Logger.log(image);

    // Return the promise of the S3 upload
    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve({
            url: data.Location,
            type: image.media ? image.media : image.type,
            media: image.media ? image.media : image.type,
            size: fileSizeInMegabytes,
          }); // The file's URL
        }
      });
    });
  });

  // Return a single promise that resolves when all the upload promises are resolved
  return Promise.all(promises);
};

export default uploadToS3;
