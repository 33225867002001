import AWS from 'aws-sdk';
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRETE_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});
const s3 = new AWS.S3();


const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(','), 
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
  let n = bstr.length;  // Changed from const to let
  const u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0,
        v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// ...import statements

const uploadVideoToS3 = (imagesData, user, onProgress, media = 'media') => {
  const promises = imagesData.map((image) => {
    const uuid = generateUUID();
    const key = `${media}/${user?.firebaseAuthUUID}/${uuid}`;
    const file = dataURLtoBlob(image.data);
    const fileSizeInMegabytes = file.size / (1024 * 1024); // Convert size to megabytes

    const params = {
      Bucket: 'tempinputnucket',
      Key: key,
      Body: file,
    };

    return new Promise((resolve, reject) => {
      s3.upload(params)
        .on('httpUploadProgress', (progress) => {
          if (onProgress) {
            onProgress(progress.loaded / progress.total * 100);
          }
        })
        .send((err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve({ 
              url: data.Location, 
              key, 
              type: image.media ? image.media : image.type,
              size: fileSizeInMegabytes
            }); // Return both URL and key
          }
        });
    });
  });

  return Promise.all(promises);
};

export default uploadVideoToS3;