const ChevronDown = ({ className = "stroke-gray-400" }) => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8" fill="none" className={className}>
      <path
        d="M1 1.5L6 6.5L11 1.5"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronDown;