import Icon from '../Icon';
import Plus from '../SVGs/Plus';
import searchIcon from '../../icons/search-icon.png';

const NoContent = ({ handleContentUpload }) => {
  return (
    <div className="h-[480px] w-[480px] flex flex-col justify-end radial-background relative self-center">
      <div className="w-full h-full radial-g ">
        <div className="w-full h-full flex flex-col justify-end items-center gap-8 px-8 py-8">
          <div className="flex flex-col items-center text-center gap-1">
            <h3 className="text-lg font-semibold">No content uploaded</h3>
            <div className="text-gray-600">
              Uploaded content will be displayed here.
            </div>
          </div>
          <button
            className="py-2.5 px-4 rounded-lg text-primary w-fit flex items-center justify-baseline gap-2"
            onClick={handleContentUpload}
          >
            <Plus className="stroke-primary-default" /> Upload content
          </button>
        </div>
      </div>
      <Icon
        name="search"
        alt="search"
        src={searchIcon}
        className="p-3 border rounded-lg absolute bottom-[216px] right-[216px]"
        size={12}
      />
    </div>
  );
};

export default NoContent;
