import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo",
    authDomain: "swiftapprove-a7bd6.firebaseapp.com",
    projectId: "swiftapprove-a7bd6",
    storageBucket: "swiftapprove-a7bd6.appspot.com",
    messagingSenderId: "701177215582",
    appId: "1:701177215582:web:a5dbbaa722240bf8cdd2cf",
    measurementId: "G-611KBMSFR7"
  };


export const firebaseApp = initializeApp(firebaseConfig);