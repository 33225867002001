const AddUserModal = ({
  setShowShowAddUser,
  addMemberFirstName,
  addMemberSetFirstName,
  addMemberLastName,
  addMemberSetLastName,
  addMemberEmail,
  addMemberSetEmail,
  addMemberRole,
  addMemberSetRole,
  setShowUserManagement,
  addMemberIsLoading,
  handleSendInvite,
}) => {
  return (
    <div className="modal-backdrop">
      <div className="relative flex flex-col w-full max-w-md p-6 m-4 bg-white shadow-xl rounded-2xl modal-content">
        <button
          onClick={() => setShowShowAddUser(false)}
          className="absolute top-0 right-0 mt-4 mr-4"
        >
          <svg
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="mb-4 text-center">
          <h2 className="text-2xl font-bold">Manage Users</h2>
          <p className="text-sm text-gray-500">Invite a user to your plan.</p>
        </div>

        {/* User input form */}
        <div className="mb-4 w-3/5 self-center">
          <input
            type="text"
            placeholder="First Name"
            className="mb-3 shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={addMemberFirstName}
            onChange={(e) => addMemberSetFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            className="mb-3 shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={addMemberLastName}
            onChange={(e) => addMemberSetLastName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            className="mb-3 shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={addMemberEmail}
            onChange={(e) => addMemberSetEmail(e.target.value)}
          />
          <select
            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            value={addMemberRole}
            onChange={(e) => addMemberSetRole(e.target.value)}
          >
            <option value="team">Team</option>
            <option value="admin">Admin</option>
          </select>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center mt-4 justify-center">
          <button
            className="w-1/2 bg-dashGray hover:bg-gray-200 text-primary font-semibold py-2 px-4 mx-2 rounded-xl"
            onClick={() => {
              setShowUserManagement(true);
              setShowShowAddUser(false);
            }}
          >
            Back
          </button>
          <button
            disabled={addMemberIsLoading}
            className="w-1/2 bg-primary text-white font-bold py-2 px-4 mx-2 rounded-xl hover:bg-blue-700"
            onClick={() => handleSendInvite()}
          >
            {addMemberIsLoading ? 'Sending...' : 'Send Invite'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
