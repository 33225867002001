import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import PageWrapper from '../components/PageWrapper';
import userIcon from '../icons/manage-user-icon.png';
import FormInput from '../components/Form/FormInput';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { validateEmailOrToast } from '../utils/validateUtils';
import uploadToS3 from '../utils/uploadToS3';
import axios from 'axios';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseApp } from '../firebase/firebaseConfig';
import { Logger } from '../utils/logger';

const Profile = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const history = useNavigate();

  const fileInputRef = React.createRef();

  const [activeTab, setActiveTab] = useState('profile');
  const toast = useToast();
  const [changePassword, setChangePassword] = useState({
    password: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [userProfile, setUserProfile] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    profilePicURL: user.profilePicURL,
  });

  const [imageData, setImageData] = useState([]);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);

  const handleProfileChange = (e) => {
    setUserProfile({
      ...userProfile,
      [e.target.id]: e.target.value,
    });
  };

  const addImageData = (newData) => {
    setImageData(newData);
    // setIsUploadModalOpen(false);
    setMediaIsChanged(true);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const fetchData = async () => {
    if (user?._id) {
      // Assuming you have set up an endpoint like "/api/users/:id"
      try {
        const response = await axios.get(`/api/auth/user/${user._id}`);
        setUser(response.data);
      } catch (error) {
        toast({
          title: 'An error occurred.',
          description: 'Unable to retrive user information.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleSaveChanges = async () => {
    try {
      if (!validateEmailOrToast(userProfile.email, toast)) {
        return;
      }
      // Assuming you have set up a PUT endpoint like "/api/user/update/:id"
      let imageUri = await uploadToS3(imageData, user);
      const response = await axios.post(`/api/auth/user/update/${user._id}`, {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        email: userProfile.email,
        profilePicURL: mediaIsChanged ? imageUri : userProfile.profilePicURL,
      });
      setUser(response.data);
      toast({
        title: 'Your changes have been saved.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      let description = 'Unable to save changes.';

      if (
        error.response &&
        error.response.data &&
        error.response.data.msg.includes('Email already in use')
      ) {
        description = error.response.data.msg;
      }

      toast({
        title: 'An error occurred.',
        description: description,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleRemoveImage = () => {
    setImageData([]);
    setMediaIsChanged(true);
    setUserProfile({
      ...userProfile,
      profilePicURL: null,
    });
  };

  const handlePasswordUpdate = async () => {
    try {
      if (
        !changePassword.password ||
        !changePassword.newPassword ||
        !changePassword.confirmNewPassword
      ) {
        toast({
          title: 'Please fill in all fields.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (changePassword.newPassword !== changePassword.confirmNewPassword) {
        toast({
          title: 'Passwords do not match.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const auth = getAuth(firebaseApp);
      signInWithEmailAndPassword(
        auth,
        userProfile.email,
        changePassword.password
      )
        .then(async (userCredential) => {
          var fBUser = userCredential.user;

          await axios.patch(`/api/auth/user/${user._id}/password`, {
            firebaseUUID: fBUser.uid,
            newPassword: changePassword.newPassword,
          });

          toast({
            title: 'Password changed successfully.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });

          setChangePassword({
            password: '',
            newPassword: '',
            confirmNewPassword: '',
          });
        })
        .catch((error) => {
          let description = 'Unable to change password.';
          if (error.code === 'auth/wrong-password') {
            description = 'Incorrect password';
          } else if (error.response && error.response.data) {
            description =
              error.response?.data?.message ??
              error.response?.data ??
              error.description;
          }

          toast({
            title: 'An error occurred.',
            description: description,
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      Logger.error('Password update error:', error);
    }
  };

  const handleResendVerificationEmail = async () => {
    if (user.emailVerified) {
      toast({
        title: 'Email already verified.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      await axios.get(
        `api/users/resend-verification-email?firebaseAuthUUID=${user.firebaseAuthUUID}`
      );

      toast({
        title: 'Verification Email sent',
        status: 'success',
        toastId: 'resend-verification',
      });
    } catch (error) {
      toast({
        title: 'Unable to send verification email.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePasswordChange = (e) => {
    setChangePassword({
      ...changePassword,
      [e.target.id]: e.target.value,
    });
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files); // Convert files to an array

    // Create an array of image data from the files
    const newImageData = await Promise.all(
      files.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: file.type.startsWith('image') ? 'image' : 'video',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    // Update the state with the new image data, overwriting any existing data
    addImageData(newImageData);
  };

  const reset = () => {
    setUserProfile({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      profilePicURL: user.profilePicURL,
    });

    setChangePassword({
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    });

    setMediaIsChanged(false);
  };

  const handleLogout = () => {
    setUser({});
    history('/login');
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageWrapper title="Technical workspace">
      <div className="mt-2.5 flex justify-between h-9">
        <div className="text-xl font-semibold">Profile</div>
      </div>
      <div className="flex justify-start mt-6 text-sm font-semibold">
        <div className="flex">
          <div
            className={`px-4 py-2 cursor-pointer ${
              activeTab === 'profile'
                ? 'text-gray-800 border-b border-b-primary-600 border-b-2'
                : 'text-gray-400'
            }`}
            onClick={() => {
              reset();
              setActiveTab('profile');
            }}
          >
            Profile
          </div>
          <div
            className={`px-4 py-2 cursor-pointer ${
              activeTab === 'security'
                ? 'text-gray-800 border-b border-b-primary-600 border-b-2'
                : 'text-gray-400'
            }`}
            onClick={() => {
              reset();
              setActiveTab('security');
            }}
          >
            Security
          </div>
        </div>
      </div>
      <div className="mt-9 pl-8 ">
        <div className="pb-5 border-b">
          <h4 className="text-gray-900 font-semibold text-lg">Profile</h4>
          <p className="text-gray-600 text-sm">
            Update your photo and personal details here
          </p>
        </div>

        {activeTab === 'profile' ? (
          <div className="mt-6 flex flex-col">
            <div className="flex flex-box">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">Your photo</h4>
                <p className="text-gray-600">
                  This will be displayed on your profile
                </p>
              </div>
              <div className="flex flex-row gap-4">
                <div className="w-16 h-16 border rounded-full overflow-hidden">
                  <img
                    src={
                      imageData[0]?.data ??
                      userProfile.profilePicURL ??
                      userIcon
                    }
                    alt="profile"
                    className="w-full h-full"
                  />
                </div>
                <div className="flex gap-4">
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple
                    onChange={handleImageUpload}
                    style={{ display: 'none' }}
                    accept="image/*,video/*"
                  />
                  <button
                    className="text-error-700 font-semibold"
                    onClick={handleRemoveImage}
                  >
                    Delete
                  </button>
                  <button
                    className="text-primary font-semibold"
                    onClick={triggerFileInput}
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
            <div className="flex flex-box border-b py-5 items-center">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">First name</h4>
              </div>
              <div className="flex flex-row gap-4 max-w-[350px] w-full">
                <FormInput
                  value={userProfile.firstName}
                  onChange={handleProfileChange}
                  id="firstName"
                />
              </div>
            </div>
            <div className="flex flex-box border-b py-5 items-center">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">Last name</h4>
              </div>
              <div className="flex flex-row gap-4 max-w-[350px] w-full">
                <FormInput
                  value={userProfile.lastName}
                  onChange={handleProfileChange}
                  id="firstName"
                />
              </div>
            </div>
            <div className="flex flex-box border-b py-5 items-center">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">
                  Email address
                </h4>
              </div>
              <div className="flex items-center max-w-[350px] w-full">
                <FormInput
                  value={userProfile.email}
                  onChange={handleProfileChange}
                  id="email"
                />

                {/* <EmailIcon /> {user.email} */}
              </div>
              {!user.emailVerified ? (
                <p
                  className="px-3 cursor-pointer text-xs text-error-500"
                  onClick={handleResendVerificationEmail}
                >
                  Verify email
                </p>
              ) : null}
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-box border-b py-5 items-center">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">
                  Old Password
                </h4>
              </div>
              <div className="flex flex-row gap-4 max-w-[350px] w-full">
                <FormInput
                  value={changePassword.password}
                  type={'password'}
                  placeholder={'Old password'}
                  onChange={handlePasswordChange}
                  id="password"
                />
              </div>
            </div>
            <div className="flex flex-box border-b py-5 items-center">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">
                  New Password
                </h4>
              </div>
              <div className="flex flex-row gap-4 max-w-[350px] w-full">
                <FormInput
                  value={changePassword.newPassword}
                  type={'password'}
                  placeholder={'New password'}
                  onChange={handlePasswordChange}
                  id="newPassword"
                />
              </div>
            </div>
            <div className="flex flex-box border-b py-5 items-center">
              <div className="w-72">
                <h4 className="text-gray-700 font-semibold m-0">
                  Confirm New Password
                </h4>
              </div>
              <div className="flex items-center max-w-[350px] w-full">
                <FormInput
                  value={changePassword.confirmNewPassword}
                  type={'password'}
                  placeholder={'Confirm new password'}
                  onChange={handlePasswordChange}
                  id="confirmNewPassword"
                />
              </div>
            </div>
          </div>
        )}
        <div className="mt-6 flex justify-between items-center">

        <button
            className="text-primary-600 bg-white outline py-2.5 px-5 rounded-md"
            onClick={
              () => handleLogout()
            }
          >
            Logout
          </button>
        
          <button
            className="bg-primary-600 text-white py-2.5 px-5 rounded-md"
            onClick={
              activeTab === 'profile' ? handleSaveChanges : handlePasswordUpdate
            }
          >
            {activeTab === 'profile' ? 'Save changes' : 'Update password'}
          </button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Profile;
