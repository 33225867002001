import ModalContent from './ModalContent';
import Icon from '../Icon';
import globeIcon from '../../icons/globe-icon.png';
import codeIcon from '../../icons/code-browser-icon.png';
import documentIcon from '../../icons/document-icon.png';
import imageIcon from '../../icons/image-icon.png';
import { useNavigate } from 'react-router-dom';

const CreateApprovalModal = ({ show, handleClose, workspaceID }) => {
  const history = useNavigate();

  return (
    <ModalContent
      title={'Select content type'}
      className={'max-w-[480px]'}
      closeModal={handleClose}
      openModal={show}
    >
      <div className="flex flex-col gap-2 text-sm">
        <p>Choose a suitable content type to be sent for approval.</p>
        <div className="grid grid-cols-2 justify-between w-full gap-5 pt-3">
          <div
            className="rounded-lg pb-6 pt-9 px-9 w-full flex flex-col items-center gap-2 text-center bg-dashGray cursor-pointer"
            onClick={() => {
              history(
                '/create-request?workspaceID=' +
                  workspaceID +
                  '&socialFormat=post&category=social&platform=social'
              );
            }}
          >
            <Icon
              name="social"
              alt="social"
              src={globeIcon}
              size={10}
              className=""
            />
            <h3 className="text-lg font-semibold">Social</h3>
            <p>(Facebook, Instagram, LinkedIn, Tiktok)</p>
          </div>
          <div
            className="rounded-lg pb-6 pt-9 px-9 w-full flex flex-col items-center gap-2 text-center bg-dashGray cursor-pointer"
            onClick={() => {
              history(
                '/create-request?workspaceID=' + workspaceID + '&category=web'
              );
            }}
          >
            <Icon
              name="social"
              alt="social"
              src={codeIcon}
              size={10}
              className=""
            />
            <h3 className="text-lg font-semibold">Web/HTML</h3>
            <p>(Website, Landing page, Email)</p>
          </div>
          <div
            className="rounded-lg pb-6 pt-9 px-9 w-full flex flex-col items-center gap-2 text-center bg-dashGray cursor-pointer"
            onClick={() =>
              history(
                '/create-request?workspaceID=' +
                  workspaceID +
                  '&category=document&platform=document'
              )
            }
          >
            <Icon
              name="social"
              alt="social"
              src={documentIcon}
              size={10}
              className=""
            />
            <h3 className="text-lg font-semibold">Documents</h3>
            <p>(PDF, Doc, Docx)</p>
          </div>
          <div
            className="rounded-lg pb-6 pt-9 px-9 w-full flex flex-col items-center gap-2 text-center bg-dashGray cursor-pointer"
            onClick={() =>
              history(
                '/create-request?workspaceID=' +
                  workspaceID +
                  '&socialFormat=post&category=other&platform=other'
              )
            }
          >
            <Icon
              name="social"
              alt="social"
              src={imageIcon}
              size={10}
              className=""
            />
            <h3 className="text-lg font-semibold">Other</h3>
            <p>(Designs, Graphics, Videos)</p>
          </div>
        </div>
      </div>
    </ModalContent>
  );
};

export default CreateApprovalModal;
