import axios from 'axios';
import { FaChevronRight } from 'react-icons/fa';
import { Logger } from '../../utils/logger';

const UserManagementModal = ({
  setShowModal,
  pendingInvitations,
  orgMembers,
  setShowShowAddUser,
  addMemberSetIsLoading,
  onLoadFetch,
  setShowUserProf,
  setSelectedOrgMember,
}) => {
  const deleteInvitation = async (invitationId) => {
    try {
      await axios.delete(`/api/organizations/invitation/${invitationId}`);
      // Remove the invitation from the state to update the UI
      addMemberSetIsLoading(false); // Stop loading animation
      setShowUserProf(false); // Close the profile/modal view
      onLoadFetch(); // Refresh the list of members
    } catch (error) {
      Logger.error('Error deleting invitation:', error);
    }
  };

  const updateUserProf = (member) => {
    setSelectedOrgMember(member);
    setShowModal(false);
    setShowUserProf(true);
  };

  return (
    <div className="modal-backdrop">
      <div className="relative flex flex-col w-full max-w-2xl p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-0 right-0 mt-4 mr-4"
        >
          <svg
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>

        <div className="justify-center mb-4 text-center">
          <h2 className="font-bold text-2xl">Manage Users</h2>
          <p className="text-secondary leading-5">
            Add, remove, update user permissions.
          </p>
        </div>

        {/* Users list */}
        <div
          className="overflow-y-scroll mb-4"
          style={{ maxHeight: '25vh', minHeight: '25vh' }}
        >
          {/* Repeat this div for each user row */}
          {pendingInvitations?.length > 0 && (
            <p className="font-bold text-2xl text-center">
              Pending Invitations
            </p>
          )}
          {pendingInvitations?.length > 0 &&
            pendingInvitations.map((invitation, index) => (
              <div
                key={index}
                className="flex items-center space-x-3 mb-3 bg-dashGray rounded-lg p-1"
              >
                <p className="flex-1 bg-dashGray rounded py-2 px-3 text-gray-700 leading-tight ">
                  {invitation.inviteeEmail}
                </p>
                {/* <p className="flex-1 bg-dashGray rounded py-2 px-3 text-gray-700 leading-tight ">{new Date(invitation.dateCreated).toLocaleDateString()}</p> */}
                <p className="rounded py-2 px-3 text-gray-700 bg-dashGray leading-tight">
                  {invitation?.status
                    .split(' ')
                    .map(
                      (word) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(' ')}
                </p>
                <button
                  style={{
                    border: '1px solid #be1c1c',
                    color: '#be1c1c',
                  }}
                  className="font-bold py-2 px-4 mx-2 rounded-xl hover-shadow"
                  onClick={() => deleteInvitation(invitation._id)}
                >
                  Delete
                </button>
                {/* <button className="bg-primary text-white font-bold py-2 px-4 mx-2 rounded-xl hover:bg-blue-700" onClick={() => sendReminder(invitation._id)}>Remind</button> */}
              </div>
            ))}

          {pendingInvitations?.length > 0 && orgMembers?.length > 0 && (
            <p className="font-bold text-2xl text-center">Users</p>
          )}
          {orgMembers.map((member, index) => (
            <div
              key={index}
              className="hover-shadow flex cursor-pointer space-x-3 mb-3 bg-dashGray rounded-lg p-1"
              onClick={() => updateUserProf(member)}
            >
              <p className="flex-1 bg-dashGray rounded py-2 px-3 text-gray-700 leading-tight ">
                {member?.firstName + ' ' + member?.lastName}
              </p>
              <p className="flex-1 bg-dashGray rounded py-2 px-3 text-gray-700 leading-tight ">
                {member?.email}
              </p>
              <p className="rounded py-2 px-3 text-gray-700 bg-dashGray leading-tight">
                {member?.role
                  .split(' ')
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(' ')}
              </p>
              <div className="text-right items-center flex ">
                <FaChevronRight size={12} />
              </div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex items-center mt-4 justify-center">
          <button
            className="w-1/2 bg-dashGray hover:bg-gray-200 text-primary font-semibold py-2 px-4 mx-2 rounded-xl"
            onClick={() => {
              setShowModal(false);
              setShowShowAddUser(true);
            }}
          >
            Add New User
          </button>
          <button
            className="w-1/2 bg-primary text-white font-bold py-2 px-4 mx-2 rounded-xl hover:bg-blue-700"
            onClick={() => setShowModal(false)}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserManagementModal;
