const mapStatus = (status) => {
  switch (status) {
    case 'pending':
      return 'Approval Sent';
    case 'rejected':
      return 'Rejected';
    case 'approved':
      return 'Approved';
    case 'needsRevison': // Corrected the typo from 'needsRevison' to 'needsRevision'
      return 'Feedback Received';
    case 'resolved':
      return 'Revisions Resolved';
    default:
      return 'Unknown Status';
  }
};

// Exporting the function
export default mapStatus;
