import CheckboxWithTick from '../CheckBox';

const TableCell = ({
  value,
  onSelect,
  selectId,
  allSelected,
  children,
  partial,
  isSelected,
  navigate,
  span,
}) => {
  return (
    <td
      className="px-2 flex gap-1.5 items-center min-w-10"
      style={{
        gridColumn: `span ${span || 1}`,
        cursor: onSelect || navigate ? 'pointer' : 'default',
      }}
    >
      {onSelect ? (
        <CheckboxWithTick
          onSelect={onSelect}
          value={selectId}
          allSelected={allSelected}
          partial={partial}
          isSelected={isSelected}
        />
      ) : null}
      <div onClick={navigate} className="w-full">
        {children ? (
          children
        ) : (
          <>
            <div className={navigate && 'cursor-pointer'}>{value}</div>
          </>
        )}
      </div>
    </td>
  );
};

export default TableCell;
