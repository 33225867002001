import { useEffect, useState } from 'react';

const useError = (errorKeys) => {
  const [errors, setError] = useState(
    errorKeys.reduce((acc, key) => {
      acc[key] = '';
      return acc;
    }, {})
  );

  useEffect(() => {}, [errors]);

  const addError = (key, value) => {
    setError((prev) => ({ ...prev, [key]: value }));
  };

  const removeError = (key) => {
    setError({ ...errors, [key]: '' });
  };

  const clearErrors = (keys = errorKeys) => {
    let clearedKeys = keys.reduce(
      (cleared, key) => {
        if (errors[key]) {
          cleared[key] = '';
        }

        return cleared;
      },
      { ...errors }
    );

    setError(clearedKeys);
  };

  return {
    addError,
    removeError,
    clearErrors,
    errors,
  };
};

export default useError;
