import React from 'react';

const FormTextArea = ({
  label,
  id,
  type,
  placeholder,
  error,
  hint,
  ...otherProps
}) => {
  return (
    <div className="flex flex-col gap-1.5 w-full">
      <label htmlFor={id} className="">
        {label}
      </label>
      <textarea
        className="rounded-lg py-2 px-3 w-full border shadow-sm h-28"
        id={id}
        type={type}
        placeholder={placeholder}
        {...otherProps}
      />
      {hint && (
        <p className={`text-xs ${error ? 'text-red-600' : 'text-gray-600'}`}>
          {hint}
        </p>
      )}
    </div>
  );
};

export default FormTextArea;
