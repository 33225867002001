import { useEffect } from 'react';
import PropTypes from 'prop-types';

import Comment from './Comment';
import { Logger } from '../../utils/logger';

const CommentSection = ({
  scrollableDivRef,
  comments,
  activeLabel,
  reviewerEmail,
  users,
  appReq,
  commentRefs,
  convertSecondsToTimestamp,
  deleteComment,
  imageRef,
  scrollToComment,
  selectedCommentID,
  sender,
  setCurrentSlide,
  setIsPlaying,
  setSelectedCommentID,
  toggleResolved,
  updateComment,
  user,
  setMediaIndex,
  setSelectionId,
  newComment,
}) => {
  const groupedComments = [];
  let currentGroup = [];
  const scrollToBottom = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop =
        scrollableDivRef.current.scrollHeight;
    }
  };
  const validMentions = new Set(users.map((user) => `@${user.email}`));
  const validMentionedUsers = users.reduce((acc, user) => {
    return {
      ...acc,
      [`@${user.email}`]: user,
    };
  }, {});

  useEffect(() => {
    scrollToBottom();
  }, [comments]);

  comments.forEach((comment, index) => {
    if (
      currentGroup.length === 0 ||
      currentGroup[0].sender.email === comment.sender.email
    ) {
      currentGroup.push(comment);
    } else {
      groupedComments.push(currentGroup);
      currentGroup = [comment];
    }
    if (index === comments.length - 1) {
      groupedComments.push(currentGroup);
    }
  });

  const filteredComments = comments.filter((comment) => {
    return activeLabel === 'resolved' ? comment.resolved : !comment.resolved;
  });

  const noCommentsMessage = () => {
    if (filteredComments.length === 0) {
      return activeLabel === 'resolved' ? (
        <p className="text-left text-gray-600 ml-3">
          No resolved comments yet.
        </p>
      ) : (
        <p className="text-left text-gray-600 ml-3">No comments yet.</p>
      );
    }
    return null;
  };

  return (
    <div className=" bg-white rounded-xl">
      {/* <p className='text-center font-bold text-xl'>Comments</p> */}
      <div
        ref={scrollableDivRef}
        className="no-scrollbar w-full pt-4 flex flex-col gap-3"
        style={{ overflowY: 'auto' }}
      >
        {filteredComments.length > 0
          ? filteredComments.map((comment, index) => (
              <div key={index} className="flex flex-col items-end">
                <Comment
                  comment={comment}
                  index={index}
                  reviewerEmail={reviewerEmail}
                  users={users}
                  appReq={appReq}
                  commentRefs={commentRefs}
                  convertSecondsToTimestamp={convertSecondsToTimestamp}
                  deleteComment={deleteComment}
                  // group={group}
                  imageRef={imageRef}
                  scrollToComment={scrollToComment}
                  selectedCommentID={selectedCommentID}
                  sender={sender}
                  setCurrentSlide={setCurrentSlide}
                  setIsPlaying={setIsPlaying}
                  setMediaIndex={setMediaIndex}
                  setSelectedCommentID={setSelectedCommentID}
                  toggleResolved={toggleResolved}
                  updateComment={updateComment}
                  user={user.email ? user : { email: reviewerEmail }}
                  validMentionedUsers={validMentionedUsers}
                  validMentions={validMentions}
                  setSelectionId={setSelectionId}
                />
              </div>
            ))
          : noCommentsMessage()}
        {newComment ? (
          <div className="flex flex-col items-end">
            <Comment
              comment={newComment}
              reviewerEmail={reviewerEmail}
              users={users}
              appReq={appReq}
              commentRefs={commentRefs}
              convertSecondsToTimestamp={convertSecondsToTimestamp}
              deleteComment={deleteComment}
              // group={group}
              imageRef={imageRef}
              scrollToComment={scrollToComment}
              selectedCommentID={selectedCommentID}
              sender={sender}
              setCurrentSlide={setCurrentSlide}
              setIsPlaying={setIsPlaying}
              setMediaIndex={setMediaIndex}
              setSelectedCommentID={setSelectedCommentID}
              toggleResolved={toggleResolved}
              updateComment={updateComment}
              user={user.email ? user : { email: reviewerEmail }}
              validMentionedUsers={validMentionedUsers}
              validMentions={validMentions}
              setSelectionId={setSelectionId}
            />
          </div>
        ) : null}
      </div>
      <div className="flex justify-center mt-2"></div>
    </div>
  );
};

CommentSection.propTypes = {
  apReqID: PropTypes.string,
  reviewerEmail: PropTypes.string,
};

export default CommentSection;
