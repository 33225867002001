import FormDropSelect from '../Form/FormDropSelect';
import FormInput from '../Form/FormInput';
import ModalContent from './ModalContent';

const ApprovalFilterModal = ({ show, handleClose }) => {
  return (
    <ModalContent
      title={'Filter'}
      className={'max-w-[480px] w-full'}
      closeModal={handleClose}
      openModal={show}
    >
      <div className="flex flex-col gap-2 text-sm w-full gap-6">
        <p>Select filters, date ranges, and formats</p>

        <FormDropSelect
          label="Approval Status"
          placeholder="Select approval status"
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'pending',
              label: 'Pending',
            },
            {
              value: 'approved',
              label: 'Approved',
            },
            {
              value: 'rejected',
              label: 'Rejected',
            },
          ]}
        />
        <FormDropSelect
          label="Overdue"
          placeholder="Select overdue"
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'pending',
              label: 'Pending',
            },
            {
              value: 'approved',
              label: 'Approved',
            },
            {
              value: 'rejected',
              label: 'Rejected',
            },
          ]}
        />
        <FormDropSelect
          label="Label"
          placeholder="Select label"
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'pending',
              label: 'Pending',
            },
            {
              value: 'approved',
              label: 'Approved',
            },
            {
              value: 'rejected',
              label: 'Rejected',
            },
          ]}
        />
        <FormInput
          label="Creation Date"
          type="date"
          placeholder="Select date"
        />
        <FormInput label="Deadline" type="date" placeholder="Select date" />
        <div className="flex justify-between w-full gap-4 mt-8 mb-4">
          <button className="bg-primary text-white rounded-lg py-2.5 px-5 w-full">
            Filter
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default ApprovalFilterModal;
