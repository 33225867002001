import { useEffect, useRef, useState } from 'react';
import Check from './SVGs/Check';
import sortIcon from '../icons/switch-vertical.png';
import Icon from './Icon';

const DropDownSort = ({ sortOrder, setSortOrder }) => {
  const dropSelectRef = useRef(null);
  const [toggleSortView, setToggleSortView] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        toggleSortView &&
        dropSelectRef?.current &&
        !dropSelectRef?.current.contains(event.target) &&
        !event.target.classList.value.includes('sort-drop-select')
      ) {
        setToggleSortView(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [toggleSortView, dropSelectRef]);

  return (
    <div
      className="border rounded-lg flex gap-2 py-2.5 px-4 relative cursor-pointer"
      onClick={() => {
        setToggleSortView(!toggleSortView);
      }}
      ref={dropSelectRef}
    >
      <div className="flex  gap-2 items-center">
        <Icon alt="sort" src={sortIcon} size={5} /> Sort{' '}
      </div>
      {toggleSortView ? (
        <div
          className={
            'absolute top-full py-1.5 px-2 flex flex-col border mt-2 right-0 rounded bg-white z-20 gap-1 justify-stretch'
          }
        >
          <div
            className="flex text-nowrap gap-4 p-2.5 justify-between rounded bg-gray-100 cursor-pointer"
            onClick={() => {
              setSortOrder('asc');
            }}
          >
            <p>Ascending order </p>
            <Check
              classNames={sortOrder === 'asc' ? 'stroke-primary-600' : 'none'}
            />
          </div>
          <div
            className="flex text-nowrap gap-4 p-2.5 justify-between rounded bg-gray-100 cursor-pointer"
            onClick={() => {
              setSortOrder('desc');
            }}
          >
            <p>Descending order </p>
            <Check
              classNames={sortOrder === 'desc' ? 'stroke-primary-600' : 'none'}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DropDownSort;
