import ModalContent from './ModalContent';

const SendReminderModal = ({
  closeModal,
  isOpen = true,
  handleSendReminder,
  isSendingReminder,
  pendingApprovers = [],
}) => {
  const closeReminderModal = () => {
    !isSendingReminder && closeModal();
  };

  return (
    <ModalContent
      title={'Send Reminder'}
      className={'max-w-[480px] w-full'}
      closeModal={closeReminderModal}
      openModal={isOpen}
    >
      <div className="flex flex-col items-center gap-2 text-sm pt-2">
        <p className="flex justify-start w-full">
          Are you sure you want to send a reminder to these approvers?
        </p>
        <div className="flex justify-start w-full gap-3 font-semibold">
          {pendingApprovers.join(', ')}
        </div>

        <div className="flex justify-between w-full gap-4 mt-2">
          <button
            className="border rounded-lg py-2.5 px-5 w-full"
            onClick={closeReminderModal}
          >
            Cancel
          </button>
          <button
            className="bg-primary text-white rounded-lg py-2.5 px-5 w-full"
            onClick={handleSendReminder}
          >
            Confirm
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default SendReminderModal;
