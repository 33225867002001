const DotIcon = ({ className = 'fill-success-500' }) => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      className={className}
    >
      <circle cx="4.88037" cy="4" r="3" />
    </svg>
  );
};

export default DotIcon;
