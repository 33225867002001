// VideoPlayer.js
import React, { useState, useRef, useEffect } from 'react';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaExpand, FaCompress, FaComment} from 'react-icons/fa';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeComments, setActiveComments] = useState([]);

  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });


  useEffect(() => {
    if (videoRef.current) {
      setVideoDimensions({
        width: videoRef.current.offsetWidth,
        height: videoRef.current.offsetHeight
      });
    }
  }, [videoRef.current?.offsetWidth, videoRef.current?.offsetHeight]);

  
    const comments = [
        {
            "_id": "655aadabfe2ba519a6a280d5",
            "sender": {
                "email": "tuzoegbut@gmail.com",
                "_id": "655aadabfe2ba519a6a280d6"
            },
            "comment": "parents still",
            "resolved": true,
            "approvalRequestID": "655aad4afe2ba519a6a28044",
            "approvalRequestTimeliID": "655aad4afe2ba519a6a28049",
            "coords": {
                "videoTimestamp": 1.66992,
                'x':0.7856609451147748,
                'y':0.6504653554970938
            },
            "mediaIndex": 1,
            "dateCreated": "2023-11-20T00:51:55.619Z",
            "lastUpdated": "2023-11-20T00:51:55.619Z",
            "__v": 0,
            "bg": "#e7f1f3"
        },
        {
          "_id": "655aadabfe2ba519a6a280d4",
          "sender": {
              "email": "tuzoegbut@gmail.com",
              "_id": "655aadabfe2ba519a6a280d6"
          },
          "comment": "parents still",
          "resolved": true,
          "approvalRequestID": "655aad4afe2ba519a6a28044",
          "approvalRequestTimeliID": "655aad4afe2ba519a6a28049",
          "coords": {
              "videoTimestamp": 71.66992,
              'x':0.7856609451147748,
              'y':0.6504653554970938
              
          },
          "mediaIndex": 1,
          "dateCreated": "2023-11-20T00:51:55.619Z",
          "lastUpdated": "2023-11-20T00:51:55.619Z",
          "__v": 0,
          "bg": "#e7f1f3"
      },
        
    ]

  useEffect(() => {
    setDuration(videoRef?.current?.duration);
    videoRef.current.volume = volume;
  }, [volume]);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

 

  const handleVolumeChange = (e) => {
    setVolume(e.target.value);
    videoRef.current.volume = e.target.value;
    setIsMuted(e.target.value === 0);
  };

  const toggleMute = () => {
    const video = videoRef.current;
    if (video.volume > 0) {
      setVolume(0);
      video.volume = 0;
      setIsMuted(true);
    } else {
      setVolume(0.5); // Or restore the previous volume
      video.volume = 0.5;
      setIsMuted(false);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      videoRef.current.parentElement.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  const formatTime = (seconds) => {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, '0');
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  const handleProgressChange = (e) => {
    const video = videoRef.current;
    const time = (e.target.value * video?.duration) / 100;
    video.currentTime = time;
  };

  const getProgress = () => {
    const video = videoRef.current;
    if (!video?.duration) return 0;
    return (video.currentTime / video?.duration) * 100;
  };
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    setCurrentTime(currentTime);

    // Filter comments that should be active based on the current time
    const active = comments.filter(comment => {
      return currentTime >= comment.coords.videoTimestamp - 3 &&
             currentTime <= comment.coords.videoTimestamp + 3;
    });

    setActiveComments(active.map(comment => ({
      ...comment,
      x: comment.coords.x * videoDimensions.width,
      y: comment.coords.y * videoDimensions.height
    })));
  };

  // Calculate the position of a comment on the progress bar
  const calculateCommentPosition = (timestamp) => {
    const video = videoRef.current;
    if (!video?.duration) return 0;
    const position = (timestamp / video.duration) * 100;
    return `${position}%`;
  };

  const handleCommentClick = (timestamp) => {
    if (videoRef.current) {
      videoRef.current.currentTime = timestamp;
    }
  };

 

  // The JSX below includes TailwindCSS classes
  return (
    <div className="relative bg-black w-full max-w-xl mx-auto">
        <div className="absolute bottom-full mb-4 left-0 right-0">
        {activeComments.map(comment => {
          return (
            <FaComment 
              key={comment._id} 
              className={`absolute transition duration-300 transform hover:scale-150 ${comment.isActive ? 'text-primary' : 'text-primary'}`} 
              style={{ left: comment.x, top: comment.y }} 
              onClick={() => handleCommentClick(comment.coords.videoTimestamp)}
            />
          );
        })}
      </div>
      <video
        ref={videoRef}
        src={src}
        className="w-full cursor-pointer"
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={() => setDuration(videoRef.current.duration)}
        onClick={togglePlayPause}
      />
      <div className="relat bottom-0 w-full flex justify-between items-center px-4 py-2 bg-gray-800 bg-opacity-75">
        <button onClick={togglePlayPause} className="focus:outline-none text-white">
          {isPlaying ? <FaPause /> : <FaPlay />}
        </button>
        <div className="relative w-1/2">
        {comments.map(comment => {
            const isActive = currentTime >= comment.coords.videoTimestamp - 3 &&
                            currentTime <= comment.coords.videoTimestamp + 3;
            const commentClasses = isActive ? "text-primary" : "text-white";

            return (
              <FaComment 
                key={comment._id} 
                className={`absolute cursor-pointer bottom-8 transition duration-300 transform hover:scale-150 ${commentClasses}`} 
                style={{ left: calculateCommentPosition(comment.coords.videoTimestamp) }} 
                onClick={() => handleCommentClick(comment.coords.videoTimestamp)}
              />
            );
          })}
          <input
            type="range"
            min="0"
            max="100"
            value={getProgress()}
            onChange={handleProgressChange}
            className="w-full custom-range"
          />
        </div>
      
        <button onClick={toggleFullscreen} className="focus:outline-none text-white">
          {isFullscreen ? <FaCompress /> : <FaExpand />}
        </button>
        <div className="text-white">
          <span>{formatTime(currentTime)}</span> / <span>{formatTime(duration)}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
