const CompletedStep = ({ size = 18, className = "" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <rect
        x="0.56875"
        y="0.44375"
        width="17.1125"
        height="17.1125"
        rx="8.43125"
        stroke="#1570EF"
        strokeWidth="0.8875"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9475 5.54253L7.5775 10.725L6.1525 9.20253C5.89 8.95503 5.4775 8.94003 5.1775 9.15003C4.885 9.36753 4.8025 9.75003 4.9825 10.0575L6.67 12.8025C6.835 13.0575 7.12 13.215 7.4425 13.215C7.75 13.215 8.0425 13.0575 8.2075 12.8025C8.4775 12.45 13.63 6.30753 13.63 6.30753C14.305 5.61753 13.4875 5.01003 12.9475 5.53503V5.54253Z"
        fill="#1570EF"
      />
    </svg>
  );
};

export default CompletedStep;
