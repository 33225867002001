import { useCallback, useEffect, useState } from 'react';
import { Logger } from '../utils/logger';

const useIframe = () => {
  /**
   * The sole purpose of this suite of code is to gain access to Iframe document
   * You can add more to it depending on your use case
   */
  const [comment, setComment] = useState(false);

  // useEffect(() => {
  //   Logger.log('Comment updated', comment);
  // }, [comment]);

  const handleComment = useCallback((bool) => {
    setComment(bool);
  }, []);

  const scrubIframeDoc = function (e) {
    // disable pointer events
    // Logger.log("Comment is updated here", comment, e, e.target, e.target.document)

    const doc =
      e.target?.contentDocument ??
      e.target?.document ??
      e.contentDocument ??
      e.contentWindow?.document;

    // disable pointer events
    if (e?.target?.style?.pointerEvents) {
      e.target.style.pointerEvents = '';
    }

    if (doc) {
      doc.body.style.overflowY = 'unset';

      doc.querySelectorAll('a').forEach((tag) => {
        if (tag.href && !tag.href.startsWith('/')) {
          tag.href = '';
          tag.removeAttribute('href');
        }
        if (tag.href && !tag.href.startsWith('/')) {
          tag.href = '';
          tag.removeAttribute('href');
        }
        if (tag.href && tag.href.startsWith('./')) {
          tag.href = '';
          tag.removeAttribute('href');
        }
        if (tag.href && tag.href.startsWith('../')) {
          tag.href = '';
          tag.removeAttribute('href');
        }
        if (tag.href && !tag.href.startsWith('#')) {
          tag.href = '';
        }

        tag.removeAttribute('href');
        tag.removeAttribute('target');
        tag.disabled = true;
      });
      doc.querySelectorAll('input').forEach((tag) => {
        if (tag.type && tag.type.startsWith('submit')) {
          tag.disabled = true;
        }
      });
      doc.querySelectorAll('button').forEach((tag) => {
        if (tag.type && tag.type.startsWith('submit')) {
          tag.disabled = true;
        }
      });

      let customModals = ['elementor-popup-modal', 'subscribe-modal-wrapper'];

      customModals.forEach((modal) => {
        let elems = doc.getElementsByClassName(modal);

        for (const elem of elems) {
          Logger.log(elem);
          elem.style = 'display: none;';
          elem.classList.add('remove-modal');
        }
      });
    }
  };

  return {
    handleComment,
    comment,
    scrubIframeDoc,
  };
};

export default useIframe;
