/* global Dropbox */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import Sidebar from '../components/Sidebar';
import contactUs from '../icons/contactUs.png';
import GooglePicker from 'react-google-picker';
import billingIcon from '../icons/billingIcon.png';
import { useToast } from '@chakra-ui/react';
import profileIcon from '../icons/profileIcon.png';
import notifPrefIcon from '../icons/notifPrefIcon.png';
import manageUsersIcon from '../icons/manageUsersIcon.png';
import { FaChevronRight } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import { validateEmailOrToast } from '../utils/validateUtils';
import SettingsItem from '../components/SettingsItem';
import ProfileModal from '../components/Modal/ProfileModal';
import PreferenceModal from '../components/Modal/PreferenceModal';
import UserManagementModal from '../components/Modal/UserManagementModal';
import AddUserModal from '../components/Modal/AddUserModel';
import UserProfileModal from '../components/Modal/UserProfileModal';
import BillingModal from '../components/Modal/BillingModal';
import ContactUsModal from '../components/Modal/ContactUsModal';
import IntegrationModal from '../components/Modal/IntegrationModal';
import { Logger } from '../utils/logger';
import PageWrapper from '../components/PageWrapper';


const Settings = () => {
  const [user, setUser] = useRecoilState(userAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [profilePicURL, setProfilePicURL] = useState('');
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [mediaIsChanged, setMediaIsChanged] = useState(false);
  const fileInputRef = React.createRef();
  const toast = useToast();
  const [reminders, setReminders] = useState([
    { id: 1, days: 1, hours: 0 }, // Default for reminder 1
    { id: 2, days: 2, hours: 0 }, // Default for reminder 2
  ]);
  const [orgMembers, setOrgMembers] = useState([]);
  const [pendingInvitations, setPendingInvitations] = useState([]);
  const ownerId = user?._id;

  const location = useLocation();
  const [isUpgrade, setIsUpgrade] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const open = params.get('open');
    const upgrade = params.get('upgrade');

    if (open === 'contact-us') {
      const contactUsElement = document.getElementById('contactUs');
      if (contactUsElement) {
        contactUsElement.click();
      }
    }else if (open === 'manage-users'){
      const manageUsersElement = document.getElementById('manage-users');
      if (manageUsersElement) {
        manageUsersElement.click();
      }
    }
    else if (open === 'integrations'){
      const integrationsElement = document.getElementById('integrations');
      if (integrationsElement) {
        integrationsElement.click();
      }
    }
    if (upgrade === 'true') {
      const billingElement = document.getElementById('billing');
      if (billingElement) {
        billingElement.click();
      }
    }
  }, [location]);

  // New state for contact form
  const [showUserManagement, setShowUserManagement] = useState(false);
  const [showAddUser, setShowShowAddUser] = useState(false);
  const [showUserProf, setShowUserProf] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [addMemberFirstName, addMemberSetFirstName] = useState('');
  const [addMemberLastName, addMemberSetLastName] = useState('');
  const [addMemberEmail, addMemberSetEmail] = useState('');
  const [addMemberRole, addMemberSetRole] = useState('team');
  const [addMemberIsLoading, addMemberSetIsLoading] = useState(false);

  const [selectedOrgMember, setSelectedOrgMember] = useState(null);

  const addImageData = (newData) => {
    setImageData(newData);
    setIsUploadModalOpen(false);
    setMediaIsChanged(true);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const fetchData = async () => {
    if (user?._id) {
      // Assuming you have set up an endpoint like "/api/users/:id"
      try {
        const response = await axios.get(`/api/auth/user/${user._id}`);
        setFirstName(response.data.firstName);
        setLastName(response.data.lastName);
        setEmail(response.data.email);
        setReminders(
          response.data?.notificationPreferences
            ? response.data?.notificationPreferences
            : reminders
        );
        setProfilePicURL(response.data?.profilePicURL);
        setUser(response.data);
      } catch (error) {
        toast({
          title: 'An error occurred.',
          description: 'Unable to retrive user information.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const fetchMembers = async () => {
    try {
      const response = await axios.get(`/api/organizations/members/${ownerId}`);
      setOrgMembers(response.data.members);
    } catch (error) {
      Logger.error('Error fetching organization members:', error);
    }
  };

  const fetchPendingInvitations = async () => {
    try {
      const response = await axios.get(
        `/api/organizations/pending-invitations-by-owner/${ownerId}`
      );
      setPendingInvitations(response.data);
    } catch (error) {
      Logger.error('Error fetching organization members:', error);
    }
  };

  const onLoadFetch = () => {
    fetchData();
    fetchMembers();
    fetchPendingInvitations();
  };

  useEffect(() => {
    onLoadFetch();
  }, []);

  const handleProfilePicChange = () => {};

  const openDropboxChooser = () => {
    Dropbox.choose({
      success: function (files) {
        // 'files' is an array of file objects. We'll map over this array to extract the links.
        const fileLinks = files.map((file) => file.link);
        // Pass the array of links to the handler
        handleDropboxFiles(fileLinks);
      },
      linkType: 'direct', // "direct" gives a direct link to the file, which we can convert to a blob
      multiselect: true, // Allow multiple files to be selected
      extensions: ['.jpg', '.png'],
    });
  };

  const loadDropboxSDK = (callback) => {
    const script = document.createElement('script');
    script.src = 'https://www.dropbox.com/static/api/2/dropins.js';
    script.id = 'dropboxjs';
    script.dataset.appKey = 'YOUR_APP_KEY'; // Ensure to replace with your actual App key
    script.onload = callback;
    document.body.appendChild(script);
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files); // Convert files to an array

    // Create an array of image data from the files
    const newImageData = await Promise.all(
      files.map(async (file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: file.type.startsWith('image') ? 'image' : 'video',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    // Update the state with the new image data, overwriting any existing data
    addImageData(newImageData);
  };

  const handleDropboxFiles = async (fileLinks) => {
    // Map over the fileLinks to create an array of fetch requests
    const fetchPromises = fileLinks.map((fileLink) => fetch(fileLink));

    // Use Promise.all to wait for all of them
    const responses = await Promise.all(fetchPromises);

    // Convert responses to blobs
    const blobs = await Promise.all(
      responses.map((response) => response.blob())
    );

    // Create a new array of image data from the blobs
    const newImageData = await Promise.all(
      blobs.map(async (blob, index) => {
        // Consider extracting the actual filename and MIME type from the Dropbox response
        const file = new File([blob], `filename${index}`, { type: blob.type });

        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            resolve({
              name: file.name,
              data: reader.result,
              type: file.type.startsWith('image') ? 'image' : 'video',
            });
          };

          reader.readAsDataURL(file);
        });
      })
    );

    // Update the state with the new image data, overwriting any existing data
    addImageData(newImageData);
  };

  const UploadModal = ({ onClose }) => {
    return (
      <div className="modal-backdrop">
        <div className="flex w-full max-w-sm p-8 pb-3 m-4 bg-white shadow-top-prominent rounded-3xl modal-content h-3/8">
          <div className="flex flex-col w-full">
            <p className="text-xl font-bold text-center mb-4">
              Select your upload method
            </p>
            <div className="flex flex-col justify-center">
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                multiple
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                accept="image/*,video/*"
              />
              {/* Button to trigger file input */}
              <button
                className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
                onClick={triggerFileInput}
              >
                Device
              </button>

              <button
                className="bg-primary text-white font-bold hover-shadow px-3 rounded-lg my-2 py-3 "
                onClick={() => {
                  loadDropboxSDK();
                  openDropboxChooser();
                }}
              >
                Dropbox
              </button>

              <GooglePicker
                clientId={
                  '701177215582-m10tn0hun38pudm6aqdrp4asei35pm1q.apps.googleusercontent.com'
                }
                developerKey={'AIzaSyCBGHYJMUeVOAlZEf9AeYjD7ULZIVNS-jo'}
                scope={['https://www.googleapis.com/auth/drive']}
                onChange={(data) => Logger.log('on change:', data)}
                onAuthFailed={(data) => Logger.log('on auth failed:', data)}
                multiselect={true}
                signInFlow="redirect"
                navHidden={true}
                authImmediate={false}
                mimeTypes={['image/png', 'image/jpeg']}
                viewId={'DOCS_IMAGES'}
              >
                <button className="bg-primary text-white cursor-pointer font-bold hover-shadow px-3 rounded-lg my-2 py-3 w-full">
                  Google Drive
                </button>
              </GooglePicker>
            </div>
            <span
              className="close text-primary text-center mt-4 cursor-pointer"
              onClick={onClose}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    );
  };

  const handleSendInvite = async () => {
    addMemberSetIsLoading(true); // Start loading animation
    if (!validateEmailOrToast(addMemberEmail, toast)) {
      addMemberSetIsLoading(false); // Stop loading animation
      return;
    }
    try {
      const response = await axios.post('/api/organizations/invite', {
        email: addMemberEmail,
        role: addMemberRole,
        userID: user?._id, // This should be dynamically set based on the logged-in user
      });
      toast({
        title: 'Invitation Sent',
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response.data.message || 'Failed to send invitation',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    addMemberSetIsLoading(false); // Stop loading animation
    setShowShowAddUser(false);
    onLoadFetch();
  };

  const handleSaveMember = async () => {
    // Add loading logic here if needed
    addMemberSetIsLoading(true); // Start loading animation
    if (!validateEmailOrToast(selectedOrgMember.email, toast)) {
      addMemberSetIsLoading(false); // Stop loading animation
      return;
    }
    try {
      const response = await axios.post('/api/organizations/updateMember', {
        userId: user?._id, // This should be dynamically set based on the logged-in user
        firstName: selectedOrgMember.firstName,
        lastName: selectedOrgMember.lastName,
        email: selectedOrgMember.email,
        role: selectedOrgMember.role,
      });
      toast({
        title: 'User updated',
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response.data.message || 'Failed to send invitation',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    // Add loading logic here if needed
    addMemberSetIsLoading(false); // Stop loading animation
    setShowUserProf(false);
    onLoadFetch();
  };

  const handleRemoveMember = async () => {
    addMemberSetIsLoading(true); // Start loading animation for UI feedback
    if (!validateEmailOrToast(selectedOrgMember.email, toast)) {
      setShowUserProf(false); // Close the profile/modal view
      return;
    }
    try {
      const response = await axios.post('/api/organizations/removeMember', {
        userId: user?._id, // Dynamically set based on the logged-in user
        memberEmail: selectedOrgMember.email, // Email of the member to remove
      });
      toast({
        title: 'Member removed',
        description: response.data.message,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to remove member',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    addMemberSetIsLoading(false); // Stop loading animation
    setShowUserProf(false); // Close the profile/modal view
    onLoadFetch(); // Refresh the list of members
  };

  const handleFirstNameChange = (e) => {
    setSelectedOrgMember((prev) => ({
      ...prev,
      firstName: e.target.value,
    }));
  };

  const handleLastNameChange = (e) => {
    setSelectedOrgMember((prev) => ({
      ...prev,
      lastName: e.target.value,
    }));
  };

  const handleRoleChange = (e) => {
    setSelectedOrgMember((prev) => ({
      ...prev,
      role: e.target.value,
    }));
  };

  return (
    <PageWrapper>
      <div className="flex bg-white">

        <div className="flex-1">
          <div className="flex flex-col">
            <div className="flex flex-row justify-between">
              <p className="font-bold text-3xl">Settings</p>
            </div>

            {isLoading ? (
              <div className="blackSpinner"></div>
            ) : (
              <>
                <div className="">
                  <SettingsItem
                    Icon={FaChevronRight}
                    profileIcon={profileIcon}
                    title={'Edit profile'}
                  >
                    <ProfileModal
                      profileIcon={profileIcon}
                      profilePicURL={profilePicURL}
                      mediaIsChanged={mediaIsChanged}
                      imageData={imageData}
                      handleProfilePicChange={handleProfilePicChange}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      setEmail={setEmail}
                      setIsUploadModalOpen={setIsUploadModalOpen}
                      setFirstName={setFirstName}
                      setLastName={setLastName}
                      user={user}
                      fetchData={fetchData}
                      FaChevronRight={FaChevronRight}
                      title={'Edit profile'}
                    />
                  </SettingsItem>

                  <SettingsItem
                    Icon={FaChevronRight}
                    profileIcon={notifPrefIcon}
                    title={'Approver Notification Preferences'}
                  >
                    <PreferenceModal
                      user={user}
                      fetchData={fetchData}
                      reminders={reminders}
                      setReminders={setReminders}
                    />
                  </SettingsItem>

                  <SettingsItem
                    Icon={FaChevronRight}
                    profileIcon={manageUsersIcon}
                    title={'Manage Users'}
                    setCustomShowModal={setShowUserManagement}
                    customShowModal={showUserManagement}
                    id={'manage-users'}
                  >
                    <UserManagementModal
                      pendingInvitations={pendingInvitations}
                      orgMembers={orgMembers}
                      setShowShowAddUser={setShowShowAddUser}
                      addMemberSetIsLoading={addMemberSetIsLoading}
                      onLoadFetch={onLoadFetch}
                      setShowUserProf={setShowUserProf}
                      setSelectedOrgMember={setSelectedOrgMember}
                    />
                  </SettingsItem>
                  <SettingsItem
                    Icon={FaChevronRight}
                    profileIcon={contactUs}
                    title={'Billing'}
                    id={'billing'}

                  >
                    <BillingModal user={user} />
                  </SettingsItem>
                  <SettingsItem
                    Icon={FaChevronRight}
                    profileIcon={billingIcon}
                    title={'Contact Us'}
                    id={'contactUs'}
                  >
                    <ContactUsModal
                      user={user}
                      firstName={firstName}
                      lastName={lastName}
                      message={message}
                      setFirstName={setFirstName}
                      setLastName={setLastName}
                      setMessage={setMessage}
                      setSubject={setSubject}
                      subject={subject}
                    />
                  </SettingsItem>
                  <SettingsItem
                    Icon={FaChevronRight}
                    /* TODO: get icon for Integration */
                    profileIcon={notifPrefIcon}
                    title={'Integrations'}
                    id={'integrations'}

                  >
                    <IntegrationModal user={user} />
                  </SettingsItem>

                  {isUploadModalOpen && (
                    <UploadModal
                      onClose={() => setIsUploadModalOpen(false)}
                      onFileSelect={handleImageUpload}
                      onDropboxFileSelect={handleDropboxFiles}
                    />
                  )}

                  {showAddUser && (
                    <AddUserModal
                      addMemberEmail={addMemberEmail}
                      addMemberFirstName={addMemberFirstName}
                      addMemberIsLoading={addMemberIsLoading}
                      addMemberLastName={addMemberLastName}
                      addMemberRole={addMemberRole}
                      addMemberSetEmail={addMemberSetEmail}
                      addMemberSetFirstName={addMemberSetFirstName}
                      addMemberSetLastName={addMemberSetLastName}
                      addMemberSetRole={addMemberSetRole}
                      handleSendInvite={handleSendInvite}
                      setShowShowAddUser={setShowShowAddUser}
                      setShowUserManagement={setShowUserManagement}
                    />
                  )}

                  {showUserProf && (
                    <UserProfileModal
                      addMemberIsLoading={addMemberIsLoading}
                      handleFirstNameChange={handleFirstNameChange}
                      handleLastNameChange={handleLastNameChange}
                      handleRemoveMember={handleRemoveMember}
                      handleRoleChange={handleRoleChange}
                      handleSaveMember={handleSaveMember}
                      selectedOrgMember={selectedOrgMember}
                      setShowUserManagement={setShowUserManagement}
                      setShowUserProf={setShowUserProf}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Settings;
