// - [x]  if starter:
//     - [x]  only 1 piece of content per approval request
//     - [x]  up to 50 gb storage
//     - [x]  no editing request, must create new request
//     - [x]  1 workspace

// - [ ]  if premium
//     - [x]  up to 5 pieces of content per approval request
//     - [ ]  3 user minimum?
//     - [x]  up to 5 workspaces for free
//         - [x]  additional $9 per new workspace
// - [ ]  if ultimate
//     - [x]  500 GB Storage
//     - [ ]  5 user minimum?
//     - [x]  up to 10 workspaces for free
//         - [x]  additional $9 per new workspace

import priceToPlan from "./priceIdToPlan"

const planValidationNewAppReq = (user, imData) => {
    // validation for starter
    if(priceToPlan[user?.plan]?.title == 'STARTER'){
        // check for only one piece of content
        if(imData[0].length > 1){
            return {valid: false, msg: "Users on Starter Plan are limited to 1 media per Approval Request. Upgrade to Premium to send up to 5."}
        }
        // check for storage
        if(imData[1] + user?.storageUsed > (5 * 1024)){
            return {valid: false, msg: `Account storage at ${((user?.storageUsed + imData[1]) / 1024).toFixed(2)  } GB. Users on Starter Plan are limited to 5 GB of storage. Upgrade to Premium to instantly gain access to up to 50 GB.`}
        }
    }else if(priceToPlan[user?.plan]?.title == 'PREMIUM'){
        // check for no more than 5 pieces of content
        if(imData[0].length > 5){
            return {valid: false, msg: "Users on Premium Plan are limited to up to 5 media per Approval Request. Contact sales to upgrade your capacity."}
        }
         // check for storage
         if(imData[1] + user?.storageUsed > (50 * 1024)){
            return {valid: false, msg: `Account storage at ${((user?.storageUsed + imData[1]) / 1024).toFixed(2)  } GB. Users on Premium Plan are limited to 50 GB of storage. Upgrade to Ultimate to instantly gain access to up to 500 GB.`}
        }

    }else if(priceToPlan[user?.plan]?.title == 'ULTIMATE'){
        // check for no more than 5 pieces of content
        if(imData[0].length > 5){
            return {valid: false, msg: "Users on Ultimate Plan are limited to up to 5 media per Approval Request. Contact sales to upgrade your capacity."}
        }
         // check for storage
         if(imData[1] + user?.storageUsed > (500 * 1024)){
            return {valid: false, msg: `Account storage at ${((user?.storageUsed + imData[1]) / 1024).toFixed(2)  } GB. Users on Ultimate Plan are limited to 500 GB of storage. Contact sales to upgrade your capacity.`}
        }
    }

    return {valid: true}

} 

const planValidationEditAppReq = (user) => {
    // validation for starter
    if(priceToPlan[user?.plan]?.title == 'STARTER'){
        // check edit
        return {valid: false, msg: "Users on Starter Plan do not have access to version control. Upgrade to Premium to send multiple Approval Requests in one timeline, with comments and version history."}
    }
    return {valid: true}

} 


const planValidationNewWorkspace = (user, workspaces) => {
    // validation for starter
    if(priceToPlan[user?.plan]?.title == 'STARTER' && workspaces.length >= 1){
        return {valid: false, msg: "Users on Starter Plan are limited to 1 workspace. Upgrade to Premium to instantly gain access to up to 5 workspaces to organize your requests."}
    }else if(priceToPlan[user?.plan]?.title == 'PREMIUM' && workspaces.length >=  5){
        return {valid: false, msg: "Users on Premium Plan are limited to 5 free workspaces. Would you like to add an additional workspace for $9/mo?"}
    }else if(priceToPlan[user?.plan]?.title == 'ULTIMATE' && workspaces.length >=  10){
        return {valid: false, msg: "Users on Ulimate Plan are limited to 10 free workspaces. Would you like to add an additional workspace for $9/mo?"}
    }
    return {valid: true}

} 

export { planValidationNewAppReq, planValidationEditAppReq, planValidationNewWorkspace };
