import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { Logger } from '../../utils/logger';

const BillingModal = ({ setShowModal, user }) => {
  const toast = useToast();

  const handleOpenStripePortal = async () => {
    try {
      const { data } = await axios.post(
        '/api/auth/create-stripe-portal-session',
        { userId: user._id }
      );
      window.location.href = data.url;
    } catch (error) {
      Logger.error('Error opening Stripe portal:', error);
      toast({
        title: 'Error',
        description: 'Error opening Stripe portal',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="relative flex flex-col w-full max-w-md p-6 m-4 bg-white shadow-xl rounded-2xl modal-content">
        <button
          onClick={() => setShowModal(false)}
          className="absolute top-0 right-0 mt-4 mr-4"
        >
          <svg
            className="h-6 w-6 text-gray-800"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <div className="mb-4 text-center">
          <h2 className="text-2xl font-bold">Billing</h2>
          <p className="text-sm text-gray-500">
            Update your billing preferences
          </p>
        </div>

        <button
          className="w-full mt-4 text-lg px-4 py-2 font-bold text-white hover-shadow bg-primary rounded-lg focus:outline-none focus:shadow-outline-blue active:bg-blue-800"
          type="button"
          onClick={() => handleOpenStripePortal()}
        >
          Manage Billing
        </button>
      </div>
    </div>
  );
};

export default BillingModal;
