import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();

export const workspacesAtom = atom({
  key: 'workspaces',
  default: [],
  effects_UNSTABLE: [persistAtom],
});

export const approvalRequestsAtom = atom({
  key: 'approvalRequests',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const workspaceNamesAtom = atom({
  key: 'workspaceNames',
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export const showCardAtom = atom({
  key: 'showCard',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const activeWorkspaceAtom = atom({
  key: 'activeWorkspace',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const sideNavDimension = atom({
  key: 'sideNavDimension',
  default: { width: 250 },
  effects_UNSTABLE: [persistAtom],
});
