import { useEffect, useState } from 'react';
import axios from 'axios';
import { MdDeleteOutline } from 'react-icons/md';
import { FiArrowRightCircle } from 'react-icons/fi';
import { useToast } from '@chakra-ui/react';

import zapierIcon from '../../../icons/zapierIcon.png';
import ModalContent from '../../Modal/ModalContent';

const ZapierIntegration = ({
  integration,
}) => {
  const toast = useToast();

  const [open, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div
        className={`flex flex-col gap-2 p-2 h-fit max-w-20 text-center rounded-md cursor-pointer bg-gray-100`}
        onClick={handleOpenModal}
      >
        <img src={zapierIcon} />
      </div>
      {open && (
        <ModalContent closeModal={handleCloseModal}>
          <h2 className="text-center font-bold text-xl mb-5">
            Zapier Intergration
          </h2>
          <div className='text-center'>
            {!integration?.isConnected ? (<a href="https://zapier.com/engine/auth/start/App206209CLIAPI@1.0.0/?context=11586067" target='_blank'>      
              <p>
                Connect with Zapier
              </p>
            </a>): (<p>You are connected. 
              </p>)}
          </div>
        </ModalContent>
      )}
    </>
  );
};

export default ZapierIntegration;
