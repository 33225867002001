import React, { useEffect, useState } from 'react';
import CancelIcon from '../SVGs/Cancel';

const FormInputSelect = ({
  label,
  id,
  type,
  placeholder,
  error,
  hint,
  data = [],
  selectedData,
  handleDataSelect,
  ...otherProps
}) => {
  const [baseRecord] = useState(data);
  const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSelection = (record) => {
    handleDataSelect([...selectedData, record]);
    setSearch('');
    setFilteredData([]);
  };

  useEffect(() => {
    setFilteredData(
      baseRecord.filter((record) => {
        if (record.name.toLowerCase().includes(search.toLowerCase())) {
          return record;
        }
      })
    );
  }, [search]);

  return (
    <div className="flex flex-col gap-1.5 w-full">
      <label htmlFor={id} className="">
        {label}
      </label>
      <div className="relative">
        <input
          className="rounded-lg py-2 px-3 w-full border shadow-sm h-11"
          id={id}
          type="text"
          placeholder={placeholder}
          value={search}
          onChange={handleChange}
          {...otherProps}
        />
        {!!search && !!filteredData.length && (
          <div className="absolute border w-full bg-gray-100 rounded-lg p-2 z-20">
            {filteredData.map((record, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-2 border px-2 rounded-lg bg-[#1570EF1A] cursor-pointer"
                onClick={() => {
                  handleSelection(record);
                }}
              >
                {record.name}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex gap-1.5 flex-wrap">
        {selectedData?.map((record, index) => (
          <span
            key={index}
            className="flex items-center gap-2 border px-2 rounded-lg bg-[#1570EF1A]"
          >
            {record.name}
            <button
              onClick={() => {
                const newData = selectedData.filter(
                  (item) => item.id !== record.id
                );
                handleDataSelect(newData);
              }}
            >
              <CancelIcon />
            </button>
          </span>
        ))}
      </div>
      {hint && (
        <p className={`text-xs ${error ? 'text-red-600' : 'text-gray-600'}`}>
          {hint}
        </p>
      )}
    </div>
  );
};

export default FormInputSelect;
