import { useNavigate } from 'react-router-dom';

const NavLink = ({
  src,
  title,
  active,
  link,
  titleVisible = true,
  iconVisible = true,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(link);
  };

  return (
    <li
      className={`${
        active ? 'bg-gray-100' : ''
      } rounded h-10 p-2 flex gap-2 cursor-pointer`}
      onClick={handleClick}
    >
      <div className="min-h-6 min-w-6">
        <img src={src} alt="SwiftApprove" className="h-6 w-6" />
      </div>{' '}
      {titleVisible && <div>{title}</div>}
    </li>
  );
};

export default NavLink;
