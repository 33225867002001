const foramtDateComments = (isoString) => {
    const date = new Date(isoString);
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear().toString().slice(2);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let period = 'AM';
    if (hours >= 12) {
      period = 'PM';
    }
    if (hours > 12) {
      hours -= 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return `${month}/${day}/${year} ${hours}:${minutes} ${period}`;
  };

export default  foramtDateComments;